import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    paddingTop: 20,
    minWidth: "100%",
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: "64px",
  },

  spinner: {
    marginTop: "20%",
  },

  "& .MuiCardContent-root": {
    width: "100%",
  },

  myAppBar: {
    backgroundColor: "unset",
    boxShadow: "none",
    borderBottom: "1px #dfdfdf solid",
    borderColor: theme.palette.secondary.light,
  },

}));
