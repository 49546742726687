import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Home, KeyboardArrowRight } from "@mui/icons-material";
import useStyles from "./RouteDisplayStylesStyles";

interface routeDisplayProps {
  parentLink?: string;
  parentName?: string;
  currentLink: string;
  currentName: string;
}

export default ({ parentLink, parentName, currentLink, currentName }: routeDisplayProps): JSX.Element => {
  const classes = useStyles();
  return (
      <Box className={classes.routeDisplay}>
          <Box className={classes.currentLocationElementWrapper}>
              {currentName}
          </Box>
          <Box className={classes.routeDisplayElementWrapper}>
              |
          </Box>

          <Box className={classes.routeDisplayElementWrapper}>
              <Link to="/home">
                  <Home className={classes.homeIcon} />
              </Link>
          </Box>
          { parentLink
      && (
          <>
              <Box className={classes.routeDisplayElementWrapper}>
                  <KeyboardArrowRight />
              </Box>
              <Box className={classes.routeDisplayElementWrapper}>
                  <Link className={classes.link} to={parentLink}>
                      {parentName}
                  </Link>
              </Box>
          </>
      )}
          <Box className={classes.routeDisplayElementWrapper}>
              <KeyboardArrowRight />
          </Box>
          <Box className={classes.routeDisplayElementWrapper}>
              <Link className={classes.link} to={currentLink}>
                  {currentName}
              </Link>
          </Box>

      </Box>
  );
};
