import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",

    "& .MuiTableRow-head": {
      backgroundColor: theme.palette.primary.dark,
      "& .MuiTableCell-sizeSmall": {
        backgroundColor: "white",
        color: "black",

      },
      "& .MuiCircularProgress-root": {
        marginTop: "5% !important",
      },
    },
    "& .MuiTableCell-root": {
      borderBottom: "unset",
    },
    "& .MuiTableCell-head": {
      color: theme.palette.primary.contrastText,
    },
    "& > *": {
      borderBottom: "unset",
    },
  },
  container: {
    maxHeight: 440,
  },
  deleteIcon: {
    cursor: "pointer",
  },
  subrow: {
    borderBottom: "unset !important",
  },
  subrowContainer: {
    borderTop: `1px solid ${grey[500]}`,
  },
  jsonCell: {
    whiteSpace: "pre-wrap",
  },
  sortLabel: {
    color: `${theme.palette.primary.contrastText} !important`,
    "& .MuiSvgIcon-root": {
      color: `${theme.palette.primary.contrastText} !important`,

    },
  },
  spinner: {
    marginTop: "5%",
  },

}));
