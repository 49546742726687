import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: 30,
    minWidth: "100%",
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: "64px",
  },

  spinner: {
    marginTop: "20%",
  },

  "& .MuiCardContent-root": {
    width: "100%",
  },
  myAppBar: {
    backgroundColor: "unset",
    boxShadow: "none",
    borderBottom: "1px #dfdfdf solid",
    borderColor: theme.palette.secondary.light,
  },
  tabContainer: {
    marginTop: "15px",
  },

  searchButton: {
    height: "56px",
    marginLeft: 15,
    borderRadius: 5,
  },

  tabBar: {
    backgroundColor: "#f7f7f7 !important",
    color: "black",
    boxShadow: "none",
    display: "grid",
    "grid-template-columns": "1fr auto auto",
    marginTop: 50,
  },

  searchInput: {
    textAlign: "right",
    whiteSpace: "nowrap",
    justifySelf: "flex-end",
  },
  headerContainer: {
    marginBottom: 50,
  },
  noUsers: {
    marginTop: 50,
  },

}));
