import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button, Checkbox, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, styled, Tab, Tabs, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import useStyles from "./MassEditModalStyle";
import { TabPanel } from "../tabPanel/TabPanel";
import { getOrganizationTagsById } from "../../api/OrganizationApi";
import Device from "../../models/Device";
import Tag from "../../models/Tag";
import { useRootStore } from "../../RootStateContext";
import Decoding from "../../models/Decoding";
import RoutingPlan from "../../models/RoutingPlan";
import PendingCircle from "../PendingCircle/PendingCircle";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  submitDecoding: (selectedDevices: Device[], decoding: string) => Promise<void>;
  submitTags: (selectedDevices: Device[], selectedTags: string[]) => Promise<void>;
  submitRoutings: (selectedDevices: Device[], selectedRoutings: string[]) => Promise<void>;
  selectedDevices: Device[];
  selectedDevicesHaveSameOrganization: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{
      popper: className,
      }}
    />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export default observer(({ open, handleClose, selectedDevices, selectedDevicesHaveSameOrganization, submitDecoding, submitTags, submitRoutings }: IModalProps): JSX.Element => {
  const classes = useStyles();

  const { deviceStore, routingPlanStore } = useRootStore();

  const [activeTab, setActiveTab] = useState(0);

  const [loadingTags, setLoadingTags] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);

  const [selectedDecoding, setSelectedDecoding] = useState<string>("");
  const [selectedRoutingPlans, setSelectedRoutingPlans] = useState<string[]>([]);

  const close = (): void => {
    setLoadingTags(false);
    setSelectedTags([]);
    setAvailableTags([]);
    setSelectedDecoding("");
    setSelectedRoutingPlans([]);
    setActiveTab(0)
    handleClose();
  }

  const submitDecodingsInternal = async (): Promise<void> => {
    if (selectedDevices && selectedDevices.length > 0 && selectedDecoding) {
      await submitDecoding(selectedDevices, selectedDecoding);
      close();
    }
  };

  const submitTagsInternal = async (): Promise<void> => {
    if (selectedDevices && selectedDevices.length > 0 && selectedTags && selectedTags.length > 0) {
      await submitTags(selectedDevices, selectedTags);
      close();
    }
  };

  const submitRoutingsInternal = async (): Promise<void> => {
    if (selectedDevices && selectedDevices.length > 0 && selectedRoutingPlans && selectedRoutingPlans.length > 0) {
      await submitRoutings(selectedDevices, selectedRoutingPlans);
      close();
    }
  };

  const handleTabChange = async (event: React.SyntheticEvent<Element, Event> | null, newValue: any) : Promise<void> => {
    setActiveTab(newValue);

    if (newValue === 1) {
      if (selectedDevices[0].organizationId) {
        await routingPlanStore.loadRoutingPlansOfOrganization(selectedDevices[0].organizationId);
        setSelectedRoutingPlans([])
      }
    } else if (newValue === 2) {
      if (selectedDevices[0].organizationId) {
        setLoadingTags(true);
        const tags = await getOrganizationTagsById(selectedDevices[0].organizationId);
        setAvailableTags(tags);
        setSelectedTags([]);
        setLoadingTags(false);
      }
    }
  };

  const handleTabClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tabNum: number) : Promise<void> => {
    handleTabChange(null, tabNum);
  };

  const handleTagDropdownChange = (event: SelectChangeEvent<string[]>): void => {
    setSelectedTags(event.target.value as string[]);
  };

  const handleDecodingDropdownChange = (event: SelectChangeEvent<string>): void => {
    setSelectedDecoding(event.target.value);
  };

  const handleRoutingDropdownChange = (event: SelectChangeEvent<string[]>): void => {
    setSelectedRoutingPlans(event.target.value as string[]);
  };

  useEffect(() => {
    (async () => {
      await deviceStore.loadDeviceDecodings();
    })();
  }, [deviceStore])

  return (
      <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
          >
              <Fade in={open}>
                  <div className={`${classes.paper}`}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id="massEditHeader">{`${t("massEditModal.massEdit")} (${selectedDevices.length} laitetta)`}</Typography>
                      </div>
                      <Tabs value={activeTab} centered>
                          <Tab label={t("massEditModal.decoding")} onClick={(e) => handleTabClick(e, 0)} />
                          <LightTooltip
                            placement="top-start"
                            title={selectedDevicesHaveSameOrganization === false ? t("massEditModal.tabDisabled") : ""}
                            arrow
                          >
                              <span>
                                  <Tab label={t("massEditModal.routingPlan")} disabled={!selectedDevicesHaveSameOrganization} onClick={(e) => handleTabClick(e, 1)} />
                              </span>
                          </LightTooltip>
                          <LightTooltip
                            placement="top-start"
                            title={selectedDevicesHaveSameOrganization === false ? t("massEditModal.tabDisabled") : ""}
                            arrow
                          >
                              <span>
                                  <Tab label={t("massEditModal.tags")} disabled={!selectedDevicesHaveSameOrganization} onClick={(e) => handleTabClick(e, 2)} />
                              </span>
                          </LightTooltip>
                      </Tabs>
                      <TabPanel
                        value={activeTab}
                        index={0}
                        style={{
                          height: "calc(100% - 45px - 65px)",
                        }}
                      >
                          <div className={classes.section}>
                              <Typography className={classes.sectionHeaderText} variant="h6" component="h2" id="selectNewDecodingText">{t("massEditModal.selectNewDecodingText")}</Typography>
                              <Typography className={classes.sectionHeaderSubText} variant="h6" component="h2" id="selectNewDecodingText">{t("massEditModal.selectNewDecodingSubText")}</Typography>
                              {deviceStore.loadingDecodings ? (
                                  <PendingCircle customStyles={classes.spinner} containerClasses={classes.spinnerContainer} />
                              ) : (
                                  <FormControl className={classes.formControl}>
                                      <InputLabel id="massEditDecodingLabel">{t("massEditModal.selectNewDecoding")}</InputLabel>
                                      <Select
                                        key="select"
                                        labelId="massEditDecodingLabel"
                                        id="selectNewDecodingInput"
                                        value={selectedDecoding}
                                        onChange={handleDecodingDropdownChange}
                                        displayEmpty
                                      >
                                          {deviceStore.decodings.map((dec) => (
                                              <MenuItem key={dec.name} value={dec.id}>{`${dec.name} (${dec.decoding})`}</MenuItem>
                                      ))}
                                      </Select>
                                  </FormControl>
                              )}
                          </div>
                          <Grid container className={classes.massEditModalButtons}>
                              <Button
                                variant="contained"
                                id="closeMassEditModal"
                                className={classes.backButton}
                                color="secondary"
                                onClick={close}
                              >
                                  {t("massEditModal.cancel")}
                              </Button>
                              <Button
                                variant="contained"
                                id="createUserButton"
                                className={classes.saveButton}
                                color="primary"
                                onClick={submitDecodingsInternal}
                              >
                                  {t("massEditModal.save")}
                              </Button>
                          </Grid>
                      </TabPanel>
                      <TabPanel
                        value={activeTab}
                        index={1}
                        style={{
                          height: "calc(100% - 45px - 65px)",
                        }}
                      >
                          <div className={classes.section}>
                              <Typography className={classes.sectionHeaderText} variant="h6" component="h2" id="selectNewRoutingText">{t("massEditModal.selectNewRoutingText")}</Typography>
                              <Typography className={classes.sectionHeaderSubText} variant="h6" component="h2" id="selectNewRoutingText">{t("massEditModal.selectNewRoutingSubText")}</Typography>
                              {routingPlanStore.loadingOrganizationRoutingPlans ? (
                                  <PendingCircle customStyles={classes.spinner} containerClasses={classes.spinnerContainer} />
                              ) : (
                                  <FormControl className={classes.formControl}>
                                      <InputLabel id="massEditRoutingLabel">{t("massEditModal.selectNewRouting")}</InputLabel>
                                      <Select
                                        key="select"
                                        labelId="massEditRoutingLabel"
                                        id="selectNewRoutingInput"
                                        value={selectedRoutingPlans}
                                        onChange={handleRoutingDropdownChange}
                                        renderValue={(selected) => routingPlanStore.organizationRoutingPlans.filter((rp) => selected.includes(rp.id!)).map((rp) => rp.name).join(", ")}
                                        multiple
                                        style={{
                                      width: "100%",
                                        }}
                                      >
                                          {routingPlanStore.organizationRoutingPlans.map((plan) => (
                                              <MenuItem key={plan.id} value={plan.id}>
                                                  <Checkbox checked={selectedRoutingPlans.indexOf(plan.id!) > -1} />
                                                  <ListItemText primary={`${plan.name} (${plan.url})`} />
                                              </MenuItem>
                                        ))}
                                      </Select>
                                  </FormControl>
                              )}
                          </div>
                          <Grid container className={classes.massEditModalButtons}>
                              <Button
                                variant="contained"
                                id="closeMassEditModal"
                                className={classes.backButton}
                                color="secondary"
                                onClick={close}
                              >
                                  {t("massEditModal.cancel")}
                              </Button>
                              <Button
                                variant="contained"
                                id="createUserButton"
                                className={classes.saveButton}
                                color="primary"
                                onClick={submitRoutingsInternal}
                              >
                                  {t("massEditModal.save")}
                              </Button>
                          </Grid>
                      </TabPanel>
                      <TabPanel
                        value={activeTab}
                        index={2}
                        style={{
                          height: "calc(100% - 45px - 65px)",
                        }}
                      >
                          <div className={classes.section}>
                              <Typography className={classes.sectionHeaderText} variant="h6" component="h2" id="selectNewTagsText">{t("massEditModal.selectNewTagsText")}</Typography>
                              <Typography className={classes.sectionHeaderSubText} variant="h6" component="h2" id="selectNewTagsText">{t("massEditModal.selectNewTagsSubText")}</Typography>
                              {loadingTags ? (
                                  <PendingCircle customStyles={classes.spinner} containerClasses={classes.spinnerContainer} />
                              ) : (
                                  <FormControl className={classes.formControl}>
                                      <InputLabel id="massEditTagLabel">{t("massEditModal.selectNewTags")}</InputLabel>
                                      <Select
                                        key="select"
                                        labelId="massEditTagLabel"
                                        id="selectNewTagsInput"
                                        multiple
                                        value={selectedTags}
                                        onChange={handleTagDropdownChange}
                                        renderValue={(selected) => availableTags.filter((t) => selected.includes(t.id!)).map((t) => t.name).join(", ")}
                                        style={{
                                      width: "100%",
                                        }}
                                      >
                                          {availableTags.map((tag) => (
                                              <MenuItem key={tag.id!} value={tag.id!}>
                                                  <Checkbox checked={selectedTags.indexOf(tag.id!) > -1} />
                                                  <ListItemText primary={tag.name} />
                                              </MenuItem>
                                      ))}
                                      </Select>
                                  </FormControl>
                              )}
                          </div>
                          <Grid container className={classes.massEditModalButtons}>
                              <Button
                                variant="contained"
                                id="closeMassEditModal"
                                className={classes.backButton}
                                color="secondary"
                                onClick={close}
                              >
                                  {t("massEditModal.cancel")}
                              </Button>
                              <Button
                                variant="contained"
                                id="createUserButton"
                                className={classes.saveButton}
                                color="primary"
                                onClick={submitTagsInternal}
                              >
                                  {t("massEditModal.save")}
                              </Button>
                          </Grid>
                      </TabPanel>
                  </div>
              </Fade>
          </Modal>
      </div>
  );
});
