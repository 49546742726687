import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { drawerWidth } from "../navigationBar/NavigationBarStyles";

export default makeStyles((theme: Theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  nameAndUserMenuContainer: {
    display: "block",
  },
  contents: {
    marginLeft: "auto",
  },
  languageSelector: {
    marginRight: "20px",
  },
  selectedLanguage: {
    color: "white",
    backgroundColor: theme.palette.primary.light,
  },
  languageButton: {
    color: "white",
  },
  logout: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "center",
    height: "40%",
    color: "white",
  },
  user: {
    display: "flex",
    flexDirection: "column",
  },
  userName: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "center",
    height: "60%",
  },
}));
