import { action, makeObservable, observable } from "mobx";

abstract class BaseStore {
    error?: string;

    setError = (error: string): void => {
      this.error = error;
      setTimeout(this.clearError, 3000);
    }

    clearError = (): void => {
      this.error = undefined;
    }

    constructor() {
      makeObservable(this, {
        clearError: action,
        setError: action,
        error: observable,
      });
    }
}

export default BaseStore;
