import { Dispatch } from "react";
import i18n from "../../i18n";

export type State = {
    [key: string]: any; // TODO: Fix type
 }

export type Action = {
    type: ActionType;
    data: State
}

export enum ActionType {
    UPDATE,
    RESET
}

export const validateInput = (name: string, value: string): {hasError: boolean, error: string} => {
  let hasError = false;

  let error = "";
  switch (name) {
    case "email":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyEmail");
      } else if (
        !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
          value,
        )
      ) {
        hasError = true;
        error = i18n.t("validation.invalidEmail");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "mobilePhone":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyMobilePhone");
      } else if (
        !/^\+358.*[0-9]$/.test(
          value,
        ) // TODO change to use some mobile number lib validation
      ) {
        hasError = true;
        error = i18n.t("validation.invalidMobilePhone");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "password":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyPassword");
      } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(value)) {
        hasError = true;
        error = i18n.t("validation.invalidPassword");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "firstName":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyFirstName");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "organization":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyOrganization");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "lastName":
      if (!value) {
        hasError = true;
        error = i18n.t("validation.emptyLastName");
      } else {
        hasError = false;
        error = "";
      }
      break;
    default:
      break;
  }
  return {
    hasError, error,
  };
};

export const onInputChange = (name: string, value: string, dispatch: Dispatch<Action>): void => {
  const { hasError, error } = validateInput(name, value);

  dispatch({
    type: ActionType.UPDATE,
    data: {
      name, value, hasError, error, touched: false,
    },
  });
};

export enum PasswordLength {
    SHORT, MEDIUM, LONG
}

export const generatePassword = (length?: PasswordLength): string => {
  /*
    constraints are specified in backend
    8-25 characters, 1 upper case, 1 lower case, 1 number, 1 special char
    */

  const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lower = "abcdefghijklmnopqrstuvwxyz";
  const special = ",.-+?!";

  let pwd = "";
  let len = 2;
  if (length !== undefined) {
    if (length === PasswordLength.SHORT) len = 3;
    else if (length === PasswordLength.MEDIUM) len = 5;
    else if (length === PasswordLength.LONG) len = 7;
  }
  for (let i = 0; i < len; i += 1) {
    pwd += upper.charAt(Math.floor(Math.random()
            * upper.length));

    pwd += lower.charAt(Math.floor(Math.random()
            * lower.length));

    pwd += Math.floor(Math.random() * 10).toString();
  }

  pwd += special.charAt(Math.floor(Math.random()
  * special.length));

  return pwd;
};
