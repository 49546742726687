import { Button, Container, InputBase, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Device from "../../../../models/Device";
import useStyles from "./DeviceInfoStyles";

interface IDeviceInfoProps {
    device: Device;
    update: (device: Device) => void;
    requestDeviceUpdate: (device: Device) => void;
}

export default ({ device, update, requestDeviceUpdate }: IDeviceInfoProps): JSX.Element => {
  const classes = useStyles();
  const [editOn, setEditOn] = useState(false);
  const { t } = useTranslation();
  const tags = useRef<HTMLInputElement>();
  const logicalName = useRef<HTMLInputElement>();

  const saveModifications = (): void => {
    const newDevice: Device = (JSON.parse(JSON.stringify(device)));
    /*  if (tags.current !== undefined) {
      newDevice.tags = tags.current.value.split(" ");

  } */
    if (logicalName.current !== undefined) {
      newDevice.logicalName = logicalName.current.value;
    }

    setEditOn(false);
    update(newDevice);
  };

    const reqUpdate = (): void => {
        requestDeviceUpdate(JSON.parse(JSON.stringify(device)));
    };
  return (
      <Container className={classes.rootContainer}>
          <Container className={classes.titleContainer}>
              <Typography className={classes.leftFloat} component="h4" variant="h4">{t("singleDeviceView.info")}</Typography>
          </Container>
          <Container className={classes.contentContainer}>
              <table className={classes.table}>

                  <tr className={classes.tableRow}>
                      <td className={classes.tableRowTitle}>
                          <Typography className={classes.leftFloat} variant="subtitle1">
                              {t("singleDeviceView.modelName")}
                          </Typography>
                      </td>
                      <td>
                          <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceModelName">
                              {device.deviceModel?.name}
                          </Typography>
                      </td>
                  </tr>

                  <tr className={classes.tableRowSpacer} />

                  <tr className={classes.tableRow}>
                      <td className={classes.tableRowTitle}>
                          <Typography className={classes.leftFloat} variant="subtitle1">
                              {t("singleDeviceView.logicalName")}
                          </Typography>
                      </td>
                      <td>
                          {editOn
                            ? (
                                <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceLogicalName">
                                    <InputBase
                                      className={classes.modifying}
                                      type="text"
                                      defaultValue={device.logicalName}
                                      inputRef={logicalName}
                                    />
                                </Typography>

                            ) : (
                                <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceLogicalName">
                                    {device.logicalName}
                                </Typography>
                            )}
                      </td>
                  </tr>
                  <tr className={classes.tableRowSpacer} />

                  <tr className={classes.tableRow}>
                      <td className={classes.tableRowTitle}>
                          <Typography className={classes.leftFloat} variant="subtitle1">
                              {t("singleDeviceView.organizationName")}
                          </Typography>
                      </td>
                      <td>
                          <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceOrganizationName">
                              {device.organizationName}
                          </Typography>
                      </td>
                  </tr>
                  <tr className={classes.tableRowSpacer} />

                  <tr className={classes.tableRow}>
                      <td className={classes.tableRowTitle}>
                          <Typography className={classes.leftFloat} variant="subtitle1">
                              {t("singleDeviceView.organizationId")}
                          </Typography>
                      </td>
                      <td>
                          <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceAccountId">
                              {device.organizationId}
                          </Typography>
                      </td>
                  </tr>
                  <tr className={classes.tableRowSpacer} />

                  <tr className={classes.tableRow}>
                      <td className={classes.tableRowTitle}>
                          <Typography className={classes.leftFloat} variant="subtitle1">
                              {t("singleDeviceView.physicalId")}
                          </Typography>
                      </td>
                      <td>
                          <Typography className={classes.leftFloat} variant="subtitle1" id="DevicePhysicalId">
                              {device.physicalId}
                          </Typography>
                      </td>
                  </tr>
                  <tr className={classes.tableRowSpacer} />

                  <tr className={classes.tableRow}>
                      <td className={classes.tableRowTitle}>
                          <Typography className={classes.leftFloat} variant="subtitle1">
                              {t("singleDeviceView.tags")}
                          </Typography>
                      </td>
                      <td>
                          {editOn
                            ? (
                                <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceTags">
                                    <InputBase
                                      className={classes.modifying}
                                      type="text"
                                      defaultValue={device.tags.map((e) => e.name).join(", ")}
                                      inputRef={tags}
                                    />
                                </Typography>

                            ) : (
                                <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceTags">
                                    {device.tags.map((e) => e.name).join(", ")}
                                </Typography>
                            )}
                      </td>
                  </tr>
                  <tr className={classes.tableRow}>
                      <td className={classes.tableRowTitle}>
                          <Typography className={classes.leftFloat} variant="subtitle1">
                              {t("singleDeviceView.metadata")}
                          </Typography>
                      </td>
                      <td>
                          <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceMetadata">
                              {JSON.stringify(device.metadata)}
                          </Typography>
                      </td>
                  </tr>
                  <tr>
                      <td>&nbsp;</td>
                      <td>
                          <Container className={classes.buttonContainer}>
                              <Button onClick={reqUpdate} variant="outlined" color="primary" id="RequestDeviceUpdate">{t("singleDeviceView.deviceUpdateRequest")}</Button>
                          </Container>
                      </td>
                  </tr>
              </table>
              {!device.archived && false
              && (
                  <Container className={classes.buttonContainer}>
                      {editOn ? (
                          <>
                              <Button onClick={saveModifications} variant="outlined" color="primary" id="SaveInfoChangesButton">{t("singleDeviceView.save")}</Button>
                              <Button onClick={() => setEditOn(false)} variant="outlined" color="primary" id="CancelInfoChangesButton">{t("singleDeviceView.cancel")}</Button>
                          </>
                      )
                        : (
                            <Button onClick={() => setEditOn(true)} variant="outlined" color="primary" id="EditDeviceInfoButton">
                                {t("singleDeviceView.modify")}
                            </Button>
                        ) }
                  </Container>
              )}
          </Container>
      </Container>
  );
};
