import React, { useState, useReducer, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button,
  Checkbox,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  TextField,
  Typography,
  SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useStyles from "./EditUserModalStyles";
import { OrgRole, UserInfoForUpdating } from "../../../models/User";
import { State, ActionType, Action, validateInput, onInputChange } from "../FormUtils";
import { useRootStore } from "../../../RootStateContext";
import { UserRole } from "../../../models/UserRole";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (u: UserInfoForUpdating, id: string) => void;
}

const reducer = (state: State, action: Action): State => {
  if (action.type === ActionType.UPDATE) {
    const { name, value, hasError, error } = action.data;
    return {
      ...state,
      [name]: {
        ...state[name], value, hasError, error,
      },
    };
  }
  return state;
};
const initialValidationState = {
  firstName: {
    value: "", hasError: true, error: "",
  },
  email: {
    value: "", hasError: true, error: "",
  },
  mobilePhone: {
    value: "", hasError: true, error: "",
  },
  lastName: {
    value: "", hasError: true, error: "",
  },
  role: {
    value: "", hasError: true, error: "",
  },
  organization: {
    value: "", hasError: true, error: "",
  },
};

export default observer(({ open, handleClose, submit }: IModalProps): JSX.Element => {
  const user = useRootStore().userStore.selectedUser;

  const classes = useStyles();
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(user?.active || true);
  const [validationState, dispatch] = useReducer(reducer, initialValidationState);
  const { organizations } = useRootStore().organizationStore;
  const [organizationId, setOrganizationId] = useState("");
  const [isOrganizationAdmin, setIsOrganizationAdmin] = useState(false);

  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "firstName",
        value: user?.firstName,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "lastName",
        value: user?.lastName,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "email",
        value: user?.email,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "mobilePhone",
        value: user?.mobilePhone,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "role",
        value: user?.role,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "organization",
        value: user?.organizationAccess.organizationId,
        hasError: false,
        error: "",
      },
    });
    setOrganizationId(user?.organizationAccess.organizationId || "");
    setIsOrganizationAdmin(user?.organizationAccess.role === OrgRole.ADMIN);
    setActive(user?.active || true);
  }, [user]);

  const isReadyToBeSaved = (): boolean => {
    let invalid = false;
    Object.keys(validationState).forEach((name) => {
      if (Object.prototype.hasOwnProperty.call(validationState, name)) {
        const item = validationState[name];
        if (item !== undefined) {
          const { value } = item;
          const { hasError, error } = validateInput(name, value);
          if (hasError) {
            console.log(`Error in field: ${name}, value: ${value}`);
            invalid = true;
          }
          if (name) {
            dispatch({
              type: ActionType.UPDATE,
              data: {
                name,
                value,
                hasError,
                error,
              },
            });
          }
        }
      }
    });
    return !invalid;
  };

  const close = (): void => {
    dispatch({
      type: ActionType.RESET, data: {},
    });
    setOrganizationId("");
    setIsOrganizationAdmin(false);
    handleClose();
  };

  const saveUser = (): void => {
    if (isReadyToBeSaved()) {
      const u: UserInfoForUpdating = {
        firstName: validationState.firstName.value,
        lastName: validationState.lastName.value,
        email: validationState.email.value,
        mobilePhone: validationState.mobilePhone.value,
        active: active || false,
        role: UserRole[validationState.role.value as UserRole] || user?.role || UserRole.IOT_ADMIN,
        organizationAccess: {
          organizationId, role: isOrganizationAdmin ? OrgRole.ADMIN : OrgRole.USER,
        },
        _etag: user?._etag,
      };
      submit(u, user?.id || "");
      close();
    } else {
      console.log(JSON.stringify(validationState));
    }
  };

  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      onInputChange("organization", event.target.value as string, dispatch);
      setOrganizationId(event.target.value as string);
    }
  };

  return (
      <>
          <Modal
            className={classes.modal}
            open={open}
            onClose={close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id="modifyUserTitle">{t("userEdit.editUser")}</Typography>
                      </div>
                      <div className={classes.section}>
                          <div className={classes.subsection}>
                              <TextField
                                id="firstNameInput"
                                label={t("usersView.firstName")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={validationState?.firstName?.error !== ""}
                                helperText={validationState?.firstName?.error}
                                onChange={(e) => onInputChange("firstName", e.target.value, dispatch)}
                                variant="outlined"
                                className={classes.firstName}
                                defaultValue={user?.firstName}

                              />
                              <div className={classes.divider} />
                              <TextField
                                className={classes.lastName}
                                id="lastNameInput"
                                error={validationState?.lastName?.error !== ""}
                                helperText={validationState?.lastName?.error}
                                onChange={(e) => onInputChange("lastName", e.target.value, dispatch)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label={t("usersView.lastName")}
                                variant="outlined"
                                defaultValue={user?.lastName}
                              />
                          </div>
                          <div className={classes.subsection}>
                              <TextField
                                id="emailInput"
                                fullWidth
                                error={validationState?.email?.error !== ""}
                                helperText={validationState?.email?.error}
                                onChange={(e) => onInputChange("email", e.target.value, dispatch)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label={t("usersView.email")}
                                variant="outlined"
                                defaultValue={user?.email}
                              />
                          </div>
                          <div className={classes.subsection}>
                              <TextField
                                id="mobilePhoneInput"
                                fullWidth
                                error={validationState?.mobilePhone?.error !== ""}
                                helperText={validationState?.mobilePhone?.error}
                                onChange={(e) => onInputChange("mobilePhone", e.target.value, dispatch)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label={t("usersView.mobilePhone")}
                                variant="outlined"
                                defaultValue={user?.mobilePhone}
                              />
                          </div>
                          {organizations
                          && (
                              <div className={classes.subsection}>
                                  <FormControl className={classes.formControl}>
                                      <InputLabel id="organizationSelectLabel">{t("usersView.organization")}</InputLabel>
                                      <Select
                                        error={validationState?.organization?.error !== ""}
                                        labelId="orgLabel"
                                        id="orgLabelId"
                                        value={organizationId || user?.organizationAccess.organizationId}
                                        onChange={handleOrganizationDropdownChange}
                                      >
                                          {organizations.map((org) => (
                                              <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{t("usersView.organizationHelperText")}</FormHelperText>
                                  </FormControl>
                                  <FormControlLabel
                                    control={(
                                        <Checkbox
                                          id="isUserAdmin"
                                          checked={isOrganizationAdmin}
                                          onChange={() => { setIsOrganizationAdmin((a) => !a); }}
                                          name={t("usersView.admin")}
                                          color="primary"
                                        />
                                    )}
                                    label={t("usersView.admin")}
                                  />
                              </div>

                          )}
                          {/* HIDDEN FOR NOW
                          <div className={classes.subsection}>
                              <FormControl className={classes.formControl}>
                                  <InputLabel id="userRoleLabel">{t("usersView.role")}</InputLabel>
                                  <Select
                                    labelId="roleLabel"
                                    id="roleLabelId"
                                    value={user?.role}
                                    onChange={handleRoleDropDownChange}
                                  >
                                      {Object.keys(UserRole).map((role) => (
                                          <MenuItem value={role}>{role.toString()}</MenuItem>
                                      ))}
                                  </Select>
                                  <FormHelperText>{t("usersView.roleHelperText")}</FormHelperText>
                              </FormControl>
                          </div>
                           */}
                          <div className={classes.subsection}>
                              <FormControlLabel
                                control={(
                                    <Checkbox
                                      id="isUserActive"
                                      checked={active}
                                      onChange={() => { setActive((a) => !a); }}
                                      name={t("usersView.active")}
                                      color="primary"
                                    />
                                )}
                                label={t("usersView.active")}
                              />
                          </div>
                      </div>
                      <div className={classes.navigation}>

                          <div className={classes.navButtons}>
                              <Button
                                variant="contained"
                                id="closeEditUserModal"
                                className={classes.backButton}
                                color="secondary"
                                onClick={close}
                              >
                                  {t("usersView.cancel")}
                              </Button>
                              <Button
                                variant="contained"
                                id="saveUserButton"
                                className={classes.nextButton}
                                color="primary"
                                onClick={saveUser}
                              >
                                  {t("usersView.saveUser")}
                              </Button>
                          </div>

                      </div>
                  </div>
              </Fade>
          </Modal>
      </>
  );
});
