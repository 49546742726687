import { Button, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../RootStateContext";
import useStyles from "./SessionExpiredComponentStyles";

export default (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const authStore = useRootStore().authenticationStore;

  return (
      <Container className={classes.container}>
          <Typography className={classes.headerContainer} variant="h4" component="h2" id="sessionExpired">
              {t("common.sessionExpired")}
          </Typography>
          <Button onClick={authStore.logout} id="logoutButton">{t("header.logout")}</Button>
      </Container>
  );
};
