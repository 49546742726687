import ConnectivityProduct from "../models/ConnectivityProduct";
import Decoding from "../models/Decoding";
import Device, { DeviceResponseWrapper } from "../models/Device";
import { DeviceModel } from "../models/DeviceModel";
import { del, get, post, put } from "./HttpUtils";

const api = process.env.REACT_APP_API_URL || "";

export const getDevices = async (): Promise<Device[]> => {
  const content = await get(`${api}/service/device`) as DeviceResponseWrapper;
  const devices = content.content;
  return devices;
};

export const getDevicesPaged = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, organization?: string, search?: string): Promise<DeviceResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useArchived = archived !== undefined ? `&archived=${archived}` : "";
  const useOrganization = organization !== undefined ? `&organization=${organization}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";

  return await get(`${api}/service/device/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useArchived}${useOrganization}${useSearch}`) as DeviceResponseWrapper;
};

export const saveNewDevice = async (device: Device): Promise<Device> => await post(`${api}/service/device`, device) as Device;
export const deleteDevice = async (device: Device): Promise<void> => del(`${api}/service/device/${device.id}`);
export const getDevice = async (deviceId: string): Promise<Device> => await get(`${api}/service/device/${deviceId}`) as Device;
export const updateDevice = async (deviceId: string, device: Device): Promise<Device> => await put(`${api}/service/device/${deviceId}`, device) as Device;

export const requestUpdate = async (deviceId: string, device: Device): Promise<Device> => await post(`${api}/service/device/${deviceId}`, device) as Device;
// TODO: cache invalidation
export const getConnectivityProducts = async (organizationId: string): Promise<ConnectivityProduct[]> => {
  // const cachedData = localStorage.getItem("connectivityProducts");
  // if (!cachedData) {
  const data = await get(`${api}/service/device/connectivity-product/${organizationId}`) as ConnectivityProduct[];
  // localStorage.setItem("connectivityProducts", JSON.stringify(data));
  return data;
  // } return JSON.parse(cachedData) as ConnectivityProduct[];
};

// TODO: cache invalidation
export const getDeviceModels = async (): Promise<DeviceModel[]> => {
  // const cachedData = localStorage.getItem("deviceModels");
  // if (!cachedData) {
  const data = await get(`${api}/service/device/model`) as DeviceModel[];
  // localStorage.setItem("deviceModels", JSON.stringify(data));
  return data;
  // } return JSON.parse(cachedData) as DeviceModel[];
};

export const batchArchiveDevices = async (deviceIds: string[]): Promise<string[]> => await post(`${api}/service/device/batchArchive`, deviceIds) as string[];

export const getDevicePhysicalIdExists = async (devicePhysicalId: string): Promise<boolean> => {
  const data = await get(`${api}/service/device/${devicePhysicalId}/exists`);

  if (data === "true" || data === true) return true;
  return false;
};

export const getDeviceDecodings = async (): Promise<Decoding[]> => {
  const data = await get(`${api}/service/decoding`) as Decoding[];
  return data;
};
