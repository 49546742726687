import React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import AlarmIcon from "@mui/icons-material/Alarm";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { useTheme } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../RootStateContext";
import useStyles from "./NavigationBarStyles";
import { UserInfo } from "../../models/User";

interface INavigationBarProps {
                        currentUser: UserInfo;
}
export default observer(({ currentUser }:INavigationBarProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const store = useRootStore().uiStateStore;
  const { t } = useTranslation();

  let navigationComponents = [
    {
      name: t("navigation.home"),
      key: "home",
      icon: <HomeIcon />,
      id: "home",
    },
    {
      name: t("navigation.devices"),
      key: "devices",
      icon: <DeviceHubIcon />,
      id: "devices",
    },
    {
      name: t("navigation.alerts"),
      key: "alerts",
      icon: <AlarmIcon />,
      id: "alerts",
    },
    {
      name: t("navigation.routing"),
      key: "routing",
      icon: <CallSplitIcon />,
      id: "routing",
    },
    {
      name: t("navigation.subscription"),
      key: "subscription",
      icon: <CardMembershipIcon />,
      id: "subscription",
    },
  ];

  if (currentUser.adminInSuperAdminOrganization || currentUser.adminInCustomerOrganization) {
    navigationComponents = navigationComponents.concat(
      {
        name: t("navigation.users"),
        key: "users",
        icon: <PeopleIcon />,
        id: "users",
      },
    );
  }
  const drawer = (
      <div>
          <div className={classes.toolbar} />
          <Divider />
          <List>
              {navigationComponents.map((val) => (
                  <Link to={`/${val.key}`} key={val.name} className={classes.navItem}>
                      <ListItem button key={val.name} id={val.id}>
                          <ListItemIcon>{val.icon}</ListItemIcon>
                          <ListItemText primary={val.name} />
                      </ListItem>
                  </Link>
              ))}
          </List>
          <Divider />
      </div>
  );

  const container = undefined;

  return (
      <>
          <nav className={classes.drawer} aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                  <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === "rtl" ? "right" : "left"}
                    open={store.mobileOpen}
                    onClose={store.handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                      {drawer}
                  </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                  >
                      {drawer}
                  </Drawer>
              </Hidden>
          </nav>
      </>
  );
});
