import { Button, Container, Table } from "@mui/material";
import { responsiveFontSizes } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import PageTitle from "../../components/pageTitle/PageTitle";
import RouteDisplay from "../../components/routeDisplay/RouteDisplay";
import AlertTable, { Column } from "../../components/table/TableWithPagination";
import mainTheme from "../../materialUITheme";
import Alert from "../../models/Alert";
import { useRootStore } from "../../RootStateContext";
import useStyles from "./AlertsViewStyles";

export default observer((): JSX.Element => {
  const store = useRootStore().alertStore;

  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [alertSortDirection, setAlertSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  const theme = responsiveFontSizes(mainTheme, {
    factor: 590000000,
  });

  const alertColumns: Column[] = [
    {
      id: "startTime",
      label: t("alertsView.startTime"),
      minWidth: 100,
    },
    {
      id: "type",
      label: t("alertsView.type"),
      minWidth: 170,
    },
    {
      id: "title",
      label: t("alertsView.title"),
      minWidth: 100,
    },
    {
      id: "repeated",
      label: t("alertsView.repeated"),
      format: (repeated: string[]) => (repeated ? `${repeated.length}` : `0`),
    },
    {
      id: "message",
      label: t("alertsView.message"),
      minWidth: 170,
    },
  ];

  const rowClicked = (alert: Alert): void => {
    if (alert.deviceId) {
      navigate(`/device/${alert.deviceId}`);
    }
  };

  const checkMultipleAlerts = (): void => {
    store.checkMultipleAlerts(store.selectedAlerts);
  };

  const handlePageChange = (p: number): void => {
    setCurrentPage(p);
  };

  useEffect(() => {
    let mounted = true;
    console.log("xuseEffect,");
    store
      .loadActiveAlertsForUser(currentPage, pageSize, sortBy, alertSortDirection)
      .catch(() => {
        if (mounted) {
          // TODO check error response for forbidden or unauthenticated etc.
          // setForbidden(true);
        }
      });

    return function cleanup() {
      mounted = false;
      store.clearState();
    };
  }, [store, currentPage, sortBy, alertSortDirection, pageSize]);

  return (
      <>
          <RouteDisplay currentLink="/alerts" currentName={t("navigation.alerts")} />
          <Container className={classes.container}>
              <PageTitle title={t("alertsView.pageTitle")} />
              { store.selectedAlerts.length > 0 && (
                  <Button
                    style={{
                      backgroundColor: theme.palette.error.dark,
                      marginLeft: 25,
                      marginBottom: 25,
                      color: "white",
                    }}
                    variant="contained"
                    id="checkSelectedAlerts"
                    onClick={checkMultipleAlerts}
                  >
                      {t("alertsView.checkAlertsButton")}
                  </Button>
              ) }
              {(store.activeAlertsForUser?.length || []) > 0 ? (

                  <AlertTable<Alert>
                    columns={alertColumns}
                    count={store.totalAlerts}
                    rows={store.activeAlertsForUser || []}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={setPageSize}
                    currentPage={currentPage}
                    currentRowsPerPage={pageSize}
                    sort={setSortBy}
                    setSortDirection={setAlertSortDirection}
                    clickRowCallback={rowClicked}
                    toggleRowSelection={store.toggleAlertSelection}
                  />
              ) : (
                  <div>
                      {t("alertsView.noActiveAlerts")}
                  </div>
              )}
          </Container>
      </>
  );
});
