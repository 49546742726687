import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  container: {
    paddingTop: 0,
    height: "100%",
    minWidth: "80%",
    display: "flex",
    justifyContent: "center",
  },
  spinner: {
    marginTop: "20%",
  },
}));
