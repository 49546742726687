import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${blue[900]}`,
    boxShadow: theme.shadows[5],
    minWidth: "20%",
    "& .MuiStepConnector-line": {
      marginLeft: 15,
    },

  },
  modalHeader: {
    backgroundColor: blue[100],
  },
  sectionHeader: {
    padding: theme.spacing(2, 2, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.light,
    "& .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
    },
  },
  section: {},
  input: {
    width: "100%",
  },
  backButton: {
    float: "left",
  },
  nextButton: {
    float: "right",
  },
  deleteButton: {
    float: "right",
    backgroundColor: "#d32f2f",
    "&:hover": {
      backgroundColor: "#c62828",
    },
  },
  navigation: {
    padding: theme.spacing(6, 6, 6),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  navButtons: {
    display: "block",
    marginBottom: "15%",
  },
  subsection: {
    padding: theme.spacing(2, 2, 2),
    display: "flex",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "fit-content",
  },
  addTagIcon: {
    cursor: "pointer",
    alignSelf: "center",
    marginLeft: 10,
  },
  setTags: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  setTag: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: 15,
    marginLeft: 10,
    marginTop: 15,
    padding: 10,
    width: "fit-content",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    cursor: "pointer",
  },
  tagSet: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    borderRadius: 15,
    marginLeft: 10,
    marginTop: 15,
    padding: 10,
    width: "fit-content",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    cursor: "pointer",
  },
  activeCheckbox: {
    marginLeft: 0,
  },
  routingSelection: {
    backgroundColor: theme.palette.background.paper,
    padding: 20,
  },
  selectedRow: {
    backgroundColor: blue[600],
    "& .MuiTableCell-body": {
      color: theme.palette.primary.contrastText,
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  routingPlanSelectionTitle: {
    marginBottom: 25,
    marginTop: 25,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.divider}`,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  infoList: {
    marginTop: 30,
    marginBottom: 30,
    borderRadius: "10px !important",
    backgroundColor: theme.palette.primary.light,
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.contrastText,
    },
  },
  productIdentifier: {
    marginBottom: 15,
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
  },
  firstName: {
    width: "37.5%",
  },
  divider: {
    width: "5%",
  },
  lastName: {
    width: "57.5%",
  },
  generatePasswordButton: {
    marginLeft: "2%",
    marginRight: "2%",
    height: "50%",
    alignSelf: "center",
  },
  copyIcon: {
    color: "grey",
    cursor: "pointer",
  },
  genFormLabel: {
    marginLeft: "16px",
  },

  genFormLabel2: {
    marginLeft: "16px",
    marginTop: "16px",
    display: "block",
  },

  genFormLabel3: {
    marginLeft: "16px",
    marginTop: "16px",
    marginBottom: "16px",
    display: "block",
  },

  miniError: {
    color: "#EE145B !important",
    marginLeft: "14px",
    marginRight: "14px",
    marginTop: "3px",
    marginBottom: "0px",
    fontSize: "0.75rem",
    textAlign: "left",
    fontFamily: "sans-serif",
    fontWeight: 400,
    lineHeight: "1.66",
  },
}));
