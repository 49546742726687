import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({

  titleContainer: {
    display: "inline-flex",
    maxWidth: "100%",
    height: "fit-content",
    paddingTop: 10,
    paddingBottom: 10,
  },
  rootContainer: {
    width: "100%",
    height: "100%",
    padding: 0,
  },

  contentContainer: {
    maxWidth: "100%",
    borderTop: "none",
    paddingTop: "2%",
    paddingLeft: "5%",
    marginTop: "2%",
  },
  inlineFlexContainerCentered: {
    display: "inline-flex",
    maxWidth: "100%",
    justifyContent: "center",
  },
  leftFloat: {
    float: "left",
  },
  table: {
    width: "100%",
  },
  tableRow: {
    width: "100%",
  },
  tableRowSpacer: {
    height: 5,
  },
  tableRowTitle: {
    width: "30%",
  },
  buttonContainer: {
    marginTop: "5%",
    display: "inline-flex",
    justifyContent: "flex-end",
    width: "100%",
    "& .MuiButton-root": {
      marginRight: 20,
      borderRadius: 25,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 15,
      width: "40%",
    },
  },
  modifying: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));
