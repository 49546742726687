import { useTranslation } from "react-i18next";
import { Box, Card, CardActionArea, CardContent, Container, Fade, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import StopIcon from "@mui/icons-material/Stop";
import useStyles from "./FrontPageViewStyles";
import PieChart from "../../components/pieChart/PieChart";
import AlertsBox from "./frontPageSubComponents/EventsAndAlerts/Alerts";
import { UserInfo } from "../../models/User";
import ActiveAlertCount from "../../models/ActiveAlertCount";
import StatCard from "../../components/statCard/StatCard";

interface IFrontPageViewProps {
    users?: UserInfo[],
    totalDevices: number,
    totalAlerts?: ActiveAlertCount[],
}
export default ({ users, totalDevices, totalAlerts }: IFrontPageViewProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
      <Fade in>
          <Container className={classes.container}>
              <Grid
                container
                item
                xs={12}
                className={classes.headCardsContainer}
                justifyContent="flex-start"
                alignItems="center"
              >
                  <StatCard
                    icon="Devices"
                    header={totalDevices.toString()}
                    content={t("frontPage.totalDevices")}
                    id="metersview-totalmeters-card"
                    iconColor={theme.palette.primary.main}
                  />
                  <StatCard
                    icon="CheckCircle"
                    header={totalAlerts?.length}
                    content={t("frontPage.totalAlerts")}
                    id="alertsview-totalalerts-card"
                    iconColor={theme.palette.primary.main}
                  />
                  <StatCard
                    icon="Person"
                    header={users?.length}
                    content={t("frontPage.totalUsers")}
                    id="locationsview-consumers-card"
                    iconColor={theme.palette.primary.main}
                  />
              </Grid>
          </Container>
      </Fade>
  );
};
