import { get, post, put } from "./HttpUtils";
import Alert, { AlertResponseWrapper } from "../models/Alert";
import CheckAlert from "../models/CheckAlert";
import ActiveAlertCount from "../models/ActiveAlertCount";

const api = process.env.REACT_APP_API_URL || "";
export const getActiveAlertsForDevice = async (organizationId: string, deviceId: string): Promise<Alert[]> => await get(`${api}/service/alerts/organization/${organizationId}/device/${deviceId}/active`) as Alert[];
export const getAllAlertsForDevice = async (organizationId: string, deviceId: string): Promise<Alert[]> => await get(`${api}/service/alerts/organization/${organizationId}/device/${deviceId}/all`) as Alert[];
export const getActiveAlertsForOrganization = async (organizationId: string): Promise<Alert[]> => await get(`${api}/service/alerts/organization/${organizationId}`) as Alert[];
export const getActiveAlertsForUser = async (): Promise<Alert[]> => await get(`${api}/service/alerts/active`) as Alert[];

export const getAlertsPaged = async (
  page: number,
  pageSize: number,
  sortBy?: string,
  sortDirection?: string,
): Promise<AlertResponseWrapper> => {
  const dir = sortDirection !== "desc" && sortDirection !== "asc"
    ? "desc"
    : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  return (await get(
    `${api}/service/alerts/active?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}`,
  )) as AlertResponseWrapper;
};

export const getActiveAlertCountsForUser = async (): Promise<ActiveAlertCount[]> => await get(`${api}/service/alerts/count/active`) as ActiveAlertCount[];
export const checkAlert = async (checkAlertModel: CheckAlert): Promise<Alert> => await put(`${api}/service/alerts/${checkAlertModel.id}/check`, checkAlertModel) as Alert;
export const checkMultipleAlerts = async (alertIds: string[]): Promise<string[]> => await post(`${api}/service/alerts/check`, alertIds) as string[];
