import React, { useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import MainView from "./views/main/MainView";
import { useRootStore } from "./RootStateContext";

export default observer((): JSX.Element => {
  const authStore = useRootStore().authenticationStore;
  const [authenticated, setAuthenticated] = useState(false);

  useLayoutEffect(() => {
    // start auth process by calling getAuthenticatedUserInfo if not authenticated
    if (authStore.authenticated === false) {
      authStore.fetchCurrentUserInfo().then(() => {
        setAuthenticated(true);
      }).catch(() => {
        authStore.login();
      });
    }
  });

  return (
      <>
          {authenticated
          && <MainView />}
      </>
  );
});
