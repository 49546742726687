import { get, post, put, del } from "./HttpUtils";
import Organization, { OrganizationResponseWrapper } from "../models/Organization";
import Tag from "../models/Tag";

const api = process.env.REACT_APP_API_URL || "";

export const getOrganizations = async (): Promise<Organization[]> => {
  const content = await get(`${api}/service/organization`) as OrganizationResponseWrapper;
  const organizations = content.content;
  return organizations;
};

export const getOrganizationsPaged = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<OrganizationResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";

  return await get(`${api}/service/organization?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useSearch}`) as OrganizationResponseWrapper;
};

export const getOrganizationTags = async (): Promise<Tag[]> => await get(`${api}/service/organization/tag`) as Tag[];

export const getOrganizationTagsById = async (orgId: string): Promise<Tag[]> => await get(`${api}/service/organization/${orgId}/tag`) as Tag[];

export const editOrganization = async (org: Organization, id: string): Promise<Organization> => await put(`${api}/service/organization/${id}`, org) as Organization;

export const saveNewOrganization = async (org: Organization): Promise<any> => post(`${api}/service/organization`, org);

export const deleteOrganization = async (orgId: string): Promise<void> => del(`${api}/service/organization/${orgId}`);
