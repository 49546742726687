import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: 30,
    minWidth: "100%",
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: "64px",
  },
}));
