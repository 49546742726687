import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${blue[900]}`,
    boxShadow: theme.shadows[5],
    minWidth: "20%",

  },
  modalHeader: {
    backgroundColor: blue[100],
  },
  sectionHeader: {
    padding: theme.spacing(2, 2, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.light,
    "& .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
    },
  },
  section: {
    padding: theme.spacing(2, 2, 2),
  },
  subsection: {
    padding: "10px",
    display: "flex",
  },
  radio: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  input: {
    width: "100%",
  },
  backButton: {
    float: "left",
  },
  nextButton: {
    float: "right",
  },
  navigation: {
    padding: theme.spacing(6, 6, 6),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  navButtons: {
    display: "block",
    marginBottom: "15%",
  },
}));
