import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, Collapse, Divider, Fade, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { RawData } from "../../models/RawData";
import useStyles from "./RawDataModalStyles";
import { displayableDate } from "../../util/dateutil";

interface IModalProps {
    rawData?: RawData[];
    open: boolean;
    handleClose: () => void;
    title: string;
    modalType: string;
    fetchingData: boolean;
}
// todo: make this somehow presentable, add timestamps
export default ({ fetchingData, rawData, open, handleClose, title, modalType }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const clear = (): void => {
    handleClose();
  };

  const expandRow = (id: string) : void => {
    setExpandedRows((rows) => {
        const newRows = new Set(rows);
        if (newRows.has(id)) { newRows.delete(id); } else { newRows.add(id); }
        return newRows;
    })
  }

  if (fetchingData) {
    return (
        <>
            <Modal
              className={classes.modal}
              open={open}
              onClose={clear}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.sectionHeader}>
                            <Typography variant="h6" component="h2" id="noRawDataText">{t("singleDeviceView.noRawData")}</Typography>
                        </div>
                        <Grid container className={classes.loadingGrid} alignContent="center" justifyContent="center">
                            <CircularProgress
                              size={50}
                            />
                        </Grid>

                        <Grid container className={classes.footer} alignContent="center" justifyContent="center">
                            <Button variant="outlined" onClick={handleClose} color="primary" id="CloseRawDataModal">
                                {t("common.close")}
                            </Button>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </>
    );
  }

  if (rawData === undefined || rawData === null || rawData.length === 0) {
    return (
        <>
            <Modal
              className={classes.modal}
              open={open}
              onClose={clear}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.sectionHeader}>
                            <Typography variant="h6" component="h2" id="noRawDataText">{t("singleDeviceView.noRawData")}</Typography>
                        </div>

                        <Grid container className={classes.loadingGrid} alignContent="center" justifyContent="center">
                            <Typography variant="h6" component="h2" id="noRawDataText2">{t("singleDeviceView.noRawData")}</Typography>
                        </Grid>

                        <Grid container className={classes.footer} alignContent="center" justifyContent="center">
                            <Button variant="outlined" onClick={handleClose} color="primary" id="CloseRawDataModal">
                                {t("common.close")}
                            </Button>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </>
    );
  }
  return (
      <>
          <Modal
            className={classes.modal}
            open={open}
            onClose={clear}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id={`rawdataModal${modalType}Title`}>{title}</Typography>
                      </div>
                      <div className={classes.section}>
                          <List className={classes.tableRoot}>
                              {rawData.map((data) => (
                                  <React.Fragment key={data.invocationId}>
                                      <ListItemButton onClick={() => expandRow(data.invocationId)}>
                                          <ListItemText primary={`${displayableDate(data.timestamp, "DATETIME")} - ${data.invocationId}`} />
                                          {expandedRows.has(data.invocationId) ? <ExpandLess /> : <ExpandMore />}
                                      </ListItemButton>
                                      <Collapse
                                        in={expandedRows.has(data.invocationId)}
                                        timeout={100}
                                        unmountOnExit
                                        style={{
                                            padding: "0 10px 0 10px",
                                        }}
                                      >
                                          <pre>{JSON.stringify(data.jsonBody, undefined, 4)}</pre>
                                      </Collapse>
                                      <Divider />
                                  </React.Fragment>
                              ))}
                          </List>
                      </div>
                      <Grid container className={classes.footer} alignContent="center" justifyContent="center">
                          <Button variant="outlined" onClick={handleClose} color="primary" id="CloseRawDataModal">
                              {t("common.close")}
                          </Button>
                      </Grid>
                  </div>
              </Fade>
          </Modal>
      </>
  );
};
