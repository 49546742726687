import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import { RootStateProvider } from "./RootStateContext";

const domNode = document.getElementById("root");
const root = createRoot(domNode!);

root.render((
    <CookiesProvider>
        <RootStateProvider>
            <App />
        </RootStateProvider>
    </CookiesProvider>
));
