import { observer } from "mobx-react-lite";
import React, { useLayoutEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useRootStore } from "../RootStateContext";
import BaseStore from "./BaseStore";

export default observer((): JSX.Element => {
  const store = useRootStore();
  const errors = Object.values(store)
    .filter((s) => s instanceof BaseStore)
    .filter((s) => s.error !== null && s.error !== undefined)
    .map((s) => s.error);

  useLayoutEffect(() => {
    errors.forEach((error) => toast.error(error, {
      toastId: "ErrorToast",
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
    }));
  });

  return (
      <ToastContainer autoClose={3000} />
  );
});
