import React from "react";
import { Typography } from "@mui/material";
import useStyles from "./PageTitleStyles";

interface Title {
  title: string;
}

export default ({ title }: Title): JSX.Element => {
  const classes = useStyles();

  return (
      <Typography className={classes.headerContainer} variant="h4" component="h2">{title}</Typography>
  );
};
