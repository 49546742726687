import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({

  deviceStatusRootContainer:
  {
    backgroundColor: "rgba(160,187,243,0.29)",
    height: "100%",
  },

  deviceStatusRootContainerTop:
  {
    width: "100%",
    overflow: "hidden",
    height: "calc(100% - 50px)",
  },

  checkBoxIconContainer: {
    width: "30%",
    height: "80%",
    maxWidth: "30%",
    float: "left",
    overflow: "none",
  },
  statusOnlineIcon: {
    color: theme.palette.primary.main,
    width: "70%",
    height: "70%",
  },
  statusOfflineIcon: {
    color: theme.palette.secondary.main,
    width: "70%",
    height: "70%",
  },
  statusInitialIcon: {
    color: theme.palette.secondary.main,
    width: "70%",
    height: "70%",
  },
  agoText: {
    paddingLeft: "5px",
  },
  subtitles: {
    display: "flex",
    float: "left",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "auto",
    height: "100%",
    flexDirection: "column",
  },
  fetchDataContainer: {
    height: "50px",
    paddingBottom: "15px",
  },
  inlineFlexContainer: {
    display: "inline-flex",
    maxWidth: "100%",
  },
  inlineFlexContainerCentered: {
    display: "inline-flex",
    maxWidth: "100%",
    paddingTop: 7,
    justifyContent: "center",
  },
  buttonsContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  fetchDataButton: {
    width: "20% !important",
  },

  fetchDataText: {
    color: theme.palette.primary.main,
    paddingTop: 5,
  },
  leftFloat: {
    float: "left",
  },
  spinner: {
    marginTop: "15px",
  },

}));
