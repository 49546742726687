import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../RootStateContext";
import OrganizationsAndUsersView from "../OrganizationsAndUsersView";
import UnauthorizedComponent from "../../../../components/unauthorizedComponent/UnauthorizedComponent";

export default observer((): JSX.Element => {
  const { organizationStore } = useRootStore();
  const { userStore } = useRootStore();
  const { organizations } = organizationStore;
  const { users } = userStore;

  const [userPageSize, setUserPageSize] = useState(25);
  const [userPage, setUserPage] = useState(0);
  const [userSortBy, setUserSortBy] = useState("");
  const [userSortDirection, setUserSortDirection] = useState("desc");
  const [userSearchValue, setUserSearchValue] = useState("");
  const [usersLoadingState, setUsersLoadingState] = useState(false);

  const [orgPageSize, setOrgPageSize] = useState(25);
  const [orgPage, setOrgPage] = useState(0);
  const [orgSortBy, setOrgSortBy] = useState("");
  const [orgSortDirection, setOrgSortDirection] = useState("desc");
  const [orgSearchValue, setOrgSearchValue] = useState("");
  const [orgsLoadingState, setOrgsLoadingState] = useState(false);

  const [forbidden, setForbidden] = useState(false);

  const resetAllOptions = () : void => {
    setUserPageSize(25);
    setUserPage(0);
    setUserSortBy("");
    setUserSortDirection("desc");
    setUserSearchValue("");

    setOrgPageSize(25);
    setOrgPage(0);
    setOrgSortBy("");
    setOrgSortDirection("desc");
    setOrgSearchValue("");
  };

  const userSort = (field: string): void => {
    setUserSortBy(field);
  };

  const handleUserPageChange = (p: number): void => {
    setUserPage(p);
  };

  const handleUserPageSizeChange = (p: number): void => {
    setUserPage(0);
    setUserPageSize(p);
  };

  const orgSort = (field: string): void => {
    setOrgSortBy(field);
  };

  const handleOrgPageChange = (p: number): void => {
    setOrgPage(p);
  };

  const handleOrgPageSizeChange = (p: number): void => {
    setOrgPage(0);
    setOrgPageSize(p);
  };

  useEffect(() => {
    let mounted = true;
    setUsersLoadingState(true);
    (async () => {
      await userStore.loadUsersPaged(userPage, userPageSize, userSortBy, userSortDirection, userSearchValue).catch(() => {
        if (mounted) {
          // TODO check error response for forbidden or unauthenticated etc.
          setForbidden(true);
        }
      });
      setUsersLoadingState(false);
    })();
    return function cleanup() {
      mounted = false;
      userStore.clearState();
    };
  }, [userStore, userSortBy, userPage, userPageSize, userSortDirection, userSearchValue]);

  useEffect(() => {
    let mounted = true;
    setOrgsLoadingState(true);
    (async () => {
      await organizationStore.loadOrganizationsPaged(orgPage, orgPageSize, orgSortBy, orgSortDirection, orgSearchValue).catch(() => {
        if (mounted) {
          // TODO check error response for forbidden or unauthenticated etc.
          setForbidden(true);
        }
      });
      setOrgsLoadingState(false);
    })();
    return function cleanup() {
      mounted = false;
      organizationStore.clearState();
    };
  }, [organizationStore, orgPage, orgPageSize, orgSortBy, orgSortDirection, orgSearchValue]);

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  /* if (organizations === null || organizations === undefined || organizations.length === 0) {
    return (
        <PendingCircle />
    );
  }

  if (users === null || users === undefined || users.length === 0) {
    return (
        <PendingCircle />
    );
  } */

  return (
      <OrganizationsAndUsersView
        organizations={organizations || []}
        totalOrgs={organizationStore.totalOrganizations}
        users={users}
        totalUsers={userStore.totalUsers}
        userSort={userSort}
        setUserPage={handleUserPageChange}
        setUserPageSize={handleUserPageSizeChange}
        setUserSortDirection={setUserSortDirection}
        userPage={userPage}
        userSearch={setUserSearchValue}
        userRowsPerPage={userPageSize}
        usersLoading={usersLoadingState}
        orgSort={orgSort}
        setOrgPage={handleOrgPageChange}
        setOrgPageSize={handleOrgPageSizeChange}
        setOrgSortDirection={setOrgSortDirection}
        orgPage={orgPage}
        orgSearch={setOrgSearchValue}
        orgRowsPerPage={orgPageSize}
        orgsLoading={orgsLoadingState}
        resetAll={resetAllOptions}
      />
  );
});
