import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({

  titleContainer: {
    display: "inline-flex",
    maxWidth: "100%",
    height: "fit-content",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  rootContainer: {
    width: "100%",
    height: "100%",
    padding: 0,
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  eventLogButton: {
    float: "right",
    marginTop: 10,
  },
  linkToDevice: {
    padding: 2,
    border: "1px solid white",
    borderRadius: 5,
    marginLeft: 5,
    backgroundColor: "white",
    color: "black",
    textDecoration: "none",
  },
  alertEvent: {
    padding: 5,
    backgroundColor: theme.palette.error.main,
    color: "white",
  },
  contentContainer: {
    display: "inline-flex",
    maxWidth: "100%",
    borderTop: "none",
    marginTop: "-1%",
    overflowY: "auto",
    maxHeight: "357px",
  },
  inlineFlexContainerCentered: {
    display: "inline-flex",
    maxWidth: "100%",
    justifyContent: "center",
  },
  leftFloat: {
    float: "left",
  },
  alertBox: {},
  spinner: {
    marginTop: "15px",
  },
}));
