import { observable, action, makeObservable, runInAction } from "mobx";
import { parseISO } from "date-fns";
import { batchArchiveDevices, deleteDevice, getConnectivityProducts, getDevice, getDeviceDecodings, getDeviceModels, getDevices, getDevicesPaged, saveNewDevice, updateDevice, requestUpdate } from "../api/DeviceApi";
import DeviceConfig from "../models/DeviceConfig";
import Device from "../models/Device";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import RoutingPlan from "../models/RoutingPlan";
import ConnectivityProduct from "../models/ConnectivityProduct";
import { DeviceModel } from "../models/DeviceModel";
import Decoding from "../models/Decoding";

export default class DeviceConfigStore extends BaseStore {
  configs: DeviceConfig[] = [];

  routingPlans: RoutingPlan[] = [];

  devices?: Device[];

  device?: Device;

  config?: DeviceConfig;

  totalDevices = 0;

  connectivityProducts: ConnectivityProduct[] = [];

  deviceModels: DeviceModel[] = [];

  selectedDevices: Device[] = [];

  selectedDevicesHaveSameOrganization = false;

  decodings: Decoding[] = [];

  loadingDecodings = false;

  toggleDeviceSelection = (d: Device): void => {
    if (this.selectedDevices.includes(d)) this.selectedDevices = this.selectedDevices.filter((e) => e !== d);
    else this.selectedDevices = [...this.selectedDevices, d];

    if (this.selectedDevices.length > 0 && this.selectedDevices.every((d) => d.organizationId === this.selectedDevices[0].organizationId)) {
      this.selectedDevicesHaveSameOrganization = true;
    } else {
      this.selectedDevicesHaveSameOrganization = false;
    }
  }

  resetDeviceSelection = (): void => {
    this.selectedDevices = [];
  }

  batchArchiveDevices = async (devices: Device[]): Promise<void> => {
    try {
      const archived: string[] = await batchArchiveDevices(devices.map((e) => e.id || ""));
      runInAction(() => {
        this.devices = this.devices?.filter((d) => {
          if (!d.id) return false;
          return !archived.includes(d.id);
        });
        const failureList = devices.filter((e) => !e.id || !archived.includes(e.id));
        if (failureList.length > 0) {
          this.setError(i18n.t("deviceStore.archiveFailedForXDevices", {
            ids: failureList.join(", "),
          }));
        }
        this.resetDeviceSelection();
      });
    } catch (e) {
      this.setError(i18n.t("deviceStore.archiveFailedForAllDevices"));
      console.error(e.stack);
    }
  }

  loadDevice = async (deviceId: string): Promise<void> => {
    try {
      const device = await getDevice(deviceId);
      runInAction(() => {
        if (device && device.deviceConfig && device.deviceConfig.deviceRoutingPlan && device.deviceConfig.deviceRoutingPlan.length > 0) {
          device.deviceConfig.deviceRoutingPlan = JSON.parse(<string>(<unknown>(device.deviceConfig.deviceRoutingPlan)));
        } else if (device && device.deviceConfig && (!device.deviceConfig.deviceRoutingPlan || device.deviceConfig.deviceRoutingPlan.length === 0)) {
          device.deviceConfig.deviceRoutingPlan = undefined;
        }

        if (device && device.deviceConfig) {
          device.deviceConfig.timestamp = parseISO(device.deviceConfig.timestamp as unknown as string);
        }

        this.device = device;
      });
    } catch (e) {
      this.setError(i18n.t("deviceStore.singleDeviceFetchError"));
      console.error(e.stack);
    }
  }

  // Use the paged fetch if possible
  loadDevices = async (): Promise<void> => {
    try {
      const d = await getDevices();
      runInAction(() => {
        this.devices = d;
      });
    } catch (e) {
      this.setError(i18n.t("deviceStore.devicesFetchError"));
      this.devices = [];
      console.error(e.stack);
    }
  }

  loadTotalAmountOfDevices = async (): Promise<void> => {
    try {
      const devicesResp = await getDevicesPaged(0, 1);
      runInAction(() => {
        this.totalDevices = devicesResp.totalElements;
      });
    } catch (e) {
      this.setError(i18n.t("deviceStore.devicesFetchError"));
      this.devices = [];
      console.error(e.stack);
    }
  }

  loadDevicesPaged = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, organization?: string, search?: string): Promise<void> => {
    try {
      const devicesResp = await getDevicesPaged(page, pageSize, sortBy, sortDirection, archived, organization, search);

      runInAction(() => {
        this.devices = devicesResp.content;
        this.totalDevices = devicesResp.totalElements;
      });
    } catch (e) {
      this.setError(i18n.t("deviceStore.devicesFetchError"));
      this.devices = [];
      console.error(e.stack);
    }
  }

  updateDevice = async (device: Device): Promise<Device> => {
    try {
      if (!device.id) throw Error();
      const updated = await updateDevice(device.id, device);
      console.log(`Updated device: ${JSON.stringify(updated)}`);

      runInAction(() => {
        this.device = updated;
      });

      return updated;
    } catch (e) {
      this.setError(i18n.t("deviceStore.deviceUpdateError"));
      console.error(e.stack);
      console.log(JSON.stringify(e));
      return Promise.reject(e.stack);
    }
  }

  requestDeviceUpdate = async (device: Device): Promise<void> => {
    try {
      if (!device.id) throw Error();
      await requestUpdate(device.id, device);
    } catch (e) {
      this.setError(i18n.t("singleDeviceView.deviceUpdateRequestFailed"));
      console.error(e.stack);
    }
  };

  sortConfigs = (): void => {
    this.configs = this.configs.sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp));
  }

  deleteDevice = async (device: Device): Promise<void> => {
    try {
      await deleteDevice(device);
    } catch (e) {
      this.setError(i18n.t("deviceStore.singleDeviceDeletionError"));
      console.error(e.stack);
    }
  }

  saveNewDevice = async (device: Device): Promise<Device> => {
    try {
      const newDevice = await saveNewDevice(device);
      console.log(`Saved new device config: ${JSON.stringify(newDevice)}`);
      return newDevice;
    } catch (e) {
      this.setError(i18n.t("deviceStore.singleDeviceSaveError"));
      console.error(e.stack);
      console.log(JSON.stringify(e));
      return Promise.reject(e.stack);
    }
  }

  loadConnectivityProducts = async (organizationId : string): Promise<void> => {
    try {
      const c = await getConnectivityProducts(organizationId);
      runInAction(() => {
        this.connectivityProducts = c;
      });
    } catch (e) {
      this.setError(i18n.t("deviceStore.connectivityProductsFetchError"));
      this.connectivityProducts = [];
      console.error(e.stack);
    }
  }

  loadDeviceModels = async (): Promise<void> => {
    try {
      const d = await getDeviceModels();
      runInAction(() => {
        this.deviceModels = d;
      });
    } catch (e) {
      this.setError(i18n.t("deviceStore.deviceModelsFetchError"));
      this.deviceModels = [];
      console.error(e.stack);
    }
  }

  loadDeviceDecodings = async (): Promise<void> => {
    try {
      this.loadingDecodings = true;
      const decodings = await getDeviceDecodings();
      runInAction(() => {
        this.decodings = decodings;
        this.loadingDecodings = false;
      });
    } catch (e) {
      this.setError(i18n.t("deviceStore.deviceDecodingsFetchError"));
      this.decodings = [];
      this.loadingDecodings = false;
      console.error(e.stack);
    }
  }

  clearState = (): void => {
    this.configs = [];
    this.config = undefined;
    this.device = undefined;
    this.devices = undefined;
    this.error = undefined;
    this.selectedDevices = [];
    this.connectivityProducts = [];
    this.deviceModels = [];
    this.selectedDevicesHaveSameOrganization = false;
    this.decodings = [];
  }

  constructor() {
    super();
    makeObservable(this, {
      configs: observable,
      config: observable,
      selectedDevices: observable,
      devices: observable,
      device: observable,
      totalDevices: observable,
      connectivityProducts: observable,
      deviceModels: observable,
      selectedDevicesHaveSameOrganization: observable,
      decodings: observable,
      loadingDecodings: observable,
      loadDevice: action,
      loadDevicesPaged: action,
      sortConfigs: action,
      saveNewDevice: action,
      deleteDevice: action,
      clearState: action,
      updateDevice: action,
      loadTotalAmountOfDevices: action,
      toggleDeviceSelection: action,
      resetDeviceSelection: action,
      batchArchiveDevices: action,
      loadDeviceModels: action,
      loadDeviceDecodings: action,
      requestDeviceUpdate: action,
    });
  }
}
