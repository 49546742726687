import { Button, Container, AppBar, Tab, Tabs, Typography, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { Edit } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageTitle from "../../../components/pageTitle/PageTitle";
import RouteDisplay from "../../../components/routeDisplay/RouteDisplay";
import Table, { Column } from "../../../components/table/TableWithPagination";
import theme from "../../../materialUITheme";
import Organization from "../../../models/Organization";
import { UserInfo, UserInfoForCreation, UserInfoForUpdating } from "../../../models/User";
import { useRootStore } from "../../../RootStateContext";
import CreateUserModal from "../createUserModal/CreateUserModal";
import CreateOrganizationModal from "../createOrganizationModal/CreateOrganizationModal";
import EditUserModal from "../editUserModal/EditUserModal";
import EditOrganizationModal from "../editOrganizationModal/EditOrganizationModal";
import useStyles from "./OrganizationsAndUsersViewStyles";
import ConfirmationModal from "../../../components/deleteDeviceModal/ConfirmationModal";
import PendingCircle from "../../../components/loadingCircle/loadingCircle";

interface IUsersViewProps {
  organizations: Organization[];
  users?: UserInfo[];
  // OPTIONS FOR USERS TABLE
  setUserPage: (page: number) => void;
  setUserPageSize: (pageSize: number) => void;
  userSort: (s: string) => void;
  setOrgPage: (page: number) => void;
  setOrgPageSize: (pageSize: number) => void;
  orgSort: (s: string) => void;
  totalUsers: number;
  totalOrgs: number;
  userPage: number;
  userRowsPerPage: number;
  setUserSortDirection: (s: string) => void;
  userSearch: (s: string) => void;
  orgPage: number;
  orgRowsPerPage: number;
  setOrgSortDirection: (s: string) => void;
  orgSearch: (s: string) => void;
  usersLoading: boolean;
  orgsLoading: boolean;
  resetAll: () => void;
}

interface TabTitle {
 label: string,
 id: string,
 disabled: boolean,
}

interface TabContent {
 index: number,
 contentElement: JSX.Element
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any; // TODO: Fix type
  value: any; // TODO: Fix type
 }

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
          {value === index && <Box p={3}>{children}</Box>}
      </Typography>
  );
};

export default observer(({ organizations,
  users,
  setUserPage,
  setUserPageSize,
  userSort,
  setOrgPage,
  setOrgPageSize,
  orgSort,
  userPage,
  userRowsPerPage,
  setUserSortDirection,
  userSearch,
  orgPage,
  orgRowsPerPage,
  setOrgSortDirection,
  orgSearch,
  usersLoading,
  orgsLoading,
  resetAll }: IUsersViewProps): JSX.Element => {
  const { userStore } = useRootStore();
  const { organizationStore } = useRootStore();
  const classes = useStyles();
  const { t } = useTranslation();
  const [userEditModalOpen, setUserEditModalOpen] = useState(false);
  const [orgEditModalOpen, setOrgEditModalOpen] = useState(false);
  const [userCreationModalOpen, setUserCreationModalOpen] = useState(false);
  const [orgCreationModalOpen, setOrgCreationModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const [searchErrorState, setSearchErrorState] = useState<boolean>(false);
  const [searchErrorText, setSearchErrorText] = useState<string>("");

  const searchInput = useRef<HTMLInputElement>();

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) : void => {
    setCurrentTab(newValue);
    userStore.resetUserSelection();
    resetAll();
  };

  const closeUserCreationModal = (): void => {
    setUserCreationModalOpen(false);
  };

  const closeOrgCreationModal = (): void => {
    setOrgCreationModalOpen(false);
  };

  const closeUserEditModal = (): void => {
    setUserEditModalOpen(false);
  };

  const closeOrgEditModal = (): void => {
    setOrgEditModalOpen(false);
  };

  const cellClickedCallbackForUser = (user: UserInfo): void => {
    userStore.setSelectedUser(user);
    setUserEditModalOpen(true);
  };

  const cellClickedCallbackForOrg = (org: Organization): void => {
    organizationStore.setSelectedOrganization(org);
    setOrgEditModalOpen(true);
  };

  const doSearch = (): void => {
    const searchVal = searchInput?.current?.value || "";

    if (searchVal.length <= 2) {
      setSearchErrorState(true);
      setSearchErrorText(t("common.searchErrorText"));
      return;
    }
    setSearchErrorState(false);
    setSearchErrorText("");

    if (searchVal !== undefined && searchInput && searchInput.current) {
      switch (currentTab) {
        case 0:
          orgSearch(searchVal);
          break;

        case 1:
          userSearch(searchVal);
          break;

        default:
          break;
      }
      searchInput.current.value = "";
    }
  };

  // TODO: move store calls to rootview (?)
  const saveUser = async (u: UserInfoForCreation): Promise<void> => {
    try {
      await userStore.saveNewUser(u);
      if (userStore.error === undefined) {
        toast.info(t("usersView.creationSuccess"), {
          toastId: "UserCreatedToast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } catch (e) {
      console.error("user creation failed");
    }
  };

  const updateUser = async (u: UserInfoForUpdating, id: string): Promise<void> => {
    await userStore.updateUser(u, id);
    if (userStore.error === undefined) {
      toast.info(t("usersView.updateSuccess"), {
        toastId: "userUpdatedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const updateOrg = async (o: Organization, id: string): Promise<void> => {
    await organizationStore.updateOrganization(o, id);
    if (organizationStore.error === undefined) {
      toast.info(t("usersView.orgUpdateSuccess"), {
        toastId: "orgUpdatedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };
  /*  */

  const deleteAllSelected = async (): Promise<void> => {
    await userStore.deleteUsers(userStore.selectedUsers);
    if (userStore.error === undefined) {
      toast.info(t("usersView.deleteSuccess"), {
        toastId: "UserDeletedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const saveOrganization = async (o: any): Promise<void> => {
    try {
      await organizationStore.saveNewOrganization(o);
      if (userStore.error === undefined) {
        toast.info("Lisäys onnistui", {
          toastId: "OrgCreatedToast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } catch (e) {
      console.error("organization creation failed");
    }
  };

  const organizationColumns: Column[] = [
    {
      id: "name", label: "Nimi", minWidth: 100,
    },
    /* {
      id: "organizationType", label: "Tyyppi", minWidth: 170,
    }, */
    {
      id: "accountId", label: "AccountId", minWidth: 170,
    },
    {
      id: "contactPerson", label: t("common.contactPerson"), minWidth: 170,
    },
  ];

  const userColumns: Column[] = [
    {
      id: "firstName", label: "Etunimi", minWidth: 170,
    },
    {
      id: "lastName", label: "Sukunimi", minWidth: 170,
    },
    {
      id: "email", label: "Sähköposti", minWidth: 170,
    },
    {
      id: "organizationName", label: "Organisaatio", minWidth: 170,
    },
    {
      id: "active", label: "Aktiivinen", minWidth: 170, booleanType: true,
    },
  ];

  const tabTitles: TabTitle[] = [
    {
      label: "Organisaatiot", id: "organizationsTab", disabled: false,
    },
    {
      label: "Käyttäjät", id: "usersTab", disabled: false,
    },
  ];

  const tabContents: TabContent[] = [
    {
      index: 0,
      contentElement: <Table<Organization>
        columns={organizationColumns}
        count={organizationStore.totalOrganizations}
        rows={organizations}
        handlePageChange={setOrgPage}
        handlePageSizeChange={setOrgPageSize}
        currentPage={orgPage}
        currentRowsPerPage={orgRowsPerPage}
        sort={orgSort}
        setSortDirection={setOrgSortDirection}
        clickRowCallback={cellClickedCallbackForOrg}
      />,
    },
    {
      index: 1,
      contentElement: <Table<UserInfo>
        columns={userColumns}
        count={userStore.totalUsers}
        rows={users || []}
        handlePageChange={setUserPage}
        handlePageSizeChange={setUserPageSize}
        currentPage={userPage}
        currentRowsPerPage={userRowsPerPage}
        sort={userSort}
        setSortDirection={setUserSortDirection}
        clickRowCallback={cellClickedCallbackForUser}
        toggleRowSelection={userStore.toggleUserSelection}
        onClickIcon={<Edit />}
      />,
    },
  ];

  const printTabContents = () : JSX.Element[] => tabContents.map((tabContent) => {
    if (tabContent.index === 0 && organizations && organizations.length === 0) {
      if (orgsLoading) {
        return (
            <TabPanel key={tabContent.index} value={currentTab} index={tabContent.index}>
                <PendingCircle />
            </TabPanel>
        );
      }

      return (
          <TabPanel key={tabContent.index} value={currentTab} index={tabContent.index}>
              <div className={classes.noUsers}><Typography className={classes.headerContainer} variant="h4" component="h2" id="userview-noorgsfound">{t("usersView.noOrgs")}</Typography></div>
          </TabPanel>
      );
    }
    if (tabContent.index === 1 && users && users.length === 0) {
      if (usersLoading) {
          <TabPanel key={tabContent.index} value={currentTab} index={tabContent.index}>
              <PendingCircle />
          </TabPanel>;
      } else {
        return (
            <TabPanel key={tabContent.index} value={currentTab} index={tabContent.index}>
                <div className={classes.noUsers}><Typography className={classes.headerContainer} variant="h4" component="h2" id="userview-nousersfound">{t("usersView.noUsers")}</Typography></div>
            </TabPanel>
        );
      }
    }
    return (
        <TabPanel key={tabContent.index} value={currentTab} index={tabContent.index}>
            {tabContent.contentElement}
        </TabPanel>
    );
  });

  return (
      <>
          <RouteDisplay currentLink="/users" currentName={t("navigation.users")} />
          <CreateUserModal handleClose={closeUserCreationModal} submit={saveUser} open={userCreationModalOpen} />
          <CreateOrganizationModal handleClose={closeOrgCreationModal} submit={saveOrganization} open={orgCreationModalOpen} />
          <EditUserModal handleClose={closeUserEditModal} submit={updateUser} open={userEditModalOpen} />
          <EditOrganizationModal handleClose={closeOrgEditModal} submit={updateOrg} open={orgEditModalOpen} />
          <ConfirmationModal
            open={deleteModalOpen}
            text={t("usersView.deleteConfirmation", {
              ids: userStore.selectedUsers.map((e) => `${e.firstName} ${e.lastName}`).join(", "),
            })}
            handleClose={() => setDeleteModalOpen(false)}
            submit={deleteAllSelected}
          />
          <Container className={classes.container}>
              <PageTitle title="Ylläpidon käyttäjä- ja organisaatiohallinta" />
              <Button
                color="primary"
                variant="contained"
                id="AddNewUserButton"
                onClick={() => setUserCreationModalOpen(true)}
              >
                  {t("usersView.createNewUser")}
              </Button>
              {userStore.selectedUsers.length > 0 && (
                  <Button
                    style={{
                      backgroundColor: theme.palette.error.dark,
                      marginLeft: 25,
                      color: "white",
                    }}
                    variant="contained"
                    id="deleteSelectedUsers"
                    onClick={() => setDeleteModalOpen(true)}
                  >
                      {t("usersView.deleteSelectedUsers")}
                  </Button>
              ) }
              <Button
                style={{
                  marginLeft: 25,
                  color: "white",
                }}
                color="primary"
                variant="contained"
                id="AddNewOrganizationButton"
                onClick={() => setOrgCreationModalOpen(true)}
              >
                  {t("usersView.createNewOrganization")}
              </Button>
              <div className={classes.tabContainer}>
                  {/* <MyTabs tabSwitchedCallback={userStore.resetUserSelection} tabTitles={tabTitles} tabContents={tabContents} /> */}
                  <>
                      <AppBar position="static" color="default" className={classes.tabBar}>
                          <Tabs value={currentTab} onChange={handleChange} aria-label="Tabs">
                              {tabTitles.map((tab) => (
                                  <Tab key={tab.id} disabled={tab.disabled} label={tab.label} id={tab.id} aria-controls={tab.id} />
                              ))}
                          </Tabs>
                          <div className={classes.searchInput}>
                              <TextField
                                inputRef={searchInput}
                                onKeyDown={(e) => { if (e.key === "Enter") doSearch(); }}
                                className={classes.searchInput}
                                id="searchOrganizationsAndUsers"
                                label={t("usersView.userSearchHelpText1")}
                                placeholder={t("usersView.userSearchHelpText2")}
                                variant="outlined"
                                error={searchErrorState}
                                helperText={searchErrorText}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <Button onClick={() => doSearch()} className={classes.searchButton} variant="contained" color="primary">{t("deviceView.search")}</Button>
                          </div>
                      </AppBar>

                      {printTabContents()}
                  </>
              </div>
          </Container>
      </>
  );
});
