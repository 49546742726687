import React from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Button, Typography } from "@mui/material";
import useStyles from "./ConfirmationModalStyle";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  submit: () => void;
  text: string;
}

export default ({ open, handleClose, submit, text }: IModalProps): JSX.Element => {
  const classes = useStyles();

  const handleSubmit = (): void => {
    handleClose();
    submit();
  };

  return (
      <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.section}>
                          <Typography variant="h6" component="h2">{text}</Typography>
                      </div>
                      <div className={classes.buttons}>
                          <Button
                            className={classes.deleteButton}
                            onClick={handleSubmit}
                            style={{
                              borderRadius: 15,
                            }}
                            variant="contained"
                          >
                              Kyllä
                          </Button>
                          <Button
                            disableElevation
                            onClick={handleClose}
                            style={{
                              borderRadius: 15,
                            }}
                            variant="contained"
                          >
                              Peruuta
                          </Button>
                      </div>
                  </div>
              </Fade>
          </Modal>
      </div>
  );
};
