import { useState, useEffect } from "react";
import ResponsivityInfo from "../models/ResponsivityInfo";

// const mobileBreakpointWidth = 820;
const mobileBreakpointWidth = 768;
const largeTableBreakPointWidth = 1880;
const smallTableBreakPointWidth = 1420;

function getResponsivity() : ResponsivityInfo {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  const resp : ResponsivityInfo = {
    viewportWidth: vw,
    viewportHeight: vh,
    isMobile: false,
    breaksSmallTable: false,
    breaksLargeTable: false,
  };

  if (vw < mobileBreakpointWidth) { resp.isMobile = true; }
  if (vw < smallTableBreakPointWidth) { resp.breaksSmallTable = true; }
  if (vw < largeTableBreakPointWidth) { resp.breaksLargeTable = true; }

  return resp;
}

export default function useResponsivity() : ResponsivityInfo {
  const [responsivity, setResponsivity] = useState<ResponsivityInfo>({
    viewportWidth: 0,
    viewportHeight: 0,
    isMobile: false,
    breaksSmallTable: false,
    breaksLargeTable: false,
  });

  useEffect(() => {
    const onResize = () : void => {
      const r = getResponsivity();
      setResponsivity(r);
    };

    window.addEventListener("resize", onResize);
    onResize();

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return responsivity;
}
