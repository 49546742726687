import React from "react";
import { CircularProgress, Container } from "@mui/material";
import useStyles from "./PendingCircleStyles";

interface props {
  customStyles?: string;
  containerClasses?: string;
}
export default function ({ customStyles, containerClasses }: props): JSX.Element {
  const classes = useStyles();

  return (
      <Container className={containerClasses ? `${classes.container} ${containerClasses}` : classes.container}>
          <CircularProgress
            size={200}
            classes={{
              root: customStyles || classes.spinner,
            }}
          />
      </Container>
  );
}
