import { del, get, post, put } from "./HttpUtils";
import RoutingPlan, { RoutingPlanResponseWrapper } from "../models/RoutingPlan";

const api = process.env.REACT_APP_API_URL || "";

export const getRoutingPlans = async (): Promise<RoutingPlan[]> => {
  const content = await get(`${api}/service/routingplan`) as RoutingPlanResponseWrapper;
  return content.content;
};

export const getSendTypes = async (): Promise<Record<string, unknown>> => await get(`${api}/service/routingplan/sendingtypes`) as Record<string, unknown>;

export const getRoutingPlansOfOrganization = async (organizationId:string): Promise<RoutingPlan[]> => {
  const content = await get(`${api}/service/routingplan/byOrganization/${organizationId}`) as RoutingPlanResponseWrapper;
  return content.content;
};

export const getRoutingPlansPaged = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string, organization?: string): Promise<RoutingPlanResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";

  if (organization === undefined) {
    return await get(`${api}/service/routingplan/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}`) as RoutingPlanResponseWrapper;
  }

  return await get(`${api}/service/routingplan/byOrganization/${organization}/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}`) as RoutingPlanResponseWrapper;
};

export const getDefaultRoutingPlanForOrganization = async (): Promise<RoutingPlan> => await get(`${api}/service/routingplan/default`) as RoutingPlan;
export const saveNewRoutingPlan = async (plan: RoutingPlan): Promise<RoutingPlan> => await post(`${api}/service/routingplan`, plan) as RoutingPlan;
export const deleteRoutingPlan = async (plan: RoutingPlan): Promise<void> => del(`${api}/service/routingplan/${plan.id}`);
export const updateRoutingPlan = async (plan: RoutingPlan): Promise<RoutingPlan> => await put(`${api}/service/routingplan/${plan.id}`, plan) as RoutingPlan;
export const deleteRoutingPlans = async (plans: string[]): Promise<string[]> => await post(`${api}/service/routingplan/batchDelete`, plans) as string[];
