import { UserRole } from "./UserRole";

export type UserInfo = UserInfoForUpdating & {
    id?: string;
    authenticationUserId: string;
    adminInSuperAdminOrganization: boolean;
    adminInCustomerOrganization: boolean;
    organizationName: string;
}

export type UserResponseWrapper = {
    content: UserInfo[];

    first: boolean;
    last: boolean;
    totalElements: number;
}

export type UserInfoForUpdating = {
    firstName: string;
    lastName: string;
    email: string;
    mobilePhone: string;
    active: boolean;
    role: UserRole;
    organizationAccess: OrganizationAccess;
    _etag?: string;
}

export type UserInfoForCreation = UserInfoForUpdating & {
    password: string;
}

export type OrganizationAccess = {
    organizationId: string,
    role: OrgRole
}

export enum OrgRole {
    USER = "USER", ADMIN = "ADMIN"
}
