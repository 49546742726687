import { observable, action, makeObservable } from "mobx";
import BaseStore from "./BaseStore";

export default class UIStateStore extends BaseStore {
  mobileOpen = false;

  handleDrawerToggle = (): void => {
    this.mobileOpen = !this.mobileOpen;
  };

  constructor() {
    super();
    makeObservable(this, {
      mobileOpen: observable,
      handleDrawerToggle: action,
    });
  }
}
