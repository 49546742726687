import { format } from "date-fns";

export const displayableDate = (date: Date, displayFormat: "DATE" | "TIME" | "DATETIME", flavor: "NONE" | "SHORT" | "FULL" = "NONE"): string => {
    if (!date || date.toString().length === 0) { return "-"; }

    let flavored = "MM.dd.yyyy kk:mm";

    if (flavor === "SHORT") {
        flavored = "MM.dd.yyyy', klo' kk:mm";
    } else if (flavor === "FULL") {
        flavored = "MM.dd.yyyy', kello' kk:mm";
    }

    switch (displayFormat) {
        case "DATE":
            return format(date, "MM.dd.yyyy");
        case "TIME":
            return format(date, "kk:mm");
        case "DATETIME":
            return format(date, flavored);
        default:
            return format(date, flavored);
    }
}
