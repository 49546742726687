import { UserInfoForCreation, UserResponseWrapper, UserInfoForUpdating, UserInfo } from "../models/User";
import { del, get, post, put } from "./HttpUtils";

const api = process.env.REACT_APP_API_URL || "";

export const getUsers = async (): Promise<UserInfo[]> => {
  const content = await get(`${api}/service/user`) as UserResponseWrapper;
  return content.content;
};

export const getUsersPaged = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<UserResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";

  return await get(`${api}/service/user/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useSearch}`) as UserResponseWrapper;
};

export const getUserDetails = async (): Promise<UserInfo> => await get(`${api}/service/userdetails`) as UserInfo;

export const saveNewUser = async (user: UserInfoForCreation): Promise<UserInfo> => await post(`${api}/service/user`, user) as UserInfo;

export const deleteUser = async (user: UserInfo): Promise<void> => del(`${api}/service/user/${user.id}`);

export const editUser = async (user: UserInfoForUpdating, id: string): Promise<UserInfo> => await put(`${api}/service/user/${id}`, user) as UserInfo;

export const batchDeleteUsers = async (userIds: string[]): Promise<string[]> => await post(`${api}/service/user/batchDelete`, userIds) as string[];
