import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({

  container: {
    paddingTop: 20,
    minWidth: "95%",
  },
  headerContainer: {
    marginBottom: 50,
  },
  routeDisplay: {
    marginTop: 30,
    marginLeft: 30,
    display: "inline-flex",
  },
  deviceNameDisplay: {
    marginLeft: 40,
    marginTop: 30,
  },
  routeDisplayElementWrapper: {
    marginRight: 10,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",

  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,

  },

  gridContainer: {
    marginBottom: 50,
  },
  paper: {
    marginBottom: 5,
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    color: theme.palette.text.secondary,
    height: "100%",
    "& .MuiTypography-h4": {
      color: theme.palette.text.primary,
    },
    "& .MuiButton-root": {
      marginRight: 10,
      marginLeft: 10,
      borderRadius: 25,
      paddingLeft: 15,
      paddingRight: 15,
      width: "25%",
      fontSize: "75%",
    },
    "& .MuiCardContent-root": {
      width: "100%",
    },
  },
}));
