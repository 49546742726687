import React, { useRef, useState, useReducer } from "react";
import Modal from "@mui/material/Modal";
import { Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileCopyOutlined } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import useStyles from "./CreateUserModalStyles";
import { OrgRole, UserInfoForCreation } from "../../../models/User";
import { State, ActionType, Action, validateInput, onInputChange, generatePassword, PasswordLength } from "../FormUtils";
import { UserRole } from "../../../models/UserRole";
import { useRootStore } from "../../../RootStateContext";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (u: UserInfoForCreation) => void;
}

const initialValidationState = {
  firstName: {
    value: "", hasError: true, error: "",
  },
  email: {
    value: "", hasError: true, error: "",
  },
  password: {
    value: "", hasError: true, error: "",
  },
  lastName: {
    value: "", hasError: true, error: "",
  },
  role: {
    value: "", hasError: true, error: "",
  },
  organization: {
    value: "", hasError: true, error: "",
  },
  mobilePhone: {
    value: "", hasError: true, error: "",
  },
};

const reducer = (state: State, action: Action): State => {
  if (action.type === ActionType.UPDATE) {
    const { name, value, hasError, error } = action.data;
    return {
      ...state,
      [name]: {
        ...state[name], value, hasError, error,
      },
    };
  }
  if (action.type === ActionType.RESET) {
    return initialValidationState;
  }
  return state;
};

export default observer(({ open, handleClose, submit }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const password = useRef<HTMLInputElement>();
  const [validationState, dispatch] = useReducer(reducer, initialValidationState);
  const [copied, setCopied] = useState(false);
  const { organizations } = useRootStore().organizationStore;
  const [organizationId, setOrganizationId] = useState("");
  const [isOrganizationAdmin, setIsOrganizationAdmin] = useState(false);

  const isReadyToBeSaved = (): boolean => {
    let invalid = false;
    Object.keys(validationState).forEach((name) => {
      if (Object.prototype.hasOwnProperty.call(validationState, name)) {
        const item = validationState[name];
        if (item !== undefined) {
          const { value } = item;
          const { hasError, error } = validateInput(name, value);
          if (hasError) invalid = true;
          if (name) {
            dispatch({
              type: ActionType.UPDATE,
              data: {
                name,
                value,
                hasError,
                error,
              },
            });
          }
        }
      }
    });
    return !invalid;
  };

  const close = (): void => {
    dispatch({
      type: ActionType.RESET, data: {},
    });
    setOrganizationId("");
    setIsOrganizationAdmin(false);
    handleClose();
  };

  const copyPasswordToClipboard = (): void => {
    if (password?.current?.value !== "") {
      navigator.clipboard.writeText(password?.current?.value || "");
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const saveUser = (): void => {
    if (isReadyToBeSaved()) {
      const user: UserInfoForCreation = {
        password: validationState.password.value,
        firstName: validationState.firstName.value,
        lastName: validationState.lastName.value,
        email: validationState.email.value,
        mobilePhone: validationState.mobilePhone.value,
        active: true,
        role: UserRole[validationState.role.value as UserRole] || UserRole.IOT_ADMIN,
        organizationAccess: {
          organizationId, role: isOrganizationAdmin ? OrgRole.ADMIN : OrgRole.USER,
        },
      };
      submit(user);
      close();
    } else {
      console.log(JSON.stringify(validationState));
      console.log("error");
    }
  };

  const autoGeneratePassword = (): void => {
    const pwd = generatePassword(PasswordLength.MEDIUM);
    if (password && password.current) password.current.value = pwd;
    onInputChange("password", pwd, dispatch);
  };

  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      onInputChange("organization", event.target.value as string, dispatch);
      setOrganizationId(event.target.value as string);
    }
  };

  return (
      <>
          <Modal
            className={classes.modal}
            open={open}
            onClose={close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id="addUserTitle">{t("usersView.addUser")}</Typography>
                      </div>

                      <div className={classes.subsection}>
                          <TextField
                            id="firstNameInput"
                            label={t("usersView.firstName")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={validationState?.firstName?.error !== ""}
                            helperText={validationState?.firstName?.error}
                            onChange={(e) => onInputChange("firstName", e.target.value, dispatch)}
                            variant="outlined"
                            className={classes.firstName}
                          />
                          <div className={classes.divider} />
                          <TextField
                            className={classes.lastName}
                            id="lastNameInput"
                            error={validationState?.lastName?.error !== ""}
                            helperText={validationState?.lastName?.error}
                            onChange={(e) => onInputChange("lastName", e.target.value, dispatch)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t("usersView.lastName")}
                            variant="outlined"
                          />
                      </div>
                      <div className={classes.subsection}>
                          <TextField
                            id="emailInput"
                            fullWidth
                            error={validationState?.email?.error !== ""}
                            helperText={validationState?.email?.error}
                            onChange={(e) => onInputChange("email", e.target.value, dispatch)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t("usersView.email")}
                            variant="outlined"
                          />
                      </div>
                      <div className={classes.subsection}>
                          <TextField
                            id="mobilePhoneInput"
                            fullWidth
                            error={validationState?.mobilePhone?.error !== ""}
                            helperText={validationState?.mobilePhone?.error}
                            onChange={(e) => onInputChange("mobilePhone", e.target.value, dispatch)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t("usersView.mobilePhone")}
                            variant="outlined"
                          />
                      </div>
                      <div className={classes.section}>
                          <div className={classes.subsection}>
                              <TextField
                                id="passwordInput"
                                fullWidth
                                error={validationState?.password?.error !== ""}
                                helperText={copied ? t("usersView.passwordCopied") : validationState?.password?.error}
                                onChange={(e) => onInputChange("password", e.target.value, dispatch)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label={t("usersView.password")}
                                variant="outlined"
                                inputRef={password}
                                InputProps={{
                                  endAdornment: <FileCopyOutlined className={classes.copyIcon} onClick={copyPasswordToClipboard} />,
                                }}
                              />
                              <Button
                                className={classes.generatePasswordButton}
                                variant="contained"
                                color="primary"
                                onClick={autoGeneratePassword}
                              >
                                  {t("usersView.autoGeneratePassword")}
                              </Button>

                          </div>
                          {organizations
                          && (
                              <div className={classes.subsection}>
                                  <FormControl className={classes.formControl}>
                                      <InputLabel id="organizationSelectLabel">{t("usersView.organization")}</InputLabel>
                                      <Select
                                        error={validationState?.organization?.error !== ""}
                                        labelId="orgLabel"
                                        id="orgLabelId"
                                        value={organizationId}
                                        onChange={handleOrganizationDropdownChange}
                                      >
                                          {organizations.map((org) => (
                                              <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{t("usersView.organizationHelperText")}</FormHelperText>
                                  </FormControl>
                                  <FormControlLabel
                                    control={(
                                        <Checkbox
                                          id="isUserAdmin"
                                          checked={isOrganizationAdmin}
                                          onChange={() => { setIsOrganizationAdmin((a) => !a); }}
                                          name={t("usersView.admin")}
                                          color="primary"
                                        />
                                    )}
                                    label={t("usersView.admin")}
                                  />
                              </div>
                          )}
                          {/* HIDDEN FOR NOW
                          <div className={classes.subsection}>
                              <FormControl className={classes.formControl}>
                                  <InputLabel id="userRoleLabel">{t("usersView.role")}</InputLabel>
                                  <Select
                                    labelId="roleLabel"
                                    id="roleLabelId"
                                    onChange={handleRoleDropDownChange}
                                  >
                                      {Object.keys(UserRole).map((role) => (
                                          <MenuItem value={role}>{role.toString()}</MenuItem>
                                      ))}
                                  </Select>
                                  <FormHelperText>{t("usersView.roleHelperText")}</FormHelperText>
                              </FormControl>
                                      </div> */}
                      </div>
                      <div className={classes.navigation}>

                          <div className={classes.navButtons}>
                              <Button
                                variant="contained"
                                id="closeCreateUserModal"
                                className={classes.backButton}
                                color="secondary"
                                onClick={close}
                              >
                                  {t("usersView.cancel")}
                              </Button>
                              <Button
                                variant="contained"
                                id="createUserButton"
                                className={classes.nextButton}
                                color="primary"
                                onClick={saveUser}
                              >
                                  {t("usersView.saveUser")}
                              </Button>
                          </div>

                      </div>
                  </div>
              </Fade>
          </Modal>
      </>
  );
});
