import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import RoutingPlan from "../../../models/RoutingPlan";
import { useRootStore } from "../../../RootStateContext";
import RoutingView from "../RoutingView";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import PendingCircle from "../../../components/loadingCircle/loadingCircle";
import Organization, { OrganizationType } from "../../../models/Organization";

export default observer((): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);
  const store = useRootStore().routingPlanStore;
  const { t } = useTranslation();
  const { routingPlansPaged, totalRoutingPlansPaged } = store;

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");

  const organizationsStore = useRootStore().organizationStore;
  const { allOrganizations } = organizationsStore;

  const [organizationFilter, setOrganizationFilter] = useState<Organization | undefined>(undefined);

  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  const saveNewRoutingPlan = async (routingPlan: RoutingPlan): Promise<void> => {
    await store.saveNewRoutingPlan(routingPlan);
    await store.loadRoutingPlansPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, organizationFilter?.id || undefined)
    if (store.error === undefined) {
      toast.info(t("routingView.routingPlanCreated"), {
        toastId: "RoutingPlanCreatedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const updateRoutingPlan = async (routingPlan: RoutingPlan): Promise<void> => {
    await store.updateRoutingPlan(routingPlan);
    await store.loadRoutingPlansPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, organizationFilter?.id || undefined)
    if (store.error === undefined) {
      toast.info(t("routingView.routingPlanUpdated"), {
        toastId: "RoutingPlanUpdatedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const deleteRoutingPlan = async (routingPlan: RoutingPlan): Promise<void> => {
    await store.deleteRoutingPlan(routingPlan);
    await store.loadRoutingPlansPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, organizationFilter?.id || undefined)

    if (store.error === undefined) {
      toast.info(t("routingView.routingPlanDeleted"), {
        toastId: "RoutingPlanDeletedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const deleteAllSelected = async (): Promise<void> => {
    await store.deleteRoutingPlans(store.selectedRoutingPlans);
    if (store.error === undefined) {
      toast.info(t("routingView.deleteSuccess"), {
        toastId: "RoutingPlanDeletedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    (async () => {
      await store.loadRoutingPlansPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, organizationFilter?.id || undefined);
    })();

    return function cleanup() {
      store.clearState();
    };
  }, [page, pageSize, sortBy, sortDirection, organizationFilter, store]);

  if (forbidden) {
    return <UnauthorizedComponent />;
  }
  if (routingPlansPaged === null || routingPlansPaged === undefined) {
    return (
        <PendingCircle />
    );
  }

  return (
      <RoutingView
        saveNewRoutingPlan={saveNewRoutingPlan}
        updateRoutingPlan={updateRoutingPlan}
        deleteRoutingPlan={deleteRoutingPlan}
        deleteAllSelected={deleteAllSelected}
        routingPlans={routingPlansPaged}
        totalRoutingPlans={totalRoutingPlansPaged}
        organizationsFilterOptions={allOrganizations && [...[{
          name: t("deviceView.allOrganizations"),
          id: undefined,
          organizationType: "" as OrganizationType,
          active: true,
          accountId: "",
        }, ...allOrganizations]]}
        organizationFilter={[organizationFilter, setOrganizationFilter]}
        sort={sort}
        setSortDirection={setSortDirection}
        page={page}
        rowsPerPage={pageSize}
        setPage={handlePageChange}
        setPageSize={handlePageSizeChange}
      />
  );
});
