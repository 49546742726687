import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react-lite";

import OrganizationsAndUsersRootView from "../organizationsAndUsers/adminOrganizationsAndUsersRootView/OrganizationsAndUsersRootView";

import UsersRootView from "../users/usersRootView/UsersRootView";
import { useRootStore } from "../../../RootStateContext";
import PendingCircle from "../../../components/loadingCircle/loadingCircle";

export default observer((): JSX.Element => {
  const authStore = useRootStore().authenticationStore;
  const { currentUser } = authStore;

  useEffect(() => {
    let mounted = true;
    authStore.fetchUserDetails()
      .catch(() => {
        if (mounted) {
          // TODO check error response for forbidden or unauthenticated etc.
        }
      });

    return function cleanup() {
      mounted = false;
      // authStore.clearState();
    };
  }, [authStore]);

  if (currentUser === null || currentUser === undefined) {
    return (
        <PendingCircle />
    );
  }

  if (currentUser.adminInSuperAdminOrganization) {
    return (
        <OrganizationsAndUsersRootView />
    );
  }
  if (currentUser.adminInCustomerOrganization) {
    return (
        <UsersRootView />
    );
  }
  return (
      <></>
  );
});
