import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Button, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useStyles from "./RoutingViewStyles";
import PageTitle from "../../components/pageTitle/PageTitle";
import RoutingTabs from "./routingViewComponents/RoutingTabs";
import NewRoutingPlanModal from "../../components/newRoutingPlanModal/NewRoutingPlanModal";
import RoutingPlan from "../../models/RoutingPlan";
import RouteDisplay from "../../components/routeDisplay/RouteDisplay";
import { useRootStore } from "../../RootStateContext";
import ConfirmationModal from "../../components/deleteDeviceModal/ConfirmationModal";
import theme from "../../materialUITheme";
import FilterSelection from "../../components/filterSelection/FilterSelection";
import Organization from "../../models/Organization";

interface IRoutingViewProps {
  saveNewRoutingPlan: (routingPlan: RoutingPlan) => Promise<void>;
  deleteRoutingPlan: (routingPlan: RoutingPlan) => Promise<void>;
  updateRoutingPlan: (routingPlan: RoutingPlan) => Promise<void>;

  organizationsFilterOptions?: Organization[];
  organizationFilter: [(Organization | undefined), (o: Organization | undefined) => void ];
  routingPlans: RoutingPlan[];
  totalRoutingPlans: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  deleteAllSelected: () => void;
}
export default observer(({ saveNewRoutingPlan,
  deleteRoutingPlan,
  updateRoutingPlan,
  organizationsFilterOptions,
  organizationFilter,
  routingPlans,
  totalRoutingPlans,
  setPage,
  setPageSize,
  page,
  rowsPerPage,
  sort,
  setSortDirection,
  deleteAllSelected }: IRoutingViewProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const store = useRootStore().routingPlanStore;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
      <>
          <RouteDisplay currentLink="/routing" currentName={t("navigation.routing")} />
          <NewRoutingPlanModal open={modalOpen} handleClose={() => setModalOpen(false)} submit={saveNewRoutingPlan} />
          <ConfirmationModal
            open={deleteModalOpen}
            text={t("routingView.deleteConfirmation", {
              ids: store.selectedRoutingPlans.map((e) => `${e.name}`).join(", "),
            })}
            handleClose={() => setDeleteModalOpen(false)}
            submit={deleteAllSelected}
          />
          <Container className={classes.container}>
              <PageTitle title="Datan reititys" />

              <Grid container>
                  <Grid container item xs={6}>
                      <FilterSelection<Organization> filters={organizationsFilterOptions || []} setFilter={organizationFilter[1]} currentFilter={organizationFilter[0]} filterHelperText={t("deviceView.organizationFilter")} />
                  </Grid>
                  <Grid container item xs={6} justifyContent="flex-end">
                      {store.selectedRoutingPlans.length > 0 && (
                          <Button
                            style={{
                              backgroundColor: theme.palette.error.dark,
                              marginRight: 25,
                              color: "white",
                            }}
                            variant="contained"
                            id="deleteSelectedRoutingPlans"
                            onClick={() => setDeleteModalOpen(true)}
                          >
                              {t("routingView.deleteSelectedRoutingPlans")}
                          </Button>
                      )}
                      <Button
                        onClick={() => setModalOpen(true)}
                        color="primary"
                        variant="contained"
                        id="AddNewRoutingPlanButton"
                      >
                          {t("routingView.addNewRoutingPlan")}
                      </Button>

                  </Grid>
              </Grid>

              <RoutingTabs
                routingPlans={routingPlans}
                updateRoutingPlan={updateRoutingPlan}
                deleteRoutingPlan={deleteRoutingPlan}
                totalRoutingPlans={totalRoutingPlans}
                setPage={setPage}
                setPageSize={setPageSize}
                page={page}
                rowsPerPage={rowsPerPage}
                sort={sort}
                setSortDirection={setSortDirection}
              />
          </Container>
      </>
  );
});
