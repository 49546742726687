import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { massChangeDecoding, massChangeTags, massChangeRoutings } from "../../../api/MassEditApi";
import PendingCircle from "../../../components/loadingCircle/loadingCircle";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import Device from "../../../models/Device";
import MassChangePayload from "../../../models/MassChange";
import Organization, { OrganizationType } from "../../../models/Organization";
import RoutingPlan from "../../../models/RoutingPlan";
import Tag from "../../../models/Tag";
import { UserInfo } from "../../../models/User";
import { useRootStore } from "../../../RootStateContext";
import DevicesView from "../DevicesView";

interface IDevicesRootViewProps {
  currentUser: UserInfo;
}

export default observer(({ currentUser }: IDevicesRootViewProps): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);
  const devicesStore = useRootStore().deviceStore;
  const { devices } = devicesStore;
  const { totalDevices } = devicesStore;
  const { t } = useTranslation();
  const organizationsStore = useRootStore().organizationStore;
  const { allOrganizations } = organizationsStore;
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [organizationFilter, setOrganizationFilter] = useState<Organization | undefined>(undefined);
  const [searchValue, setSearchValue] = useState("");

  const resetAllOptions = (): void => {
    setArchived(false);
    setSortBy("");
    setSortDirection("desc");
    setOrganizationFilter(undefined);
    setSearchValue("");
  };

  const archive = (b: boolean): void => {
    setArchived(b);
  };
  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  const batchArchive = async (devices: Device[]): Promise<void> => {
    await devicesStore.batchArchiveDevices(devices);

    if (devicesStore.error === undefined) {
      toast.info(t("deviceView.deviceDeleted"), {
        toastId: "DeviceDeletedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const saveNewDevice = async (device: Device): Promise<void> => {
    await devicesStore.saveNewDevice(device);
    await devicesStore.loadDevicesPaged(page, pageSize, sortBy, sortDirection, archived);
    if (devicesStore.error === undefined) {
      toast.info(t("deviceView.deviceCreated"), {
        toastId: "DeviceCreatedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const saveDecoding = async (selectedDevices: Device[], decoding: string): Promise<void> => {
    try {
      const payload : MassChangePayload = {
        organizationId: selectedDevices[0].organizationId!,
        devices: selectedDevices.map((d) => d.id!),
        massChange: {
          ids: [decoding],
          massChangeType: "DECODING",
        },
      }

      await massChangeDecoding(payload);
      await devicesStore.loadDevicesPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived, organizationFilter && organizationFilter.id, searchValue)
    } catch (e) {
      console.error(e);
      toast.error(t("massEditModal.operationFail"), {
        toastId: "massEditModalOperationSuccess",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }

    toast.info(t("massEditModal.operationSuccess"), {
      toastId: "massEditModalOperationSuccess",
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
    });
  };

  const saveTags = async (selectedDevices: Device[], selectedTags: string[]): Promise<void> => {
    try {
      const payload : MassChangePayload = {
        organizationId: selectedDevices[0].organizationId!,
        devices: selectedDevices.map((d) => d.id!),
        massChange: {
          ids: selectedTags,
          massChangeType: "TAG",
        },
      }

      await massChangeTags(payload);
      await devicesStore.loadDevicesPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived, organizationFilter && organizationFilter.id, searchValue)
    } catch (e) {
      console.error(e);
      toast.error(t("massEditModal.operationFail"), {
        toastId: "massEditModalOperationSuccess",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }

    toast.info(t("massEditModal.operationSuccess"), {
      toastId: "massEditModalOperationSuccess",
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
    });
  }

  const saveRoutings = async (selectedDevices: Device[], selectedRoutings: string[]): Promise<void> => {
    try {
      const payload : MassChangePayload = {
        organizationId: selectedDevices[0].organizationId!,
        devices: selectedDevices.map((d) => d.id!),
        massChange: {
          ids: selectedRoutings,
          massChangeType: "ROUTING_PLAN",
        },
      }

      await massChangeRoutings(payload);
      await devicesStore.loadDevicesPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived, organizationFilter && organizationFilter.id, searchValue)
    } catch (e) {
      console.error(e);
      toast.error(t("massEditModal.operationFail"), {
        toastId: "massEditModalOperationSuccess",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }

    toast.info(t("massEditModal.operationSuccess"), {
      toastId: "massEditModalOperationSuccess",
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
    });
  };

  useEffect(() => {
    let mounted = true;
    (async () => {
      await devicesStore.loadDevicesPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived, organizationFilter && organizationFilter.id, searchValue)
        .catch(() => {
          if (mounted) {
          // TODO check error response for forbidden or unauthenticated etc.
            setForbidden(true);
          }
        });
      await devicesStore.loadConnectivityProducts("-1").catch(() => {
        if (mounted) {
        // TODO check error response for forbidden or unauthenticated etc.
          setForbidden(true);
        }
      });
    })();
    return function cleanup() {
      mounted = false;
      devicesStore.clearState();
    };
  }, [page, pageSize, sortBy, sortDirection, archived, organizationFilter, searchValue, devicesStore]);

  useEffect(() => {
    (async () => {
      await organizationsStore.loadAllOrganizations()
        .catch(() => {
          // TODO check error response for forbidden or unauthenticated etc.
          setForbidden(true);
        });
      /* await organizationsStore.loadTags()
        .catch(() => {
          // TODO check error response for forbidden or unauthenticated etc.
          setForbidden(true);
        }); */
    })();
  }, [organizationsStore]);

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  if ((devices === null || devices === undefined || allOrganizations === null || allOrganizations === undefined)) {
    return (
        <PendingCircle />
    );
  }

  return (
      <DevicesView
        sort={sort}
        setSortDirection={setSortDirection}
        page={page}
        rowsPerPage={pageSize}
        setPage={handlePageChange}
        totalDevices={totalDevices}
        setPageSize={handlePageSizeChange}
        deleteDevices={batchArchive}
        saveNewDevice={saveNewDevice}
        devices={devices}
        currentUser={currentUser}
        organizationsFilterOptions={allOrganizations && [...[{
          name: t("deviceView.allOrganizations"),
          id: undefined,
          organizationType: "" as OrganizationType,
          active: true,
          accountId: "",
        }, ...allOrganizations]]}
        organizations={allOrganizations}
        setArchived={archive}
        organizationFilter={[organizationFilter, setOrganizationFilter]}
        search={setSearchValue}
        resetAllOptions={resetAllOptions}
        submitDecoding={saveDecoding}
        submitRoutings={saveRoutings}
        submitTags={saveTags}
      />
  );
});
