import { Box, Card, CardContent, Container, Fade, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Device from "../../models/Device";
import useStyles from "./SingleDeviceViewStyles";
import DeviceAlerts from "./subcomponents/deviceAlerts/DeviceAlerts";
import DeviceFetch from "./subcomponents/deviceDataFetch/DeviceDataFetch";
import DeviceInfo from "./subcomponents/deviceInfo/DeviceInfo";
import DeviceTechicalInfo from "./subcomponents/deviceTechnicalInfo/DeviceTechicalInfo";
import RouteDisplay from "../../components/routeDisplay/RouteDisplay";
import RawDataUpDownLinkStatus from "../../models/RawDataUpDownLinkStatus";
import Alert from "../../models/Alert";

interface ISingleDeviceViewProps {
  device: Device;
  latestRawDataUpDownLinkStatus?: RawDataUpDownLinkStatus;
  updateDevice: (device: Device) => void;
  activeAlerts?: Alert[];
  requestDeviceUpdate: (device: Device) => void;
}

export default ({ device, latestRawDataUpDownLinkStatus, updateDevice, activeAlerts, requestDeviceUpdate }: ISingleDeviceViewProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentPageName = device.logicalName ? device.logicalName : "";
  const currentPageLink = device.id ? device.id : "";

  return (
      <Fade in>
          <Box>
              <RouteDisplay parentLink="/devices" parentName={t("deviceView.devices")} currentName={currentPageName} currentLink={currentPageLink} />
              <Box className={classes.deviceNameDisplay}>
                  <Typography className={classes.headerContainer} variant="h6" component="h2" id="DevicePartitionKey">
                      {device.logicalName}
                      {" "}
                      {device.archived ? `(${t("deviceView.archived")})` : ""}
                      {" "}
                  </Typography>
              </Box>
              <Container className={classes.container}>
                  <Grid container spacing={3}>
                      <Grid item className={classes.gridContainer} xs={12} md={5}>
                          <Card className={classes.paper}>
                              <CardContent>
                                  <DeviceFetch device={device} latestRawDataUpDownLinkStatus={latestRawDataUpDownLinkStatus} />
                              </CardContent>
                          </Card>
                      </Grid>
                      <Grid item className={classes.gridContainer} xs={12} md={7}>
                          <Card className={classes.paper}>
                              <CardContent>
                                  <DeviceAlerts activeAlerts={activeAlerts} device={device} />
                              </CardContent>
                          </Card>
                      </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                      <Grid item className={classes.gridContainer} xs={12} md={4}>
                          <Card className={classes.paper}>
                              <CardContent>
                                  <DeviceInfo device={device} update={updateDevice} requestDeviceUpdate={requestDeviceUpdate} />
                              </CardContent>
                          </Card>
                      </Grid>
                      <Grid item className={classes.gridContainer} xs={12} md={8}>
                          <Card className={classes.paper}>
                              <CardContent>
                                  <DeviceTechicalInfo update={updateDevice} device={device} />
                              </CardContent>
                          </Card>
                      </Grid>
                  </Grid>
              </Container>
          </Box>
      </Fade>

  );
};
