import React, { useMemo } from "react";
import EventStore from "./stores/EventStore";
import AuthenticationStore from "./stores/AuthenticationStore";
import DeviceStore from "./stores/DeviceStore";
import UIStateStore from "./stores/UIStateStore";
import RoutingPlanStore from "./stores/RoutingPlanStore";
import OrganizationStore from "./stores/OrganizationStore";
import UserStore from "./stores/UserStore";
import RawDataStore from "./stores/RawDataStore";
import AlertStore from "./stores/AlertStore";

export interface RootStateContextValue {
    alertStore: AlertStore,
    deviceStore: DeviceStore,
    uiStateStore: UIStateStore
    eventStore: EventStore
    routingPlanStore: RoutingPlanStore,
    authenticationStore: AuthenticationStore,
    organizationStore: OrganizationStore,
    userStore: UserStore,
    rawDataStore: RawDataStore,
}

const RootStateContext = React.createContext<RootStateContextValue>({} as RootStateContextValue);

const alertStore = new AlertStore();
const deviceStore = new DeviceStore();
const uiStateStore = new UIStateStore();
const eventStore = new EventStore();
const authenticationStore = new AuthenticationStore();
const routingPlanStore = new RoutingPlanStore();
const organizationStore = new OrganizationStore();
const userStore = new UserStore();
const rawDataStore = new RawDataStore();

export const RootStateProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const rootStateContextValue = useMemo(() => ({
    alertStore, deviceStore, eventStore, uiStateStore, authenticationStore, routingPlanStore, organizationStore, userStore, rawDataStore,
  }), []);

  return (
      <RootStateContext.Provider value={rootStateContextValue}>
          {children}
      </RootStateContext.Provider>
  );
};

export const useRootStore = (): RootStateContextValue => React.useContext(RootStateContext);
