import { action, makeObservable, observable, runInAction } from "mobx";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import { Organization, OrganizationType } from "../models/Organization";
import { getOrganizationsPaged, getOrganizationTags, saveNewOrganization, editOrganization, deleteOrganization } from "../api/OrganizationApi";
import Tag from "../models/Tag";

export default class OrganizationStore extends BaseStore {
  tags?: Tag[] = undefined

  organizations?: Organization[] = undefined;

  allOrganizations?: Organization[] = undefined;

  selectedOrganization?: Organization = undefined;

  selectedOrganizations: Organization[] = [];

  totalOrganizations = 0;

  serviceLevels = ["OMAVESI_BRONZE", "OMAVESI_SILVER", "OMAVESI_GOLD"];

  OrganizationTypes : OrganizationType[] = [
    "ADMIN", "RETAILER", "WATER_COMPANY",
  ];

    loadOrganizationsPaged = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<void> => {
      try {
        const orgsResp = await getOrganizationsPaged(page, pageSize, sortBy, sortDirection, search);
        runInAction(() => {
          this.organizations = orgsResp.content;
          this.totalOrganizations = orgsResp.totalElements;
        });
      } catch (e) {
        this.setError(i18n.t("organizationStore.organizationsFetchError"));
        this.organizations = [];
        console.error(e.stack);
      }
    }

    loadAllOrganizations = async (sortBy?: string, sortDirection?: string, search?: string): Promise<void> => {
      try {
        const orgsResp = await getOrganizationsPaged(0, 1000, sortBy, sortDirection, search);

        runInAction(() => {
          this.allOrganizations = orgsResp.content;
        });
      } catch (e) {
        this.setError(i18n.t("deviceStore.devicesFetchError"));
        this.allOrganizations = [];
        console.error(e.stack);
      }
    }

    loadTags = async (): Promise<void> => {
      try {
        const t = await getOrganizationTags();
        runInAction(() => {
          this.tags = t;
        });
      } catch (e) {
        this.setError(i18n.t("organizationStore.tagsFetchError"));
        this.tags = [];
        console.error(e.stack);
      }
    }

    saveNewOrganization = async (orgData: Organization): Promise<any> => {
      try {
        const p = await saveNewOrganization(orgData);
        runInAction(() => {
          this.organizations?.push(p);
        });
        return p;
      } catch (e) {
        console.log(e.status);
        this.setError(i18n.t("organizationStore.orgCreateError"));
        console.error(e.stack);
        console.log(JSON.stringify(e));
        return Promise.reject(e.stack);
      }
    }

    deleteOrganization = async (orgId: string): Promise<void> => {
      try {
        await deleteOrganization(orgId);
        runInAction(() => {
          this.organizations = this.organizations?.filter((e) => e.id !== orgId);
          this.allOrganizations = this.allOrganizations?.filter((e) => e.id !== orgId);
        });
      } catch (e) {
        this.setError(i18n.t("organizationStore.orgDeletionError"));
        console.error(e.stack);
        console.log(JSON.stringify(e));
      }
    }

    updateOrganization = async (org: Organization, id: string): Promise<Organization> => {
      try {
        const o = await editOrganization(org, id);
        runInAction(() => {
          const orgInOrgs = this.organizations?.find((e) => e.id === id);
          if (orgInOrgs) {
            this.organizations?.splice(this.organizations?.indexOf(orgInOrgs), 1, o);
            this.allOrganizations?.splice(this.allOrganizations?.indexOf(orgInOrgs), 1, o);
          }
        });

        return o;
      } catch (e) {
        this.setError(i18n.t("organizationStore.orgUpdatingError"));
        console.error(e.stack);
        console.log(JSON.stringify(e));
        return Promise.reject(e.stack);
      }
    }

    toggleOrganizationSelection = (o: Organization): void => {
      if (this.selectedOrganizations.includes(o)) { this.selectedOrganizations = this.selectedOrganizations.filter((e) => e !== o); } else { this.selectedOrganizations = [...this.selectedOrganizations, o]; }
    }

    setSelectedOrganization = (o: Organization | undefined): void => {
      this.selectedOrganization = o;
    }

    clearState = () : void => {
      this.organizations = [];
      this.allOrganizations = [];
    }

    constructor() {
      super();
      makeObservable(this, {
        allOrganizations: observable,
        organizations: observable,
        serviceLevels: observable,
        tags: observable,
        loadOrganizationsPaged: action,
        loadTags: action,
        clearState: action,
      });
    }
}
