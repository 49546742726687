import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  container: {
    width: "100%",
  },
  noFiltersText: {
    fontSize: "1em",
    color: theme.palette.secondary.main,
    marginBottom: "7px",
  },
  input: {
    minWidth: "15vw",
  },
}));
