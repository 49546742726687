import React from "react";
import { Container, Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import useStyles from "./UsersViewStyles";

import Table, { Column } from "../../../components/table/TableWithPagination";
import { UserInfo } from "../../../models/User";
import PageTitle from "../../../components/pageTitle/PageTitle";
import PendingCircle from "../../../components/loadingCircle/loadingCircle";
import RouteDisplay from "../../../components/routeDisplay/RouteDisplay";
import { useRootStore } from "../../../RootStateContext";

interface IUsersViewProps {
  users: UserInfo[];
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalUsers: number;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
}

export default observer(({ users, setPage, setPageSize, setSortDirection, page, sort, rowsPerPage }: IUsersViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { totalUsers } = useRootStore().userStore;

  const userColumns: Column[] = [
    {
      id: "userName", label: "Käyttäjänimi", minWidth: 100,
    },
    {
      id: "firstName", label: "Etunimi", minWidth: 170,
    },
    {
      id: "lastName", label: "Sukunimi", minWidth: 170,
    },
    {
      id: "email", label: "Sähköposti", minWidth: 170,
    },
    {
      id: "active", label: "Aktiivinen", minWidth: 170, booleanType: true,
    },
  ];

  const classes = useStyles();
  if (users === null || users === undefined || users.length === 0) {
    return (
        <PendingCircle />
    );
  }
  return (
      <>
          <RouteDisplay currentLink="/users" currentName={t("navigation.users")} />

          <Container className={classes.container}>
              <PageTitle title="Käyttäjähallinta" />
              <Typography>Organisaatiosi käyttäjät</Typography>
              <Table<UserInfo>
                count={totalUsers}
                handlePageChange={setPage}
                handlePageSizeChange={setPageSize}
                currentPage={page}
                currentRowsPerPage={rowsPerPage}
                sort={sort}
                setSortDirection={setSortDirection}
                columns={userColumns}
                rows={users}
              />
          </Container>
      </>
  );
});
