import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  Checkbox,
  SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./NewDeviceModalStyles";
import { useRootStore } from "../../RootStateContext";
import Device from "../../models/Device";
import { UserInfo } from "../../models/User";
import Organization from "../../models/Organization";
import Tag from "../../models/Tag";
import ConnectivityProduct from "../../models/ConnectivityProduct";
import { DeviceModel, DeviceProfile } from "../../models/DeviceModel";
import { getDevicePhysicalIdExists } from "../../api/DeviceApi";
import { getOrganizationTagsById } from "../../api/OrganizationApi";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (d: Device) => void;
    currentUser: UserInfo;
    organizations: Organization[];
}

export default ({ open, handleClose, submit, currentUser, organizations }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [t("deviceCreation.device"), t("deviceCreation.deviceModel"), t("deviceCreation.connectivityProduct"), t("deviceCreation.routingPlan")];
  const [tags, setTags] = useState<Tag[]>([]);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);

  const { routingPlanStore } = useRootStore();
  const { routingPlans } = routingPlanStore;

  const { deviceStore } = useRootStore();
  const { connectivityProducts } = deviceStore;
  const { deviceModels } = deviceStore;

  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);

  const logicalName = useRef<HTMLInputElement>();
  const physicalId = useRef<HTMLInputElement>();
  const accountId = useRef<HTMLInputElement>();
  const deviceModelSelectRef = useRef<HTMLInputElement>();
  const deviceProfileSelectRef = useRef<HTMLInputElement>();
  const connectivityProductSelectRef = useRef<HTMLInputElement>();

  // save to state only when step changes (component unmounts and ref is lost)
  const [organizationIdState, setOrganizationId] = useState("");
  const [connectivityProductState, setConnectivityProductState] = useState<ConnectivityProduct | undefined>(undefined);
  const [deviceModelState, setDeviceModelState] = useState<DeviceModel | undefined>(undefined);
  const [deviceProfileState, setDeviceProfileState] = useState<DeviceProfile | undefined>(undefined);
  const [logicalNameState, setLogicalNameState] = useState("");
  const [physicalIdState, setPhysicalIdState] = useState("");
  const [accountIdState, setAccountIdState] = useState("");
  const [physicalIdErrorState, setPhysicalIdErrorState] = useState<boolean>(false);
  const [physicalIdErrorText, setPhysicalIdErrorText] = useState<string>("");
  const [physicalIdValid, setPhysicalIdValid] = useState<boolean>(false);
  const [logicalNameValid, setLogicalNameValid] = useState<boolean>(false);
  const [isLoadingNextData, setIsLoadingNextData] = useState<boolean>(false);

  const clear = (): void => {
    setActiveStep(0);
    setTags([]);
    setConnectivityProductState(undefined);
    setSelectedRows([]);
    setLogicalNameState("");
    setPhysicalIdState("");
    setAccountIdState("");
    setDeviceModelState(undefined);
    setDeviceProfileState(undefined);
    setOrganizationId("");
    setPhysicalIdErrorState(false);
    setPhysicalIdErrorText("");
    setAvailableTags([]);
    setPhysicalIdValid(false);
    setLogicalNameValid(false);
    handleClose();
  };

  const selectTag = (tt: Tag): void => {
    if (tags.includes(tt)) setTags((prevState) => prevState.filter((e) => e !== tt));
    else setTags((prevState) => [...prevState, tt]);
  };

  const handleNext = async (): Promise<void> => {
    if (isLoadingNextData) { return; }
    if (activeStep === 0) {
      setIsLoadingNextData(true);
      setLogicalNameState(logicalName.current?.value || "");
      setPhysicalIdState(physicalId.current?.value || "");
      setAccountIdState(accountId.current?.value || "");
      await deviceStore.loadDeviceModels();
      setIsLoadingNextData(false);
    }
    if (activeStep === 1) {
      setIsLoadingNextData(true);
      await deviceStore.loadConnectivityProducts(organizationIdState);
      setIsLoadingNextData(false);
    }
    if (activeStep === 2) {
      setIsLoadingNextData(true);
      if (organizationIdState) {
        await routingPlanStore.loadRoutingPlansOfOrganization(organizationIdState);
      } else {
        await routingPlanStore.loadRoutingPlans();
      }
      setIsLoadingNextData(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      (async () => {
        const tags = await getOrganizationTagsById(event.target.value as string);
        setAvailableTags(tags);
        setTags([]);
      })();
      setOrganizationId(event.target.value as string);
    }
  };

  const handleConnectivityProductChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setConnectivityProductState(connectivityProducts.find((e) => e.id === event.target.value));
    }
  };

  const handleDeviceModelChange = (event: SelectChangeEvent<string>): void => {
    setDeviceProfileState(undefined);
    if (event.target.value) {
      setDeviceModelState(deviceModels.find((e) => e.id === event.target.value));
    }
  };

  const handleDeviceProfileChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setDeviceProfileState(deviceModelState?.profiles.find((e) => e.id === event.target.value));
    }
  };

  const handlePhysicalOnBlur = (event: React.FocusEvent<{ value: string }>): void => {
    setPhysicalIdErrorState(false);
    setPhysicalIdErrorText("");

    if (event.target.value) {
      (async () => {
        const idExists = await getDevicePhysicalIdExists(event.target.value);
        if (idExists) {
          setPhysicalIdErrorState(true);
          setPhysicalIdErrorText(t("deviceCreation.physicalIdExists"));
        }
      })();
    }

    setPhysicalIdValid(physicalId.current?.value === "");
  };

  const handleLogicalOnBlur = (): void => {
    setLogicalNameValid(logicalName.current?.value === "");
  };

  const isNextButtonDisabled = (): boolean => {
    if (activeStep === 0) {
      return (tags.length === 0 || organizationIdState === "" || physicalId.current?.value === "" || logicalName.current?.value === "" || physicalIdValid || logicalNameValid || physicalIdErrorState);
    } if (activeStep === 1) return deviceModelState === undefined || deviceProfileState === undefined;
    return connectivityProductState === undefined;
  };

  const saveDevice = (): void => {
    const device: Device = {
      deviceModelId: deviceModelState && deviceModelState.id,
      deviceProfileId: deviceProfileState && deviceProfileState.id,
      logicalName: logicalNameState,
      physicalId: physicalIdState,
      tags,
      accountId: accountIdState,
      organizationId: !organizationIdState ? undefined : organizationIdState,
      routingPlans: selectedRows,
      connectivityProductId: connectivityProductState && connectivityProductState.id,
      connectionStatus: "INITIAL",
    };
    submit(device);
    clear();
  };

  const selectAllRows = (e : any) : void => {
    if (e.target.checked) { setSelectedRows(routingPlans.map((e) => e.id!)); } else { setSelectedRows([]); }
  };

  const selectRow = (e : any, planId: string) : void => {
    setSelectedRows((prevState) => {
      if (e.target.checked) {
        if (!prevState.includes(planId)) { return [...prevState, planId]; } return prevState;
      }
      return prevState.filter((v) => v !== planId);
    });
  };

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
            <>
                <div className={classes.subsection}>
                    <TextField
                      id="logicalNameInput"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      label={t("deviceCreation.logicalName")}
                      variant="outlined"
                      defaultValue={logicalNameState}
                      inputRef={logicalName}
                      onBlur={handleLogicalOnBlur}
                    />
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="physicalIdInput"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      label={t("deviceCreation.physicalId")}
                      defaultValue={physicalIdState}
                      variant="outlined"
                      inputRef={physicalId}
                      onBlur={handlePhysicalOnBlur}
                      error={physicalIdErrorState}
                      helperText={physicalIdErrorText}
                    />
                </div>
                {currentUser.adminInSuperAdminOrganization && organizations
                 && (
                     <div className={classes.subsection}>
                         <FormControl className={classes.formControl}>
                             <InputLabel id="deviceOrganizationLabel" shrink>{t("deviceCreation.ownerOrganization")}</InputLabel>
                             <Select
                               key="select"
                               labelId="deviceOrganizationLabel"
                               id="deviceOrganizationLabelId"
                               value={organizationIdState}
                               onChange={handleOrganizationDropdownChange}
                               displayEmpty
                             >
                                 <MenuItem value="">Valitse organisaatio</MenuItem>
                                 {organizations.map((organization) => (
                                     <MenuItem key={organization.id} value={organization.id}>{organization.name}</MenuItem>

                                 ))}
                             </Select>
                             <FormHelperText>{t("deviceCreation.organizationHelperText")}</FormHelperText>
                         </FormControl>
                     </div>
                 )}
                <div className={classes.subsection}>
                    <div className={classes.tags}>
                        <InputLabel id="tagsLabel">{`${t("deviceCreation.tags")} ${tags.length}`}</InputLabel>
                        { /*   <TextField
                          id="tagInput"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t("deviceCreation.tags")}
                          variant="outlined"
                          inputRef={tag}
                        />
                        <AddCircleOutlined className={classes.addTagIcon} color="primary" id="addTagButton" onClick={() => { addTag(tag.current?.value); }} fontSize="large" /> */}
                        <div className={classes.setTags}>
                            {availableTags && availableTags.map((e) => (
                                <div
                                  key={e.id}
                                  role="button"
                                  tabIndex={0}
                                  id={e.name}
                                  onKeyPress={() => ""}
                                  onClick={() => selectTag(e)}
                                  className={tags.includes(e) ? classes.tagSet : classes.setTag}
                                >
                                    {e.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
      case 1:
        return (
            <>
                <div className={classes.routingSelection}>
                    <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="h2">{t("deviceCreation.deviceModelSelection")}</Typography>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="deviceModelLabel" shrink>{t("deviceCreation.deviceModel")}</InputLabel>
                        <Select
                          key="select"
                          labelId="deviceModelLabel"
                          id="deviceModelId"
                          value={deviceModelState?.id || ""}
                          onChange={handleDeviceModelChange}
                          displayEmpty
                          inputRef={deviceModelSelectRef}
                        >
                            <MenuItem value="">Valitse laitemalli</MenuItem>
                            {deviceModels.map((p) => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="deviceProfileLabel" shrink>{t("deviceCreation.deviceProfile")}</InputLabel>
                        <Select
                          key="select"
                          labelId="deviceProfileLabel"
                          id="deviceProfileId"
                          value={deviceProfileState?.id || ""}
                          onChange={handleDeviceProfileChange}
                          displayEmpty
                          inputRef={deviceProfileSelectRef}
                        >
                            <MenuItem value="">Valitse laiteprofiili</MenuItem>
                            {deviceModelState && deviceModelState.profiles && deviceModelState.profiles.map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                    v
                                    {p.macMajorVersion}
                                    .
                                    {p.macMinorVersion}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {deviceModelState !== undefined
                      && (
                          <List className={classes.infoList}>
                              <ListItem key="productidentifier" className={classes.productIdentifier}>
                                  <InputLabel>
                                      <b>{deviceModelState && deviceModelState.modelIdentifier}</b>
                                  </InputLabel>
                              </ListItem>
                              <ListItem key="modelvendor">
                                  <InputLabel>
                                      {`${t("deviceCreation.modelVendor", {
                                        vendor: deviceModelState && deviceModelState.vendor,
                                      })}`}
                                  </InputLabel>
                              </ListItem>
                              <ListItem key="modeltype">
                                  <InputLabel>
                                      {`${t("deviceCreation.modelType", {
                                        type: deviceModelState && deviceModelState.type,
                                      })}`}
                                  </InputLabel>
                              </ListItem>
                              <ListItem key="modeltypemac">
                                  <InputLabel>
                                      {`${t("deviceCreation.modelTypeMac", {
                                        typeMac: deviceModelState && deviceModelState.typeMac,
                                      })}`}
                                  </InputLabel>
                              </ListItem>
                              <ListItem key="decoding">
                                  <InputLabel>
                                      {`${t("deviceCreation.decoding", {
                                        decoding: deviceProfileState && deviceProfileState.decoding,
                                      })}`}
                                  </InputLabel>
                              </ListItem>
                          </List>
                      ) }
                </div>
            </>
        );
      case 2:
        return (
            <>
                <div className={classes.routingSelection}>
                    <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="h2">{t("deviceCreation.connectivityProductSelection")}</Typography>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="connectivityProductLabel" shrink>{t("deviceCreation.connectivityProduct")}</InputLabel>
                        <Select
                          key="select"
                          labelId="connectivityProductLabel"
                          id="connectivityProductId"
                          value={connectivityProductState?.id || ""}
                          onChange={handleConnectivityProductChange}
                          displayEmpty
                          inputRef={connectivityProductSelectRef}
                        >
                            <MenuItem value="">Valitse connectivity product</MenuItem>
                            {connectivityProducts.map((p) => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {connectivityProductState !== undefined
                    && (
                        <List className={classes.infoList}>
                            <ListItem key="productidentifier" className={classes.productIdentifier}>
                                <InputLabel>
                                    <b>{connectivityProductState.productIdentifier}</b>
                                </InputLabel>
                            </ListItem>
                            <ListItem key="freq">
                                <InputLabel>
                                    {`${t("deviceCreation.frequency", {
                                      frequency: connectivityProductState && connectivityProductState.frequency,
                                    })}`}
                                </InputLabel>
                            </ListItem>
                            {/* <ListItem key="outputtype">
                                <InputLabel>
                                    {`${t("deviceCreation.outputType", {
                                      outputType: connectivityProductState && connectivityProductState.outputType,
                                    })}`}
                                </InputLabel>
                            </ListItem>
                            <ListItem key="outputformat">
                                <InputLabel>
                                    {`${t("deviceCreation.outputFormat", {
                                      outputFormat: connectivityProductState && connectivityProductState.outputFormat,
                                    })}`}
                                </InputLabel>
                                  </ListItem> */}

                        </List>
                    ) }
                </div>
            </>
        );
      case 3:
        return (
            <>
                <div className={classes.routingSelection}>
                    <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="h2">{t("deviceCreation.routingPlanSelection")}</Typography>
                    <TableContainer className={classes.tableContainer} component={Paper} elevation={0} id="newdevicemodal-routingplantable">
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><Checkbox onChange={selectAllRows} /></TableCell>
                                    <TableCell>{t("deviceCreation.routingPlanName")}</TableCell>
                                    <TableCell align="right">URL</TableCell>
                                    <TableCell align="right">
                                        {t("deviceCreation.routingPlanType")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {routingPlans.map((row) => (
                                    <TableRow key={row.name} className={classes.tableRow}>
                                        <TableCell align="center"><Checkbox checked={selectedRows.includes(row.id!)} onChange={(e) => { selectRow(e, row.id!); }} /></TableCell>
                                        <TableCell align="right">{row.name}</TableCell>
                                        <TableCell align="right">{row.url}</TableCell>
                                        <TableCell align="right">{row.sendingType}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
      default:
        return <></>;
    }
  };
  return (
      <>
          <Modal
            className={classes.modal}
            open={open}
            onClose={clear}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id="addDeviceTitle">{t("deviceCreation.addDevice")}</Typography>
                      </div>
                      <div className={classes.section}>
                          {getStepContent(activeStep)}
                      </div>
                      <div className={classes.navigation}>

                          <div className={classes.navButtons}>

                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                              >
                                  {t("deviceCreation.back")}
                              </Button>

                              <Button
                                variant="contained"
                                disabled={isNextButtonDisabled()}
                                className={classes.nextButton}
                                color="primary"
                                onClick={activeStep === steps.length - 1 ? saveDevice : handleNext}
                              >
                                  {activeStep === steps.length - 1 ? t("deviceCreation.finish") : t("deviceCreation.next")}
                              </Button>
                          </div>
                          <Stepper activeStep={activeStep} alternativeLabel>
                              {steps.map((label) => (
                                  <Step key={label}>
                                      <StepLabel>{label}</StepLabel>
                                  </Step>
                              ))}
                          </Stepper>
                      </div>
                  </div>
              </Fade>
          </Modal>
      </>
  );
};
