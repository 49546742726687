import { action, makeObservable, observable, runInAction } from "mobx";
import { compareDesc, parseISO } from "date-fns";
import { getLatestUpDownLink, getRawDataByDays, getRawDataByMonths } from "../api/RawDataApi";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import RawDataUpDownLinkStatus from "../models/RawDataUpDownLinkStatus";
import { RawData } from "../models/RawData";

export default class RawDataStore extends BaseStore {
  latestRawDataUpDownLinkStatus?: RawDataUpDownLinkStatus;

  rawDataOfDay?: RawData[];

  rawDataOfWeek?: RawData[];

  rawDataOfMonth?: RawData[];

  loadLatestRawDataUpDownLinkStatus = async (physicalId: string): Promise<void> => {
    try {
      const l = await getLatestUpDownLink(physicalId);
      runInAction(() => {
        this.latestRawDataUpDownLinkStatus = l;
      });
    } catch (e) {
      this.setError(i18n.t("rawDataStore.latestRawDataUpDownLinkStatusFetchError"));
      console.error(e.stack);
    }
  }

  loadRawDataOfLastDay = async (physicalId: string): Promise<void> => {
    try {
      const r = await getRawDataByDays(physicalId, 1);
      runInAction(() => {
        this.rawDataOfDay = r.map((data) => ({
          invocationId: data.invocationId,
          rawBody: data.body,
          timestamp: parseISO(data.timeStamp as unknown as string),
          jsonBody: JSON.parse(data.body),
        })).sort((a, b) => compareDesc(a.timestamp, b.timestamp))
      });
    } catch (e) {
      this.setError(i18n.t("rawDataStore.rawDataFetchError"));
      this.rawDataOfDay = [];
      console.error(e.stack);
    }
  }

  loadRawDataOfLastWeek = async (physicalId: string): Promise<void> => {
    try {
      const r = await getRawDataByDays(physicalId, 7);
      runInAction(() => {
        this.rawDataOfWeek = r.map((data) => ({
          invocationId: data.invocationId,
          rawBody: data.body,
          timestamp: parseISO(data.timeStamp as unknown as string),
          jsonBody: JSON.parse(data.body),
        })).sort((a, b) => compareDesc(a.timestamp, b.timestamp))
      });
    } catch (e) {
      this.setError(i18n.t("rawDataStore.rawDataFetchError"));
      this.rawDataOfWeek = [];
      console.error(e.stack);
    }
  }

  loadRawDataOfLastMonth = async (physicalId: string): Promise<void> => {
    try {
      const r = await getRawDataByMonths(physicalId, 1);
      runInAction(() => {
        this.rawDataOfMonth = r.map((data) => ({
          invocationId: data.invocationId,
          rawBody: data.body,
          timestamp: parseISO(data.timeStamp as unknown as string),
          jsonBody: JSON.parse(data.body),
        })).sort((a, b) => compareDesc(a.timestamp, b.timestamp))
      });
    } catch (e) {
      this.setError(i18n.t("rawDataStore.rawDataFetchError"));
      this.rawDataOfMonth = [];
      console.error(e.stack);
    }
  }

  clearState = () : void => {
    this.latestRawDataUpDownLinkStatus = undefined;
    this.rawDataOfDay = [];
    this.rawDataOfMonth = [];
    this.rawDataOfWeek = [];
    this.error = undefined;
  }

  constructor() {
    super();
    makeObservable(this, {
      latestRawDataUpDownLinkStatus: observable,
      rawDataOfDay: observable,
      rawDataOfWeek: observable,
      rawDataOfMonth: observable,
      loadLatestRawDataUpDownLinkStatus: action,
      loadRawDataOfLastDay: action,
      loadRawDataOfLastWeek: action,
      loadRawDataOfLastMonth: action,
    });
  }
}
