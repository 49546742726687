import { Button, Container, Grid, InputBase, Typography, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Device from "../../../../models/Device";
import { displayableDate } from "../../../../util/dateutil";
import useStyles from "./DeviceTechnicalInfoStyles";

interface IDeviceTechnicalInfoProps {
    device: Device;
    update: (device: Device) => void;
}

enum Route {
    FAF = 0,
    WFR = 1,
    IOTDATASTORE = 2
}

export default ({ device, update }: IDeviceTechnicalInfoProps): JSX.Element => {
  const classes = useStyles();
  const [editOn, setEditOn] = useState(false);
  const { t } = useTranslation();
  const parserKeyRef = useRef<HTMLInputElement>();
  const urlRef = useRef<HTMLInputElement>();
  const decodingRef = useRef<HTMLInputElement>();
  const routeRef = useRef<HTMLInputElement>();

  const saveModifications = (): void => {
    const newDevice: Device = (JSON.parse(JSON.stringify(device)));
    if (newDevice.deviceConfig !== undefined) {
      if (parserKeyRef.current !== undefined) {
        newDevice.deviceConfig.parserKey = parserKeyRef.current.value;
      }
      if (urlRef.current !== undefined) {
        newDevice.deviceConfig.url = urlRef.current.value;
      }
      if (decodingRef.current !== undefined) {
        newDevice.deviceConfig.decoding = +decodingRef.current.value;
      }
      if (routeRef.current !== undefined) {
        newDevice.deviceConfig.route = +routeRef.current.value;
      }
      setEditOn(false);
      update(newDevice);
    }
  };

  return (
      <Container className={classes.rootContainer}>
          <Container className={classes.titleContainer}>
              <Typography className={classes.leftFloat} variant="h4">{t("singleDeviceView.technicalInfo")}</Typography>
          </Container>
          <Container className={classes.contentContainer}>
              <>
                  {device.deviceConfig
              && (
                  <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                          <table className={classes.table}>
                              <tr className={classes.tableRow}>
                                  <td className={classes.tableRowTitle}>
                                      <Typography className={classes.leftFloat} variant="subtitle1">
                                          Partition Key
                                      </Typography>
                                  </td>
                                  <td>
                                      <Typography className={classes.leftFloat} variant="subtitle1" id="DevicePartitionKey">
                                          {device.deviceConfig.partitionKey}
                                      </Typography>
                                  </td>
                              </tr>
                              <tr className={classes.tableRowSpacer} />
                              <tr className={classes.tableRow}>
                                  <td className={classes.tableRowTitle}>
                                      <Typography className={classes.leftFloat} variant="subtitle1">
                                          Row Key
                                      </Typography>
                                  </td>
                                  <td>
                                      <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceRowKey">
                                          {device.deviceConfig.rowKey}
                                      </Typography>
                                  </td>
                              </tr>
                              <tr className={classes.tableRowSpacer} />
                              <tr className={classes.tableRow}>
                                  <td className={classes.tableRowTitle}>
                                      <Typography className={classes.leftFloat} variant="subtitle1">
                                          Logical ID
                                      </Typography>
                                  </td>
                                  <td>
                                      <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceLogicalId">
                                          {device.deviceConfig.logicalId}
                                      </Typography>
                                  </td>
                              </tr>
                              <tr className={classes.tableRowSpacer} />
                              {/* <tr className={classes.tableRow}>
                                  <td className={classes.tableRowTitle}>
                                      <Typography className={classes.leftFloat} variant="subtitle1">
                                          Parser Key
                                      </Typography>
                                  </td>
                                  <td>
                                      {editOn
                                        ? (
                                            <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceParseKey">
                                                <InputBase
                                                  className={classes.input}
                                                  defaultValue={device.deviceConfig.parserKey}
                                                  inputRef={parserKeyRef}
                                                />
                                            </Typography>

                                        ) : (
                                            <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceParseKey">
                                                {device.deviceConfig.parserKey}
                                            </Typography>
                                        )}
                                  </td>
                              </tr>
                              <tr className={classes.tableRowSpacer} /> */}
                          </table>
                      </Grid>
                      <Grid item xs={12} md={5}>
                          <table className={classes.table}>
                              {/* <tr className={classes.tableRow}>
                                  <td className={classes.tableRowTitle}>
                                      <Typography className={classes.leftFloat} variant="subtitle1">
                                          URL
                                      </Typography>
                                  </td>
                                  <td>
                                      {editOn
                                        ? (
                                            <InputBase
                                              className={classes.input}
                                              defaultValue={device.deviceConfig.url}
                                              inputRef={urlRef}
                                            />

                                        ) : (
                                            <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceUrl">
                                                {device.deviceConfig.url}
                                            </Typography>
                                        )}
                                  </td>
                                        </tr> */}
                              <tr className={classes.tableRowSpacer} />
                              <tr className={classes.tableRow}>
                                  <td className={classes.tableRowTitle}>
                                      <Typography className={classes.leftFloat} variant="subtitle1">
                                          {t("singleDeviceView.decoding")}
                                      </Typography>
                                  </td>
                                  <td>
                                      {editOn
                                        ? (
                                            <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceDecoding">
                                                <InputBase
                                                  className={classes.input}
                                                  type="number"
                                                  defaultValue={device.deviceConfig.decoding}
                                                  inputRef={decodingRef}

                                                />
                                            </Typography>

                                        ) : (
                                            <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceDecoding">
                                                {device.deviceConfig.decoding}
                                            </Typography>
                                        )}
                                  </td>
                              </tr>
                              <tr className={classes.tableRowSpacer} />
                              {/* <tr className={classes.tableRow}>
                                  <td className={classes.tableRowTitle}>
                                      <Typography className={classes.leftFloat} variant="subtitle1">
                                          Route
                                      </Typography>
                                  </td>
                                  <td>
                                      {editOn
                                        ? (
                                            <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceRoute">
                                                <InputBase
                                                  className={classes.input}
                                                  type="number"
                                                  defaultValue={device.deviceConfig.route}
                                                  inputRef={routeRef}
                                                />
                                            </Typography>

                                        ) : (
                                            <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceRoute">
                                                {device.deviceConfig.route}
                                            </Typography>
                                        )}
                                  </td>
                                        </tr>
                              <tr className={classes.tableRowSpacer} /> */}
                              <tr className={classes.tableRow}>
                                  <td className={classes.tableRowTitle}>
                                      <Typography className={classes.leftFloat} variant="subtitle1">
                                          {t("singleDeviceView.timestamp")}
                                      </Typography>
                                  </td>
                                  <td>
                                      <Typography className={classes.leftFloat} variant="subtitle1" id="DeviceTimestamp">
                                          {displayableDate(device.deviceConfig.timestamp, "DATETIME", "SHORT")}
                                      </Typography>
                                  </td>
                              </tr>
                              <tr className={classes.tableRowSpacer} />
                          </table>
                      </Grid>
                  </Grid>
              )}

              </>
          </Container>
          <Container className={classes.titleContainer}>
              <Typography className={classes.leftFloat} variant="h4">Reitityssuunnitelmat</Typography>
          </Container>
          <Container>
              {device.deviceConfig?.deviceRoutingPlan ? (
                  <TableContainer className={classes.routingPlantTable} component={Paper} elevation={0} id="singledeviceview-routingplantable">
                      <Table size="small" aria-label="a dense table">
                          <TableHead>
                              <TableRow>
                                  <TableCell align="left">URL</TableCell>
                                  <TableCell align="left">
                                      {t("deviceCreation.routingPlanType")}
                                  </TableCell>
                                  <TableCell align="left">Header</TableCell>
                                  <TableCell align="left">RoutingPlan Id</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {device.deviceConfig?.deviceRoutingPlan.map((row : any) => (
                                  <TableRow key={row.id} className={classes.tableRow}>
                                      <TableCell align="left">{row.url}</TableCell>
                                      <TableCell align="left">
                                          {Route[row.sendingType]}
                                      </TableCell>
                                      <TableCell align="left">{row.customHeader}</TableCell>
                                      <TableCell align="left">{row.id}</TableCell>
                                  </TableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
              )
                : (
                    <Typography className={classes.leftFloat} variant="h5" id="singledeviceview-noplansfound">{t("singleDeviceView.noPlansFound")}</Typography>
                )}
          </Container>
          {!device.archived && false
              && (
                  <Container className={classes.buttonContainer}>
                      {editOn ? (
                          <>
                              <Button onClick={saveModifications} variant="outlined" color="primary" id="SaveTechnicalInfoChangesButton">{t("singleDeviceView.save")}</Button>
                              <Button onClick={() => setEditOn(false)} variant="outlined" color="primary" id="CancelChangesButton">{t("singleDeviceView.cancel")}</Button>
                          </>
                      )
                        : (
                            <Button onClick={() => setEditOn(true)} variant="outlined" color="primary" id="EditDeviceTechnicalInfoButton">
                                {t("singleDeviceView.modify")}
                            </Button>
                        ) }
                  </Container>
              )}
      </Container>
  );
};
