import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${blue[900]}`,
    boxShadow: theme.shadows[5],
    maxWidth: "100%",
    minWidth: "20%",
    height: "90%",
    "& .MuiStepConnector-line": {
      marginLeft: 15,
    },
  },
  alertsContainer: {
    float: "left",
    width: "50%",
    height: "70%",
    marginBottom: 40,
  },
  eventsContainer: {
    float: "left",
    width: "50%",
    height: "70%",
    marginBottom: 40,
  },
  rawDataBody: {
    padding: 5,
    marginBottom: 10,
  },
  modalHeader: {
    backgroundColor: blue[100],
  },
  sectionHeader: {
    padding: theme.spacing(2, 2, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.light,
    "& .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
    },
  },
  tableRoot: {
    maxHeight: "100%",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  section: {
    height: "40em",
  },
  input: {
    width: "100%",
  },
  backButton: {
    float: "left",
  },
  nextButton: {
    float: "right",
  },
  navigation: {
    padding: theme.spacing(6, 6, 6),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  navButtons: {
    display: "block",
    marginBottom: "15%",
  },
  subsection: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2, 2),
    display: "flex",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "fit-content",
  },
  addTagIcon: {
    cursor: "pointer",
    alignSelf: "center",
    marginLeft: 10,
  },
  setTags: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  setTag: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: 15,
    marginLeft: 10,
    marginTop: 15,
    padding: 10,
    width: "fit-content",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    cursor: "pointer",
  },
  activeCheckbox: {
    marginLeft: 0,
  },
  routingSelection: {
    backgroundColor: theme.palette.background.paper,
    padding: 20,
  },
  selectedRow: {
    backgroundColor: blue[600],
    "& .MuiTableCell-body": {
      color: theme.palette.primary.contrastText,
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  routingPlanSelectionTitle: {
    marginBottom: 25,
    marginTop: 25,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.divider}`,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  footer: {
    textAlign: "center",
    marginBottom: 20,
    marginTop: 20,
  },
}));
