import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#f7f7f7",
  },
  toolbar: {
    minHeight: "64px",
  },
  container: {
    paddingTop: 0,
    height: "100%",
    minWidth: "80%",
    display: "flex",
    justifyContent: "center",
  },
  spinner: {
    marginTop: "20%",
  },
}));
