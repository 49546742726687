import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import useStyles from "./SubscriptionViewStyles";
import PageTitle from "../../components/pageTitle/PageTitle";
import RouteDisplay from "../../components/routeDisplay/RouteDisplay";

export default (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
      <>
          <RouteDisplay currentLink="/subscription" currentName={t("navigation.subscription")} />
          <Container className={classes.container}>
              <PageTitle title="Tilaus" />
          </Container>
      </>
  );
};
