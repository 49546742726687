import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const drawerWidth = 240;
export default makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.dark,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  navItem: {
    textDecoration: "none",
    color: "white",
    "& .MuiListItemIcon-root": {
      color: "white",
    },
  },
}));
