import React from "react";
import { AppBar, Tab, Tabs, Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import useStyles from "./TabsStyles";

interface TabPanelProps {
 children?: React.ReactNode;
 index: any; // TODO: Fix type
 value: any; // TODO: Fix type
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
          {value === index && <Box p={3}>{children}</Box>}
      </Typography>
  );
};

interface TabTitle {
  label: string,
  id: string,
  disabled: boolean,
}

interface TabContent {
  index: number,
  contentElement: JSX.Element
}

interface TabsProps {
  tabTitles: TabTitle[];
  tabContents: TabContent[];
  tabSwitchedCallback?: () => void; // extra function to call when tab is switched
}

export default (({ tabTitles, tabContents, tabSwitchedCallback }: TabsProps): JSX.Element => {
  const [currentTab, setCurrentTab] = React.useState(0);
  // todo fix this
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
    if (tabSwitchedCallback) tabSwitchedCallback();
  };

  const classes = useStyles();

  return (
      <>
          <AppBar position="static" color="default" className={classes.myAppBar}>
              <Tabs value={currentTab} onChange={handleChange} aria-label="Tabs">
                  {tabTitles.map((tab) => (
                      <Tab key={tab.id} disabled={tab.disabled} label={tab.label} id={tab.id} aria-controls={tab.id} />
                  ))}
              </Tabs>
          </AppBar>

          {tabContents.map((tabContent) => (
              <TabPanel key={tabContent.index} value={currentTab} index={tabContent.index}>
                  {tabContent.contentElement}
              </TabPanel>
          ))}
      </>
  );
});
