import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  routeDisplay: {
    marginTop: 30,
    marginLeft: 30,
    display: "inline-flex",
  },
  deviceNameDisplay: {
    marginLeft: 40,
    marginTop: 30,
  },
  routeDisplayElementWrapper: {
    marginRight: 10,
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  homeIcon: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  currentLocationElementWrapper: {
    marginRight: 10,
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    display: "flex",
    alignItems: "center",
  },
}));
