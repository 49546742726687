import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import FrontPageView from "../FrontPageView";
import { useRootStore } from "../../../RootStateContext";
import PendingCircle from "../../../components/loadingCircle/loadingCircle";

export default observer((): JSX.Element => {
  const store = useRootStore().alertStore;
  const { activeAlertCountsForUser } = store;
  const usersStore = useRootStore().userStore;
  const { users } = usersStore;
  const devicesStore = useRootStore().deviceStore;
  const { totalDevices } = devicesStore;

  useEffect(() => {
    let mounted = true;
    store.loadUserActiveAlertCount()
      .catch(() => {
        if (mounted) {
          // TODO check error response for forbidden or unauthenticated etc.
        }
      });

    usersStore.loadUsers();
    devicesStore.loadTotalAmountOfDevices();

    return function cleanup() {
      mounted = false;
      store.clearState();
      usersStore.clearState();
    };
  }, [store, usersStore, devicesStore]);

  if (users === null || users === undefined) {
    return (
        <PendingCircle />
    );
  }

  return (
      <FrontPageView users={users} totalDevices={totalDevices} totalAlerts={activeAlertCountsForUser} />
  );
});
