import "@fontsource/roboto";
import { AppBar, Box, Button, Container, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { CloudDone, CloudOff, CloudQueue, Edit } from "@mui/icons-material";

import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ConfirmationModal from "../../components/deleteDeviceModal/ConfirmationModal";
import FilterSelection from "../../components/filterSelection/FilterSelection";
import NewDeviceModal from "../../components/newDeviceModal/NewDeviceModal";
import PageTitle from "../../components/pageTitle/PageTitle";
import RouteDisplay from "../../components/routeDisplay/RouteDisplay";
import DeviceTable, { Column } from "../../components/table/TableWithPagination";
import theme from "../../materialUITheme";
import ConnectivityProduct from "../../models/ConnectivityProduct";
import Device from "../../models/Device";
import { DeviceModel } from "../../models/DeviceModel";
import Organization from "../../models/Organization";
import RoutingPlan from "../../models/RoutingPlan";
import Tag from "../../models/Tag";
import { UserInfo } from "../../models/User";
import { useRootStore } from "../../RootStateContext";
import useStyles from "./DevicesViewStyles";
import MassEditModal from "../../components/massEditModal/MassEditModal";

interface IDeviceConfigViewProps {
  saveNewDevice: (device: Device) => Promise<void>;
  deleteDevices: (devices: Device[]) => Promise<void>;
  devices?: Device[];
  currentUser: UserInfo;
  organizations?: Organization[];
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalDevices: number;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  setArchived: (a: boolean) => void;
  organizationFilter: [(Organization | undefined), (o: Organization | undefined) => void ];
  search: (s: string) => void;
  resetAllOptions: () => void;
  organizationsFilterOptions?: Organization[];
  submitDecoding: (selectedDevices: Device[], decoding: string) => Promise<void>;
  submitTags: (selectedDevices: Device[], selectedTags: string[]) => Promise<void>;
  submitRoutings: (selectedDevices: Device[], selectedRoutings: string[]) => Promise<void>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any; // TODO: fix type
  value: any; // TODO: fix type
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
          {value === index && (
              <Box p={3}>
                  {children}
              </Box>
          )}
      </div>
  );
}

function a11yProps(index: any): any { // TODO: fix type
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default observer(({ saveNewDevice,
  deleteDevices,
  devices,
  currentUser,
  organizations,
  setPage,
  setPageSize,
  totalDevices,
  page,
  rowsPerPage,
  sort,
  setSortDirection,
  setArchived,
  organizationFilter,
  search,
  resetAllOptions,
  organizationsFilterOptions,
  submitDecoding,
  submitTags,
  submitRoutings }: IDeviceConfigViewProps): JSX.Element => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [massEditModalOpen, setMassEditModalOpen] = useState(false);
  const navigate = useNavigate();
  const store = useRootStore().deviceStore;
  const { t } = useTranslation();
  const [archivedState, setArchivedState] = React.useState(0); // Tab control
  const searchInput = useRef<HTMLInputElement>();

  const [searchErrorState, setSearchErrorState] = useState<boolean>(false);
  const [searchErrorText, setSearchErrorText] = useState<string>("");

  const setTab = (n: number): void => {
    store.resetDeviceSelection();
    setArchivedState(n);
  };

  const resetParams = (): void => {
    resetAllOptions();
    setTab(0);
    store.resetDeviceSelection();
    if (searchInput && searchInput.current) searchInput.current.value = "";
  };

  const handleChangeArchived = (event: React.ChangeEvent<any>, newValue: number): void => { // TODO: fix type
    setTab(newValue);
    setArchived(newValue === 1);
  };

  const columns: Column[] = [
    {
      id: "logicalName", label: t("deviceView.logicalName"), minWidth: 170,
    },
    {
      id: "deviceModel",
      label: t("deviceView.modelName"),
      minWidth: 170,
      format: (m: DeviceModel) => m && m.name,
      disableSort: true,
    },
    {
      id: "physicalId",
      label: t("deviceView.physicalId"),
      minWidth: 170,
    },
    {
      id: "organizationName",
      label: t("deviceView.organizationName"),
      minWidth: 170,
      disableSort: true,
    },
    {
      id: "tags",
      label: t("deviceView.tags"),
      minWidth: 170,
      disableSort: true,
      format: (deviceTags: Tag[]) => deviceTags.map((e) => e.name).join(", "),
    },
    {
      id: "connectivityProduct",
      label: t("deviceView.connectivityProduct"),
      format: (p: ConnectivityProduct) => p && p.name,
      disableSort: true,
    },
    {
      id: "connectionStatus",
      label: t("deviceView.connectionStatus"),
      format: (connectionStatus: string) => {
        switch (connectionStatus) {
          case "ONLINE":
            return <Tooltip title="Online"><CloudDone className={classes.statusOnlineIcon} /></Tooltip>;
          case "OFFLINE":
            return <Tooltip title="Offline"><CloudOff className={classes.statusOfflineIcon} /></Tooltip>;
          default:
            return <Tooltip title="Never seen"><CloudQueue className={classes.statusInitialIcon} /></Tooltip>;
        }
      },
      disableSort: true,
    },
  ];

  const cellClickedCallback = (device: Device): void => {
    navigate(`/device/${device.id}`);
  };

  const doSearch = (): void => {
    const searchVal = searchInput?.current?.value || "";

    if (searchVal.length <= 2) {
      setSearchErrorState(true);
      setSearchErrorText(t("common.searchErrorText"));
      return;
    }
    setSearchErrorState(false);
    setSearchErrorText("");

    if (searchVal !== undefined) { search(searchVal); }
  };

  const archiveAllSelected = (): void => {
    deleteDevices(store.selectedDevices);
  };
  return (
      <>
          <RouteDisplay currentLink="/devices" currentName={t("navigation.devices")} />
          <NewDeviceModal open={modalOpen} handleClose={() => setModalOpen(false)} submit={saveNewDevice} currentUser={currentUser} organizations={organizations || []} />
          <MassEditModal
            open={massEditModalOpen}
            handleClose={() => setMassEditModalOpen(false)}
            submitDecoding={submitDecoding}
            submitTags={submitTags}
            submitRoutings={submitRoutings}
            selectedDevices={store.selectedDevices}
            selectedDevicesHaveSameOrganization={store.selectedDevicesHaveSameOrganization}
          />
          <ConfirmationModal
            open={deleteModalOpen}
            text={t("deviceView.deleteConfirmation", {
              ids: store.selectedDevices.map((e) => e.physicalId).join(", "),
            })}
            handleClose={() => setDeleteModalOpen(false)}
            submit={archiveAllSelected}
          />
          <Container className={classes.container}>
              {archivedState === 0 ? (
                  <>
                      {currentUser.adminInSuperAdminOrganization
                        ? <PageTitle title={t("deviceView.allDevices")} /> : <PageTitle title={t("deviceView.devices")} />}
                      {(currentUser.adminInSuperAdminOrganization || currentUser.adminInCustomerOrganization)
                      && (
                          <Button
                            onClick={() => setModalOpen(true)}
                            color="primary"
                            variant="contained"
                            id="AddNewDeviceButton"
                            style={{
                              visibility: "hidden",
                              position: "absolute",
                            }}
                          >
                              {t("deviceView.addNewDevice")}
                          </Button>
                      )}
                      <Button
                        style={{
                          marginLeft: 25,
                        }}
                        color="secondary"
                        variant="contained"
                        id="DefaultSettingsButton"
                        onClick={resetParams}
                      >
                          {t("deviceView.defaultSettings")}
                      </Button>
                      {store.selectedDevices.length > 0 && (
                          <Button
                            style={{
                              marginLeft: 25,
                              backgroundColor: theme.palette.error.dark,
                              color: "white",

                            }}
                            variant="contained"
                            id="archiveAllButton"
                            onClick={() => setDeleteModalOpen(true)}
                          >
                              {t("deviceView.archiveAll")}
                          </Button>
                      )}
                      {store.selectedDevices.length > 0 && (
                          <Button
                            style={{
                              marginLeft: 25,
                              backgroundColor: theme.palette.error.dark,
                              color: "white",

                            }}
                            variant="contained"
                            id="massEditModalOpenButton"
                            onClick={() => setMassEditModalOpen(true)}
                          >
                              {t("deviceView.openMassEdit")}
                          </Button>
                      )}
                  </>
              ) : (
                  <>
                      <PageTitle title={t("deviceView.archivedDevices")} />
                      <>
                          {(currentUser.adminInSuperAdminOrganization || currentUser.adminInCustomerOrganization)
              && (
                  <Button
                    onClick={() => setModalOpen(true)}
                    color="primary"
                    variant="contained"
                    id="AddNewDeviceButton"
                  >
                      {t("deviceView.addNewDevice")}
                  </Button>
              )}
                          <Button
                            style={{
                              marginLeft: 25,
                            }}
                            color="secondary"
                            variant="contained"
                            id="DefaultSettingsButton"
                            onClick={resetParams}
                          >
                              {t("deviceView.defaultSettings")}
                          </Button>
                          {store.selectedDevices.length > 0 && (
                              <Button
                                style={{
                                  marginLeft: 25,
                                  backgroundColor: theme.palette.error.dark,
                                  color: "white",
                                }}
                                variant="contained"
                                id="archiveAllButton"
                                onClick={() => setDeleteModalOpen(true)}
                              >
                                  {t("deviceView.archiveAll")}
                              </Button>
                          )}
                      </>
                  </>
              )}
              <AppBar className={classes.tabBar} position="static">
                  <FilterSelection<Organization> filters={organizationsFilterOptions || []} setFilter={organizationFilter[1]} currentFilter={organizationFilter[0]} filterHelperText={t("deviceView.organizationFilter")} />

                  <Tabs centered value={archivedState} onChange={handleChangeArchived} aria-label="simple tabs example">
                      <Tab key="tab1" label={t("deviceView.activeDevices")} {...a11yProps(0)} />
                      <Tab key="tab2" label={t("deviceView.archivedDevices")} {...a11yProps(1)} />
                  </Tabs>
                  <div className={classes.searchInput}>
                      <TextField
                        inputRef={searchInput}
                        onKeyDown={(e) => { if (e.key === "Enter") doSearch(); }}
                        className={classes.searchInput}
                        id="outlined-basic"
                        label={t("deviceView.searchHelpText1")}
                        placeholder={t("deviceView.searchHelpText2")}
                        variant="outlined"
                        error={searchErrorState}
                        helperText={searchErrorText}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Button onClick={() => doSearch()} className={classes.searchButton} variant="contained" color="primary">{t("deviceView.search")}</Button>
                  </div>

              </AppBar>

              { devices && devices.length === 0 ? <div className={classes.noDevices}><Typography className={classes.headerContainer} variant="h4" component="h2">{t("deviceView.noDevices")}</Typography></div>
                : (
                    <>
                        <TabPanel key="tabpanel1" value={archivedState} index={0}>
                            <DeviceTable<Device>
                              columns={columns}
                              rows={devices || []}
                              count={totalDevices}
                              clickRowCallback={cellClickedCallback}
                              handlePageChange={setPage}
                              onClickIcon={<Edit />}
                              handlePageSizeChange={setPageSize}
                              currentPage={page}
                              currentRowsPerPage={rowsPerPage}
                              sort={sort}
                              setSortDirection={setSortDirection}
                              toggleRowSelection={store.toggleDeviceSelection}

                            />
                        </TabPanel>
                        <TabPanel key="tabpanel2" value={archivedState} index={1}>
                            <DeviceTable<Device>
                              columns={columns}
                              rows={devices || []}
                              count={totalDevices}
                              onClickIcon={<Edit />}
                              clickRowCallback={cellClickedCallback}
                              handlePageChange={setPage}
                              handlePageSizeChange={setPageSize}
                              currentPage={page}
                              currentRowsPerPage={rowsPerPage}
                              sort={sort}
                              setSortDirection={setSortDirection}
                            />
                        </TabPanel>
                    </>
                )}
          </Container>

      </>
  );
});
