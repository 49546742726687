import React from "react";
import Modal from "@mui/material/Modal";
import { Button, Fade, List, ListItem, Typography } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import useStyles from "./EventHistoryModalStyles";
import Alert from "../../models/Alert";
import Event from "../../models/Event";

interface IModalProps {
    events?: Event[];
    alerts?: Alert[];
    open: boolean;
    handleClose: () => void;
}

// todo: finish this modal, make it pretty and page events?
export default ({ alerts, events, open, handleClose }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const clear = (): void => {
    handleClose();
  };
  function primaryEventText(event: Event): React.ReactNode {
    return `${event.eventTime} | ${event.title}: ${event.message}`;
  }
  function primaryAlertText(alert: Alert): React.ReactNode {
    return `${alert.startTime} | ${alert.title}`;
  }

  if (events === undefined || events === null || events.length === 0) {
    return (
        <>
            <Modal
              className={classes.modal}
              open={open}
              onClose={clear}
              id="alertsAndEventsModal"
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.sectionHeader}>
                            <Typography variant="h6" component="h2" id="noEventsOrAlertsTitle">{t("frontPage.noEventsOrAlerts")}</Typography>
                        </div>

                        <div className={classes.footer}>
                            <Button variant="outlined" onClick={handleClose} color="primary" id="closeEventHistoryModal">
                                {t("common.close")}
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
  }
  return (
      <>
          <Modal
            className={classes.modal}
            open={open}
            onClose={clear}
            id="alertsAndEventsModal"
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id="alertsAndEventsTitle">{t("singleDeviceView.alertsAndEvents")}</Typography>
                      </div>
                      <div id="alertsSection" className={classes.alertsContainer}>
                          {alerts && alerts.length > 0
                            ? (
                                <List className={classes.tableRoot}>
                                    {alerts.map((alert) => ((
                                        <ListItem button key={alert.id}>
                                            <ListItemText primary={primaryAlertText(alert)} secondary={JSON.stringify(alert.id, null, 2)} />
                                        </ListItem>
                                    )))}
                                </List>
                            ) : (<p>Ei hälytyksiä</p>) }
                      </div>
                      <div id="eventsSection" className={classes.eventsContainer}>
                          <List className={classes.tableRoot}>
                              {events.map((event) => ((
                                  <ListItem button key={event.id}>
                                      <ListItemText primary={primaryEventText(event)} secondary={JSON.stringify(event.data, null, 2)} />
                                  </ListItem>
                              )))}
                          </List>
                      </div>
                      <div className={classes.footer}>
                          <Button variant="outlined" onClick={handleClose} color="primary" id="closeEventHistoryModal">
                              {t("common.close")}
                          </Button>
                      </div>
                  </div>
              </Fade>
          </Modal>
      </>
  );
};
