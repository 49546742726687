import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router";
import { toast } from "react-toastify";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import Device from "../../../models/Device";
import { useRootStore } from "../../../RootStateContext";
import SingleDeviceView from "../SingleDeviceView";
import PendingCircle from "../../../components/loadingCircle/loadingCircle";

interface ISingleDeviceRootViewMatchParams extends Params<string> {
    deviceId: string;
}

export default observer((): JSX.Element => {
  const match = useParams<ISingleDeviceRootViewMatchParams>();
  const store = useRootStore().deviceStore;
  const dataStore = useRootStore().rawDataStore;
  const { alertStore } = useRootStore();
  const { activeDeviceAlerts } = alertStore;
  const { device } = store;
  const { latestRawDataUpDownLinkStatus } = dataStore;
  const [forbidden, setForbidden] = useState(false);
  const { t } = useTranslation();

  const updateDevice = (d: Device): void => {
    (async () => {
      await store.updateDevice(d);
      if (store.error === undefined) {
        toast.info(t("singleDeviceView.deviceUpdated"), {
          toastId: "DeviceUpdatedToast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    })();
  };

  const requestDeviceUpdate = (d: Device): void => {
    (async () => {
      await store.requestDeviceUpdate(d);
      if (store.error === undefined) {
        toast.info(t("singleDeviceView.deviceUpdateRequestSent"), {
          toastId: "DeviceUpdateRequestToast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    })();
  };

  useEffect(() => {
    let mounted = true;
    const { deviceId } = match;
    store.loadDevice(deviceId!)
      .then(() => {
        if (store.device?.physicalId) {
          dataStore.loadLatestRawDataUpDownLinkStatus(store.device?.physicalId);
        }
        alertStore.loadActiveAlertsForDevice(store.device?.organizationId || "", store.device?.id || "");
      }).catch(() => {
        if (mounted) {
          // TODO check error response for forbidden or unauthenticated etc.
          setForbidden(true);
        }
      });

    return function cleanup() {
      mounted = false;
      store.clearState();
      dataStore.clearState();
      alertStore.clearState();
    };
  }, [match, match.deviceId, store, dataStore, alertStore]);

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  if (device === null || device === undefined) {
    return (
        <PendingCircle />
    );
  }
  return (
      <SingleDeviceView
        updateDevice={updateDevice}
        device={device}
        requestDeviceUpdate={requestDeviceUpdate}
        latestRawDataUpDownLinkStatus={latestRawDataUpDownLinkStatus}
        activeAlerts={activeDeviceAlerts}
      />
  );
});
