import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import "@fontsource/roboto";
import { useTranslation } from "react-i18next";
import useStyles from "./FilterSelectionStyles";

interface IFilterSelectionProps<T extends Filterable> {
    setFilter: (filter: T | undefined) => void;
    filters: T[];
    currentFilter: T | undefined;
    filterHelperText: string;

}

interface Filterable {
    name: string;
    id?: string | undefined;
}

export default <T extends Filterable> ({ setFilter, filters, filterHelperText, currentFilter }: IFilterSelectionProps<T>): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const useFilter = (f: string): void => {
    setFilter(filters.find((e) => e.id === f));
  };

  return (
      <div className={classes.container}>
          {filters.length > 0 ? (
              <FormControl className={classes.input}>
                  <InputLabel id="filterHelperLabel">{filterHelperText}</InputLabel>
                  <Select
                    key="select"
                    labelId="filterlabel"
                    id="filterId"
                    value={currentFilter?.id || ""}
                    onChange={(e) => useFilter(e.target.value as string)}
                  >
                      {filters.map((f) => (
                          <MenuItem key={f.name} value={f.id}>{f.name}</MenuItem>
                      ))}
                  </Select>
              </FormControl>
          ) : (
              <Typography className={classes.noFiltersText}>
                  {" "}
                  -
                  {t("filterSelector.noActiveFilters")}
                  -
                  {" "}
              </Typography>
          )}
      </div>
  );
};
