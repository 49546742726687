import { action, makeObservable, observable, runInAction } from "mobx";
import BaseStore from "./BaseStore";
import { login as login2, getAuthenticatedUserInfo, logout } from "../api/AuthorizationApi";
import { getUserDetails } from "../api/UserApi";
import { UserInfo } from "../models/User";
import IdentityUserModel from "../models/IdentityUserModel";

export default class AuthenticationStore extends BaseStore {
    identityUserModel?: IdentityUserModel = undefined;

    currentUser?: UserInfo = undefined;

    authenticated = false;

    login = async (): Promise<void> => {
      await login2();
      await getAuthenticatedUserInfo();
      await getUserDetails();
    }

    logout = (): void => {
      this.authenticated = false;
      logout();
    }

    fetchCurrentUserInfo = async (): Promise<IdentityUserModel> => {
      try {
        const info = await getAuthenticatedUserInfo();
        runInAction(() => {
          this.authenticated = true;
          this.identityUserModel = info;
        });
        return info;
      } catch (e) {
        console.error("FAILED TO GET USER INFO", e);
        this.authenticated = false;
        throw e;
      }
    }

    fetchUserDetails = async (): Promise<void> => {
      try {
        const userDetails = await getUserDetails();
        runInAction(() => {
          this.currentUser = userDetails;
        });
      } catch (e) {
        console.error("FAILED TO GET USER DETAILS", e);
        throw e;
      }
    }

    constructor() {
      super();
      makeObservable(this, {
        currentUser: observable,
        authenticated: observable,
        login: action,
        fetchCurrentUserInfo: action,
        fetchUserDetails: action,
        logout: action,
      });
    }
}
