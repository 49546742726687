import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: "64px",
  },
  container: {
    paddingTop: 20,
    minWidth: "100%",
  },
}));
