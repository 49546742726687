import { Card, CardHeader, CardContent, Grid, Typography, CircularProgress } from "@mui/material";
import React, { useState, useLayoutEffect, useRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import * as icons from "@mui/icons-material";
import { useRootStore } from "../../RootStateContext";
import useStyles from "./StatCardStyles";
import PendingCircle from "../PendingCircle/PendingCircle";

interface StatCardProps {
    icon: string;
    iconColor?: string;
    header: ReactNode;
    content?: string;
    heavyContent?: string;
    className?: string;
    id?: string;
    loadingState?: boolean;
  }

export default function ({ header, content, heavyContent, icon, iconColor, className, id, loadingState }: StatCardProps): JSX.Element {
  const classes = useStyles();
  const [isOverflow, setIsOverflow] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const renderIcon = () : any => {
    const availableIcons = Object.keys(icons);
    if (availableIcons.indexOf(icon) > -1) {
      // @ts-expect-error Implicit indexOf
      const Icon = icons[icon];
      return (
          <Icon
            className={classes.cardIcon}
            fontSize="large"
            style={{
              color: iconColor || "",
            }}
          />
      );
    }

    console.warn(`StatCard: Could no find icon named "${icon}", defaulting to droplet icon for this card`);

    const Icon = icons.Opacity;
    return <Icon className={classes.cardIcon} fontSize="large" />;
  };

  useLayoutEffect(() => {
    if (typeof divRef !== "undefined" && divRef !== null && typeof divRef.current !== "undefined" && divRef.current !== null) {
      if (divRef!.current!.offsetHeight > 60) {
        setIsOverflow(true);
      } else {
        setIsOverflow(false);
      }
    }
  }, [divRef]);

  const renderContent = () : any => {
    if (loadingState) return <></>;

    if (heavyContent && content) {
      return (
          <>
              <Typography className={classes.cardHeavyContent} align="left" component="span">{heavyContent}</Typography>
              <br />
              <Typography className={`${classes.cardContent} ${heavyContent && content ? classes.cardContentWithHeavy : ""}`} align="left" component="span">{content}</Typography>
          </>
      );
    }
    if (heavyContent) return <Typography className={classes.cardHeavyContent} align="left" component="span">{heavyContent}</Typography>;
    if (content) return <Typography className={`${classes.cardContent} ${heavyContent && content ? classes.cardContentWithHeavy : ""}`} align="left" component="span">{content}</Typography>;

    return <></>;
  };

  return (
      <Card className={`${classes.card} ${className || ""}`} variant="elevation" elevation={2} id={id || ""}>
          <CardHeader
            className={classes.muiCardHeader}
            title={(
                <div className={classes.statCardHeaderContainer}>
                    {renderIcon()}

                    {loadingState ? (
                        <div className={`${classes.cardHeader} ${!isOverflow ? classes.cardHeaderOf : ""}`}>
                            <CircularProgress
                              size={25}
                            />
                        </div>
                    ) : (
                        <div ref={divRef} className={`${classes.cardHeader} ${!isOverflow ? classes.cardHeaderOf : ""}`}>{header}</div>
                    )}

                </div>
            )}
          />
          <CardContent>
              {renderContent()}
          </CardContent>
      </Card>
  );
}
