import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../RootStateContext";

import UsersView from "../UsersView";
import PendingCircle from "../../../../components/loadingCircle/loadingCircle";

export default observer((): JSX.Element => {
  const { userStore } = useRootStore();
  const { users } = userStore;

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");

  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  useEffect(() => {
    userStore.loadUsersPaged(page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection);
    return function cleanup() {
      userStore.clearState();
    };
  }, [userStore, sortBy, page, pageSize, sortDirection]);

  if (users === null || users === undefined || users.length === 0) {
    return (
        <PendingCircle />
    );
  }

  return (
      <UsersView
        users={users}
        sort={sort}
        setSortDirection={setSortDirection}
        page={page}
        rowsPerPage={pageSize}
        setPage={handlePageChange}
        totalUsers={userStore.totalUsers}
        setPageSize={handlePageSizeChange}
      />
  );
});
