import React, { useState, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button, Fade, FormControl, TextField, Typography, FormLabel, Select, MenuItem, Checkbox, Grid, IconButton, List, ListItem, Step, StepLabel, Stepper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Delete, Add } from "@mui/icons-material"
import RoutingPlan, { CustomHeader } from "../../../models/RoutingPlan";
import useStyles from "./SingleRoutingPlanModalStyles";
import { useRootStore } from "../../../RootStateContext";
import { uuid } from "../../../util/basicutil";

interface IModalProps {
    routingPlan: RoutingPlan;
    open: boolean;
    handleClose: () => void;
    submit: (r: RoutingPlan) => void;
}

export default ({ routingPlan, open, handleClose, submit }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const store = useRootStore().routingPlanStore;
  const { t } = useTranslation();

  const steps = [t("routingView.basicInfo"), t("routingView.customHeaderInfo")];
  const [activeStep, setActiveStep] = useState(0);

  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [defaultPlan, setDefaultPlan] = useState(false);
  const [sendingType, setSendingType] = useState("");

  const [routingNameError, setRoutingNameError] = useState(false);
  const [routingUrlError, setRoutingUrlError] = useState(false);
  const [routingSendtypeError, setRoutingSendtypeError] = useState(false);

  const [customHeaders, setCustomHeaders] = useState<CustomHeader[]>([]);

  useEffect(() => {
    if (!open) return;
    setName(routingPlan.name);
    setUrl(routingPlan.url);
    setDefaultPlan(routingPlan.defaultPlan);
    setSendingType(routingPlan.sendingType);

    if (routingPlan.customHeader !== null) {
      const headers : CustomHeader[] = Object.entries(routingPlan.customHeader).map(([key, value]) => {
        const header : CustomHeader = {
          key,
          value,
          uuid: uuid(),
        }

        return header;
      });

      setCustomHeaders(headers);
    }
  }, [routingPlan, open]);

  const close = () : void => {
    setName("");
    setUrl("");
    setSendingType("");
    setCustomHeaders([]);
    setDefaultPlan(false);
    setActiveStep(0)
    handleClose();
  }

  const updateRoutingPlan = (): void => {
    const updated = JSON.parse(JSON.stringify(routingPlan));

    updated.name = name;
    updated.url = url;
    updated.defaultPlan = defaultPlan;
    updated.sendingType = sendingType;
    updated.customHeader = customHeaders.filter((header) => header.key !== "" && header.value !== "").reduce((a, header) => ({
      ...a, [header.key]: header.value,
    }), {})

    if (typeof updated.organizationId !== "undefined") { delete updated.organizationId; }
    if (typeof updated.organizationName !== "undefined") { delete updated.organizationName; }

    submit(updated);
    close();
  };

  const newCustomHeader = () : void => {
    const header : CustomHeader = {
      uuid: uuid(),
      key: "",
      value: "",
    };

    setCustomHeaders((headers) => [...headers, header])
  }

  const removeCustomHeader = (uuid: string) : void => {
    setCustomHeaders((headers) => headers.filter((header) => header.uuid !== uuid));
  }

  const customHeaderOnChange = (uuid: string, keyOrValue: "key" | "value", newValue : string) : void => {
    setCustomHeaders((headers) => {
      const newHeaders = headers;
      const idx = headers.findIndex(((header) => header.uuid === uuid));
      const editedHeader : CustomHeader = {
        uuid: headers[idx].uuid,
        key: "",
        value: "",
      }

      if (keyOrValue === "key") {
        editedHeader.key = newValue;
        editedHeader.value = headers[idx].value;
      } else if (keyOrValue === "value") {
        editedHeader.key = headers[idx].key;
        editedHeader.value = newValue;
      }

      newHeaders[idx] = editedHeader;

      return newHeaders;
    });
  }

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
            <>
                <div className={classes.subsection}>
                    <form noValidate autoComplete="off">
                        <TextField value={name} id="editRoutingPlanName" label={t("routingView.name")} error={routingNameError} onChange={(e) => setName(e.target.value as string)} />
                    </form>
                </div>

                <div className={classes.subsection}>
                    <form noValidate autoComplete="off">
                        <TextField value={url} className={classes.input} id="editRoutingPlanDestinationUrl" label="URL" error={routingUrlError} onChange={(e) => setUrl(e.target.value as string)} />
                    </form>
                </div>

                <div className={classes.subsection}>
                    <form noValidate autoComplete="off">
                        <FormLabel component="legend">
                            {t("routingView.useAsDefaultPlan")}
                            <Checkbox color="primary" checked={defaultPlan} onChange={() => { setDefaultPlan((a) => !a); }} id="editRoutingPlanAsDefault" />
                        </FormLabel>
                    </form>
                </div>

                <div className={classes.subsection}>
                    <form noValidate autoComplete="off">
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{t("routingView.messageSendingType")}</FormLabel>
                            <Select
                              id="edit-routing-plan-sendtype-select"
                              onChange={(e) => setSendingType(e.target.value as string)}
                              displayEmpty
                              value={sendingType || ""}
                              error={routingSendtypeError}
                            >
                                <MenuItem value="">{t("routingView.selectType")}</MenuItem>
                                {Object.entries(store.sendTypes).map((st) => <MenuItem key={st[0]} value={st[0]}>{st[1] as string}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </form>
                </div>
                {routingNameError || routingUrlError || routingSendtypeError ? (
                    <div className={classes.subsection}>
                        <Typography
                          variant="h6"
                          component="h2"
                          id="routingplanErrorText"
                          style={{
                                  color: "#EE145B",
                          }}
                        >
                            {t("routingView.fillAllFields")}
                        </Typography>
                    </div>
                      ) : ""}
            </>
        );
      case 1:
        return (
            <>
                <List sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                }}
                >
                    {customHeaders.map((header) => (
                        <ListItem
                          key={header.uuid}
                          style={{
                                padding: "5px 0px 5px 0px",
                          }}
                        >
                            <Grid
                              container
                              xs={12}
                              gridTemplateColumns="1fr 1fr min-content"
                              columns={3}
                              display="grid"
                              columnGap="5px"
                              rowGap="0px"
                            >
                                <Grid item container>
                                    <TextField defaultValue={header.key} label={t("routingView.key")} onChange={(e) => { customHeaderOnChange(header.uuid!, "key", e.target.value) }} />
                                </Grid>
                                <Grid item container>
                                    <TextField defaultValue={header.value} label={t("routingView.value")} onChange={(e) => { customHeaderOnChange(header.uuid!, "value", e.target.value) }} />
                                </Grid>
                                <Grid item container justifyContent="center" alignContent="center">
                                    <IconButton
                                      edge="end"
                                      aria-label={t("routingView.removeCustomHeader")}
                                      title={t("routingView.removeCustomHeader")}
                                      onClick={() => { removeCustomHeader(header.uuid!) }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Grid>

                            </Grid>
                        </ListItem>
                        ))}
                    <ListItem
                      key="routing-list-add-row"
                      style={{
                                padding: "5px 0px 5px 0px",
                      }}
                    >
                        <Grid
                          container
                          xs={12}
                        >
                            <Grid
                              container
                              item
                              xs={6}
                              alignContent="center"
                            >
                                <Typography>{t("routingView.newCustomHeader")}</Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={6}
                              justifyContent="flex-end"
                              alignContent="center"
                            >
                                <IconButton
                                  edge="end"
                                  aria-label={t("routingView.newCustomHeader")}
                                  title={t("routingView.newCustomHeader")}
                                  onClick={newCustomHeader}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>

                        </Grid>
                    </ListItem>
                </List>
            </>
        );
      default:
        return <></>;
    }
  };

  const isNextButtonDisabled = (): boolean => {
    if (activeStep === 0) {
      if (name === "") {
        setRoutingNameError(true);
        return true;
      }

      setRoutingNameError(false);

      if (url === "") {
        setRoutingUrlError(true);
        return true;
      }

      setRoutingUrlError(false);

      if (sendingType === "") {
        setRoutingSendtypeError(true);
        return true;
      }

      setRoutingSendtypeError(false);
    }

    return false;
  };

  const handleNext = async (): Promise<void> => {
    if (!isNextButtonDisabled()) { setActiveStep((prevActiveStep) => prevActiveStep + 1); }
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
      <>
          <Modal
            className={classes.modal}
            open={open}
            onClose={() => { close(); }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id="editRoutingPlanHeader">{t("routingView.routingPlan")}</Typography>
                      </div>

                      <div className={classes.section}>
                          {getStepContent(activeStep)}
                      </div>

                      <div className={classes.navigation}>

                          <div className={classes.navButtons}>

                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                              >
                                  {t("deviceCreation.back")}
                              </Button>

                              <Button
                                variant="contained"
                                className={classes.nextButton}
                                color="primary"
                                onClick={activeStep === steps.length - 1 ? updateRoutingPlan : handleNext}
                              >
                                  {activeStep === steps.length - 1 ? t("deviceCreation.finish") : t("deviceCreation.next")}
                              </Button>
                          </div>
                          <Stepper activeStep={activeStep} alternativeLabel>
                              {steps.map((label) => (
                                  <Step key={label}>
                                      <StepLabel>{label}</StepLabel>
                                  </Step>
                              ))}
                          </Stepper>
                      </div>

                  </div>
              </Fade>
          </Modal>
      </>
  );
};
