import { observable, action, makeObservable, runInAction } from "mobx";
import { deleteRoutingPlan,
  getRoutingPlans,
  getRoutingPlansOfOrganization,
  saveNewRoutingPlan,
  updateRoutingPlan,
  deleteRoutingPlans,
  getSendTypes,
  getRoutingPlansPaged } from "../api/RoutingPlanApi";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import RoutingPlan from "../models/RoutingPlan";

export default class RoutingPlanStore extends BaseStore {
  routingPlans: RoutingPlan[] = [];

  sendTypes: Record<string, unknown> = {};

  currentDefaultRoutingPlan?: RoutingPlan;

  routingPlan?: RoutingPlan;

  organizationRoutingPlans: RoutingPlan[] = [];

  selectedRoutingPlans: RoutingPlan[] = [];

  routingPlansPaged: RoutingPlan[] = [];

  totalRoutingPlansPaged = 0;

  loadingRoutingPlansPaged = false;

  loadingOrganizationRoutingPlans = false;

  loadRoutingPlans = async (): Promise<void> => {
    try {
      const r = await getRoutingPlans();
      runInAction(() => {
        this.routingPlans = r;
      });
    } catch (e) {
      this.setError(i18n.t("routingPlanStore.routingPlansFetchError"));
      console.error(e.stack);
    }
  }

  loadSendTypes = async () : Promise<void> => {
    try {
      const r = await getSendTypes();
      runInAction(() => {
        this.sendTypes = r;
      });
    } catch (e) {
      this.setError(i18n.t("routingPlanStore.sendingTypeFetchError"));
      console.error(e.stack);
    }
  }

  toggleRoutingPlanSelection = (r: RoutingPlan): void => {
    if (this.selectedRoutingPlans.includes(r)) this.selectedRoutingPlans = this.selectedRoutingPlans.filter((e) => e !== r);
    else this.selectedRoutingPlans = [...this.selectedRoutingPlans, r];
  }

 deleteRoutingPlans = async (plans: RoutingPlan[]): Promise<void> => {
   try {
     const deleted: string[] = await deleteRoutingPlans(plans.map((e) => e.id || ""));
     runInAction(() => {
       this.routingPlansPaged = this.routingPlansPaged?.filter((d) => {
         if (!d.id) return false;
         return !deleted.includes(d.id);
       });
       const failureList = plans.filter((e) => !e.id || !deleted.includes(e.id));
       if (failureList.length > 0) {
         this.setError(i18n.t("routingPlanStore.deleteFailedForXRoutingPlans", {
           ids: failureList.join(", "),
         }));
       }
       this.resetRoutingPlanSelection();
     });
   } catch (e) {
     this.setError(i18n.t("routingPlanStore.deleteFailedAllRoutingPlans"));
     console.error(e.stack);
   }
 }

  resetRoutingPlanSelection = (): void => {
    this.selectedRoutingPlans = [];
  }

  loadRoutingPlansOfOrganization = async (organizationId : string): Promise<void> => {
    try {
      this.loadingOrganizationRoutingPlans = true;
      const r = await getRoutingPlansOfOrganization(organizationId);
      runInAction(() => {
        this.routingPlans = r;
        this.organizationRoutingPlans = r;
        this.loadingOrganizationRoutingPlans = false
      });
    } catch (e) {
      this.setError(i18n.t("routingPlanStore.routingPlansFetchError"));
      this.organizationRoutingPlans = [];
      this.loadingOrganizationRoutingPlans = false
      console.error(e.stack);
    }
  }

  loadRoutingPlansPaged = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string, organization?: string): Promise<void> => {
    try {
      this.loadingRoutingPlansPaged = true;
      const planResp = await getRoutingPlansPaged(page, pageSize, sortBy, sortDirection, organization);

      runInAction(() => {
        this.routingPlansPaged = planResp.content.map((plan) => {
          const prunedPlan = {
            ...plan,
          };

          if (typeof prunedPlan?.userId !== "undefined") { delete prunedPlan?.userId; }
          return prunedPlan;
        });
        this.totalRoutingPlansPaged = planResp.totalElements;
        this.loadingRoutingPlansPaged = false;
      });
    } catch (e) {
      this.loadingRoutingPlansPaged = false;
      this.setError(i18n.t("routingPlanStore.routingPlansFetchError"));
      this.routingPlansPaged = [];
      this.totalRoutingPlansPaged = 0;
      console.error(e.stack);
    }
  }

  updateRoutingPlan = async (routingPlan: RoutingPlan): Promise<RoutingPlan> => {
    try {
      const updated = await updateRoutingPlan(routingPlan);
      runInAction(() => {
        this.routingPlan = updated;
      });
      return updated;
    } catch (e) {
      this.setError(i18n.t("routingPlanStore.routingPlanUpdateError"));
      console.error(e.stack);
      console.log(JSON.stringify(e));
      return Promise.reject(e.stack);
    }
  }

  getDefaultRoutingPlan = async (): Promise<void> => {
    try {
      /* const mockRoutingPlan: RoutingPlan = {
        id: "4f70814e-cd1e-4a52-b2cf-93e5b6c90904",
        name: "Testiplan",
        defaultPlan: true,
        url: "https://test.test.fi",
        sendingType: "Fire and Forget",
      }; */
      runInAction(() => {
        this.currentDefaultRoutingPlan = undefined;
      });
      // this.currentDefaultRoutingPlan = await getDefaultRoutingPlanForOrganization();
      // todo remove mocks and use actual data when backend is ready for that
    } catch (e) {
      this.setError(i18n.t("routingPlanStore.defaultRoutingPlanFetchError"));
      console.error(e.stack);
    }
  }

  saveNewRoutingPlan = async (routingPlan: RoutingPlan): Promise<RoutingPlan> => {
    try {
      const newRoutingPlan = await saveNewRoutingPlan(routingPlan);
      return newRoutingPlan;
    } catch (e) {
      this.setError(i18n.t("routingPlanStore.singleRoutingPlanSaveError"));
      console.error(e.stack);
      console.log(JSON.stringify(e));
      return Promise.reject(e.stack);
    }
  }

  deleteRoutingPlan = async (routingPlan: RoutingPlan): Promise<void> => {
    try {
      await deleteRoutingPlan(routingPlan);
    } catch (e) {
      this.setError(i18n.t("routingPlanStore.singleRoutingPlanDeletionError"));
      console.error(e.stack);
    }
  }

  clearState = () : void => {
    this.routingPlans = [];
    this.routingPlan = undefined;
    this.routingPlansPaged = [];
    this.totalRoutingPlansPaged = 0;
    this.error = undefined;
    this.selectedRoutingPlans = [];
    this.loadingRoutingPlansPaged = false;
  }

  constructor() {
    super();
    makeObservable(this, {
      routingPlans: observable,
      routingPlan: observable,
      currentDefaultRoutingPlan: observable,
      sendTypes: observable,
      selectedRoutingPlans: observable,
      organizationRoutingPlans: observable,
      loadingOrganizationRoutingPlans: observable,
      routingPlansPaged: observable,
      totalRoutingPlansPaged: observable,
      loadingRoutingPlansPaged: observable,
      loadRoutingPlans: action,
      loadRoutingPlansOfOrganization: action,
      loadRoutingPlansPaged: action,
      deleteRoutingPlan: action,
      getDefaultRoutingPlan: action,
      saveNewRoutingPlan: action,
      clearState: action,
      resetRoutingPlanSelection: action,
      toggleRoutingPlanSelection: action,
      deleteRoutingPlans: action,
      loadSendTypes: action,
    });
  }
}
