import React from "react";
import { CircularProgress, Container } from "@mui/material";
import useStyles from "./loadingCircleStyles";

interface props {
  customStyles?: string;
}
export default ({ customStyles }: props): JSX.Element => {
  const classes = useStyles();

  return (
      <Container className={classes.container}>
          <CircularProgress
            size={200}
            classes={{
              root: customStyles || classes.spinner,
            }}
          />
      </Container>
  );
};
