import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  pageContainer: {
    backgroundColor: theme.palette.secondary.light,
  },
  tabBar: {
    backgroundColor: "#f7f7f7 !important",
    color: "black",
    boxShadow: "none",
    display: "grid",
    "grid-template-columns": "1fr auto 1fr",
    marginTop: 50,
  },
  container: {
    padding: 30,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,

    },
  },
  statusOnlineIcon: {
    color: "green",
  },
  statusOfflineIcon: {
    color: "red",
  },
  statusInitialIcon: {
    color: "grey",
  },
  headerContainer: {
    marginBottom: 50,
  },
  noDevices: {
    marginTop: 50,
  },
  devicesTitle: {
    marginTop: 50,
  },
  searchInput: {
    textAlign: "right",
    whiteSpace: "nowrap",
    justifySelf: "flex-end",
  },
  searchButton: {
    height: "56px",
    marginLeft: 15,
    borderRadius: 5,
  },

}));
