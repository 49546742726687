import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  container: {
    padding: 30,
    minWidth: "80%",
  },
  headerContainer: {
    marginBottom: 50,
  },
}));
