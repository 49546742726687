import React, { useRef, useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  FormLabel,
  TextField,
  Typography, Grid, Select, MenuItem, Stepper, Step, StepLabel, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { WithContext as ReactTags } from "react-tag-input";
import { toast } from "react-toastify";
import useStyles from "./EditOrganizationModalStyles";
import { Organization, OrganizationType } from "../../../models/Organization";
import { useRootStore } from "../../../RootStateContext";
import OrganizationTag from "../../../models/OrganizationTag";
import Tag from "../../../models/Tag";
import ConfirmationModal from "../../../components/deleteDeviceModal/ConfirmationModal";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (o: Organization, id: string) => void;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default observer(({ open, handleClose, submit }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { organizationStore } = useRootStore();
  const { selectedOrganization, serviceLevels, OrganizationTypes } = useRootStore().organizationStore;
  const [activeStep, setActiveStep] = useState(0);
  const steps = [t("usersView.organizationCreationFirstStep"), t("usersView.organizationCreationSecondStep")];
  const [organizationType, setOrganizationType] = useState<OrganizationType>("");

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [orgNameErrorState, setOrgNameErrorState] = useState<boolean>(false);
  const [orgNameErrorText, setOrgNameErrorText] = useState<string>("");
  const [orgSubErrorState, setOrgSubErrorState] = useState<boolean>(false);
  const [orgSubErrorText, setOrgSubErrorText] = useState<string>("");
  const [orgTypeErrorState, setOrgTypeErrorState] = useState<boolean>(false);

  const [organizationLevelState, setOrganizationLevelState] = useState<string>("");

  const [orgTags, setOrgTags] = useState<OrganizationTag[]>([]);
  const [orgNameState, setOrgNameState] = useState<string>(selectedOrganization?.name || "");
  const [orgContactState, setOrgContactState] = useState<string>(selectedOrganization?.contactPerson || "");
  const [orgSubscriptionState, setOrgSubscriptionState] = useState<string>(selectedOrganization?.subscriptionId || "");
  const [orgAccountState, setOrgAccountState] = useState<string>(selectedOrganization?.accountId || "");

  useEffect(() => {
    let tags = selectedOrganization?.tags;

    if (typeof tags !== "undefined" && tags !== null) {
      tags = tags.filter((t: Tag) : boolean => (typeof t.id !== "undefined" && t.id !== null));

      const orgTags = tags.map<OrganizationTag>((t: Tag) : OrganizationTag => ({
        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        id: t.id!,
        text: t.name,
      }));
      setOrgTags(orgTags);
    } else {
      setOrgTags([]);
    }

    if (selectedOrganization?.applicationAccesses !== undefined && selectedOrganization?.applicationAccesses !== null && selectedOrganization?.applicationAccesses[0]) {
      setOrganizationLevelState(selectedOrganization.applicationAccesses[0]);
    } else {
      setOrganizationLevelState("");
    }

    if (selectedOrganization?.organizationType !== undefined && selectedOrganization?.organizationType !== null) {
      setOrganizationType(selectedOrganization.organizationType);
    } else {
      setOrganizationType("");
    }

    setOrgNameState(selectedOrganization?.name || "");
    setOrgContactState(selectedOrganization?.contactPerson || "");
    setOrgSubscriptionState(selectedOrganization?.subscriptionId || "");
    setOrgAccountState(selectedOrganization?.accountId || "");
  }, [selectedOrganization]);

  const close = (): void => {
    organizationStore.setSelectedOrganization(undefined);
    setActiveStep(0);
    handleClose();
  };

  const saveOrganization = (): void => {
    const tags: Tag[] = orgTags.map<Tag>((t : OrganizationTag): Tag => ({
      name: t.text,
      id: null,
    }));

    let accountId = "";
    let orgName = "";

    if (orgAccountState) accountId = orgAccountState;
    if (orgNameState) orgName = orgNameState;

    const orgData : Organization = {
      id: organizationStore?.selectedOrganization?.id || "",
      name: orgName,
      accountId,
      contactUserId: undefined,
      contactPerson: orgContactState,
      active: true,
      subscriptionId: orgSubscriptionState,
      organizationType,
      tags,
      _etag: organizationStore?.selectedOrganization?._etag,
      parentId: organizationStore?.selectedOrganization?.parentId || null,
    };

    if (organizationType !== "ADMIN") { orgData.applicationAccess = organizationLevelState !== "" ? organizationLevelState : undefined; }

    submit(orgData, organizationStore?.selectedOrganization?.id || "");

    close();
  };

  const handleDelete = (i : number): void => {
    setOrgTags((currentTags: OrganizationTag[]) : OrganizationTag[] => currentTags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: OrganizationTag): void => {
    setOrgTags((currentTags: OrganizationTag[]) : OrganizationTag[] => [...currentTags, tag]);
  };

  const handleOrganizationLevelChange = (e: SelectChangeEvent<string>): void => {
    setOrganizationLevelState(e.target.value as string);
  };

  const handleOrganizationTypeChange = (e: SelectChangeEvent<"" | "ADMIN" | "RETAILER" | "WATER_COMPANY">): void => {
    if (e.target.value) {
      setOrganizationType(e.target.value as OrganizationType);
    }
  };

  const deleteOrganization = async (): Promise<void> => {
    await organizationStore.deleteOrganization(organizationStore?.selectedOrganization?.id || "");
    if (organizationStore.error === undefined) {
      toast.info(t("usersView.deleteSuccess"), {
        toastId: "OrgDeletedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
    close();
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = async (): Promise<void> => {
    if (activeStep === 0) {
      let localOrgNameError = false;
      let localOrgSubError = false;

      if (orgNameState === "") {
        localOrgNameError = true;
        setOrgNameErrorState(true);
        setOrgNameErrorText(t("usersView.orgNameRequiredError"));
      } else {
        localOrgNameError = false;
        setOrgNameErrorState(false);
        setOrgNameErrorText("");
      }

      if (orgSubscriptionState === "") {
        localOrgSubError = true;
        setOrgSubErrorState(true);
        setOrgSubErrorText(t("usersView.orgSubRequiredError"));
      } else {
        localOrgSubError = false;
        setOrgSubErrorState(false);
        setOrgSubErrorText("");
      }

      if (localOrgNameError || localOrgSubError) {
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const isNextButtonDisabled = (): boolean => false;

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
            <>
                <FormLabel className={classes.genFormLabel2}>
                    {t("usersView.basicInfo")}
                </FormLabel>
                <div
                  className={classes.subsection}
                  style={{
                    marginTop: "16px",
                  }}
                >
                    <TextField
                      id="orgNameInputForEdit"
                      label={t("usersView.orgName")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={orgNameErrorState}
                      helperText={orgNameErrorText}
                      variant="outlined"
                      fullWidth
                      value={orgNameState}
                      onChange={(e) => setOrgNameState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="orgTypeSelectLabel" shrink>{t("usersView.orgType")}</InputLabel>
                        <Select
                          labelId="orgTypeSelectLabel"
                          id="orgTypeSelectInput"
                          value={organizationType}
                          onChange={handleOrganizationTypeChange}
                          defaultValue={organizationType}
                          displayEmpty={false}
                          error={orgTypeErrorState}

                        >
                            <MenuItem value="">{t("usersView.selectOrganizationType")}</MenuItem>
                            {OrganizationTypes.map((orgType) => (
                                <MenuItem key={orgType} value={orgType}>{t(`usersView.ORGTYPE_${orgType}`)}</MenuItem>
                            ))}
                        </Select>
                        {orgTypeErrorState && (<div className={classes.miniError}>{t("usersView.orgTypeRequiredError")}</div>)}
                    </FormControl>
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="orgContactPersonInputForEdit"
                      label={t("usersView.orgContactPerson")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={false}
                      helperText=""
                      variant="outlined"
                      fullWidth
                      value={orgContactState}
                      onChange={(e) => setOrgContactState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="orgSubscriptionIdInputForEdit"
                      label={t("usersView.orgSubscriptionId")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={orgSubErrorState}
                      helperText={orgSubErrorText}
                      variant="outlined"
                      fullWidth
                      value={orgSubscriptionState}
                      onChange={(e) => setOrgSubscriptionState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="orgAccountIdInputForEdit"
                      label={t("usersView.orgAccountId")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={false}
                      helperText=""
                      variant="outlined"
                      fullWidth
                      value={orgAccountState}
                      onChange={(e) => setOrgAccountState(e.target.value)}
                    />
                </div>
                <FormLabel className={classes.genFormLabel}>
                    Organisaation tagit
                </FormLabel>
                <div className={classes.subsection}>

                    <div className={classes.tags}>
                        <ReactTags
                          tags={orgTags}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          delimiters={delimiters}
                          allowDragDrop={false}
                          inputFieldPosition="bottom"
                          placeholder="Enter lisää tagin"
                        />
                    </div>
                </div>
            </>
        );
      case 1:
        if (organizationType === "ADMIN") {
          return (
              <FormLabel className={classes.genFormLabel3}>
                  {t("usersView.noProductsForOrgType")}
              </FormLabel>
          );
        }

        return (
            <>
                <FormLabel className={classes.genFormLabel2}>
                    {t("usersView.omavesiProductHeader")}
                </FormLabel>
                <div className={classes.subsection}>
                    <Select
                      key="select"
                      labelId="organizationLevelSelectEdit"
                      id="organizationLevelSelectEdit"
                      value={organizationLevelState}
                      onChange={handleOrganizationLevelChange}
                      displayEmpty
                    >
                        <MenuItem value="">{t("usersView.noProduct")}</MenuItem>
                        {serviceLevels.map((level) => (
                            <MenuItem key={level} value={level}>{t(`usersView.${level}_text`)}</MenuItem>

                        ))}
                    </Select>
                </div>
            </>
        );
      default:
        return <></>;
    }
  };

  return (
      <>
          <Modal
            className={classes.modal}
            open={open}
            onClose={close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id="editOrgTitle">{t("usersView.editOrg")}</Typography>
                      </div>
                      {getStepContent(activeStep)}

                      <div className={classes.navigation}>

                          <div className={classes.navButtons}>

                              <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                      <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                      >
                                          {t("usersView.orgBack")}
                                      </Button>
                                  </Grid>
                                  <Grid item xs={4}>
                                      <Button
                                        variant="contained"
                                        id="deleteOrgButton"
                                        className={classes.deleteButton}
                                        color="primary"
                                        onClick={() => setDeleteModalOpen(true)}
                                      >
                                          {t("usersView.deleteOrg")}
                                      </Button>
                                  </Grid>
                                  <Grid item xs={4}>
                                      <Button
                                        variant="contained"
                                        id="editOrgButton"
                                        className={classes.nextButton}
                                        color="primary"
                                        onClick={activeStep === steps.length - 1 ? saveOrganization : handleNext}
                                      >
                                          {activeStep === steps.length - 1 ? t("usersView.orgFinish") : t("usersView.orgNext")}
                                      </Button>
                                  </Grid>
                              </Grid>

                          </div>
                          <Stepper activeStep={activeStep} alternativeLabel>
                              {steps.map((label) => (
                                  <Step key={label}>
                                      <StepLabel>{label}</StepLabel>
                                  </Step>
                              ))}
                          </Stepper>

                      </div>
                  </div>
              </Fade>
          </Modal>
          <ConfirmationModal
            open={deleteModalOpen}
            text={t("usersView.confirmOrgDelete")}
            handleClose={() => setDeleteModalOpen(false)}
            submit={deleteOrganization}
          />
      </>
  );
});
