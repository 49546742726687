import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  TextField,
  Typography,
  FormLabel,
  Select,
  MenuItem, Stepper, Step, StepLabel, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

import { observer } from "mobx-react-lite";
import { WithContext as ReactTags } from "react-tag-input";
import useStyles from "./CreateOrganizationModalStyles";
import OrganizationTag from "../../../models/OrganizationTag";
import Tag from "../../../models/Tag";
import "./createOrganizationModal.css";
import { OrganizationType, Organization } from "../../../models/Organization";
import { useRootStore } from "../../../RootStateContext";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (u: any) => void;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default observer(({ open, handleClose, submit }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orgTags, setOrgTags] = useState<OrganizationTag[]>([]);
  const { serviceLevels, OrganizationTypes } = useRootStore().organizationStore;
  const [activeStep, setActiveStep] = useState(0);
  const steps = [t("usersView.organizationCreationFirstStep"), t("usersView.organizationCreationSecondStep")];
  const [organizationType, setOrganizationType] = useState<OrganizationType>("");

  const [orgNameErrorState, setOrgNameErrorState] = useState<boolean>(false);
  const [orgNameErrorText, setOrgNameErrorText] = useState<string>("");
  const [orgSubErrorState, setOrgSubErrorState] = useState<boolean>(false);
  const [orgSubErrorText, setOrgSubErrorText] = useState<string>("");
  const [orgTypeErrorState, setOrgTypeErrorState] = useState<boolean>(false);

  const [organizationLevelState, setOrganizationLevelState] = useState<string>("");

  const [orgNameState, setOrgNameState] = useState<string>("");
  const [orgContactState, setOrgContactState] = useState<string>("");
  const [orgSubscriptionState, setOrgSubscriptionState] = useState<string>("");
  const [orgAccountState, setOrgAccountState] = useState<string>("");

  const close = (): void => {
    setOrgTags([]);
    setOrgNameState("");
    setOrgContactState("");
    setOrgSubscriptionState("");
    setOrgAccountState("");
    setActiveStep(0);

    setOrgNameErrorState(false);
    setOrgNameErrorText("");
    setOrgSubErrorState(false);
    setOrgSubErrorText("");
    setOrganizationLevelState("");
    setOrgTypeErrorState(false);
    handleClose();
  };

  const saveOrganization = (): void => {
    const tags: Tag[] = orgTags.map<Tag>((t : OrganizationTag): Tag => ({
      name: t.text,
      id: null,
    }));

    let accountId = "";
    let orgName = "";

    if (orgAccountState) accountId = orgAccountState;
    if (orgNameState) orgName = orgNameState;

    const orgData : Organization = {
      name: orgName,
      accountId,
      contactUserId: undefined,
      contactPerson: orgContactState,
      active: true,
      subscriptionId: orgSubscriptionState,
      applicationAccess: organizationLevelState !== "" ? organizationLevelState : undefined,
      organizationType,
      tags,
    };

    submit(orgData);
    close();
  };

  const handleDelete = (i : number): void => {
    setOrgTags((currentTags: OrganizationTag[]) : OrganizationTag[] => currentTags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: OrganizationTag): void => {
    setOrgTags((currentTags: OrganizationTag[]) : OrganizationTag[] => [...currentTags, tag]);
  };

  const handleOrganizationTypeChange = (event: SelectChangeEvent<"" | "ADMIN" | "RETAILER" | "WATER_COMPANY">): void => {
    if (event.target.value) {
      setOrganizationType(event.target.value as OrganizationType);
    }
  };

  const handleOrganizationLevelChange = (e: SelectChangeEvent<string>): void => {
    setOrganizationLevelState(e.target.value as string);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = async (): Promise<void> => {
    if (activeStep === 0) {
      let localOrgNameError = false;
      let localOrgSubError = false;
      let localOrgTypeError = false;

      if (orgNameState === "") {
        localOrgNameError = true;
        setOrgNameErrorState(true);
        setOrgNameErrorText(t("usersView.orgNameRequiredError"));
      } else {
        localOrgNameError = false;
        setOrgNameErrorState(false);
        setOrgNameErrorText("");
      }

      if (orgSubscriptionState === "") {
        localOrgSubError = true;
        setOrgSubErrorState(true);
        setOrgSubErrorText(t("usersView.orgSubRequiredError"));
      } else {
        localOrgSubError = false;
        setOrgSubErrorState(false);
        setOrgSubErrorText("");
      }

      if (organizationType === "") {
        localOrgTypeError = true;
        setOrgTypeErrorState(true);
      } else {
        localOrgTypeError = false;
        setOrgTypeErrorState(false);
      }

      if (localOrgNameError || localOrgSubError || localOrgTypeError) {
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const isNextButtonDisabled = (): boolean => false;

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
            <>
                <FormLabel className={classes.genFormLabel2}>
                    {t("usersView.basicInfo")}
                </FormLabel>
                <div
                  className={classes.subsection}
                  style={{
                    marginTop: "16px",
                  }}
                >
                    <TextField
                      id="orgNameInput"
                      label={t("usersView.orgName")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={orgNameErrorState}
                      helperText={orgNameErrorText}
                      variant="outlined"
                      fullWidth
                      value={orgNameState}
                      onChange={(e) => setOrgNameState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="orgTypeSelectLabel" shrink>{t("usersView.orgType")}</InputLabel>
                        <Select
                          labelId="orgTypeSelectLabel"
                          id="orgTypeSelectInput"
                          value={organizationType}
                          onChange={handleOrganizationTypeChange}
                          defaultValue={organizationType}
                          displayEmpty={false}
                          error={orgTypeErrorState}

                        >
                            <MenuItem value="">{t("usersView.selectOrganizationType")}</MenuItem>
                            {OrganizationTypes.map((orgType) => (
                                <MenuItem key={orgType} value={orgType}>{t(`usersView.ORGTYPE_${orgType}`)}</MenuItem>
                            ))}
                        </Select>
                        {orgTypeErrorState && (<div className={classes.miniError}>{t("usersView.orgTypeRequiredError")}</div>)}
                    </FormControl>
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="orgContactPersonInput"
                      label={t("usersView.orgContactPerson")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={false}
                      helperText=""
                      variant="outlined"
                      fullWidth
                      value={orgContactState}
                      onChange={(e) => setOrgContactState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="orgSubscriptionIdInput"
                      label={t("usersView.orgSubscriptionId")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={orgSubErrorState}
                      helperText={orgSubErrorText}
                      variant="outlined"
                      fullWidth
                      value={orgSubscriptionState}
                      onChange={(e) => setOrgSubscriptionState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="orgAccountIdInput"
                      label={t("usersView.orgAccountId")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={false}
                      helperText=""
                      variant="outlined"
                      fullWidth
                      value={orgAccountState}
                      onChange={(e) => setOrgAccountState(e.target.value)}
                    />
                </div>
                <FormLabel className={classes.genFormLabel}>
                    Organisaation tagit
                </FormLabel>
                <div className={classes.subsection}>

                    <div className={classes.tags}>
                        <ReactTags
                          tags={orgTags}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          delimiters={delimiters}
                          allowDragDrop={false}
                          inputFieldPosition="bottom"
                          placeholder="Enter lisää tagin"
                        />
                    </div>
                </div>
            </>
        );
      case 1:
        if (organizationType === "ADMIN") {
          return (
              <FormLabel className={classes.genFormLabel3}>
                  {t("usersView.noProductsForOrgType")}
              </FormLabel>
          );
        }

        return (
            <>
                <FormLabel className={classes.genFormLabel2}>
                    {t("usersView.omavesiProductHeader")}
                </FormLabel>
                <div className={classes.subsection}>
                    <Select
                      key="select"
                      labelId="organizationLevelSelect"
                      id="organizationLevelSelect"
                      value={organizationLevelState}
                      onChange={handleOrganizationLevelChange}
                      displayEmpty
                    >
                        <MenuItem value="">{t("usersView.noProduct")}</MenuItem>
                        {serviceLevels.map((level) => (
                            <MenuItem key={level} value={level}>{t(`usersView.${level}_text`)}</MenuItem>
                        ))}
                    </Select>
                </div>
            </>
        );

      default:
        return <></>;
    }
  };

  return (
      <>
          <Modal
            className={classes.modal}
            open={open}
            onClose={close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
              <Fade in={open}>
                  <div className={classes.paper}>
                      <div className={classes.sectionHeader}>
                          <Typography variant="h6" component="h2" id="addOrgTitle">{t("usersView.addOrg")}</Typography>
                      </div>
                      {getStepContent(activeStep)}
                      <div className={classes.navigation}>

                          <div className={classes.navButtons}>

                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                              >
                                  {t("usersView.orgBack")}
                              </Button>

                              <Button
                                variant="contained"
                                disabled={isNextButtonDisabled()}
                                className={classes.nextButton}
                                color="primary"
                                onClick={activeStep === steps.length - 1 ? saveOrganization : handleNext}
                              >
                                  {activeStep === steps.length - 1 ? t("usersView.orgFinish") : t("usersView.orgNext")}
                              </Button>
                          </div>
                          <Stepper activeStep={activeStep} alternativeLabel>
                              {steps.map((label) => (
                                  <Step key={label}>
                                      <StepLabel>{label}</StepLabel>
                                  </Step>
                              ))}
                          </Stepper>
                      </div>
                  </div>
              </Fade>
          </Modal>
      </>
  );
});
