import { action, makeObservable, observable, runInAction } from "mobx";
import { checkAlert, getActiveAlertsForDevice, getAllAlertsForDevice, getActiveAlertCountsForUser, checkMultipleAlerts, getAlertsPaged } from "../api/AlertApi";
import Alert from "../models/Alert";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import CheckAlert from "../models/CheckAlert";
import ActiveAlertCount from "../models/ActiveAlertCount";

export default class AlertStore extends BaseStore {
    activeDeviceAlerts? : Alert[];

    activeAlertsForUser? : Alert[];

    activeAlertCountsForUser? : ActiveAlertCount[];

    allDeviceAlerts? : Alert[];

    selectedAlerts: Alert[] = [];

    totalAlerts = 0;

    toggleAlertSelection = (d: Alert): void => {
      if (this.selectedAlerts.includes(d)) this.selectedAlerts = this.selectedAlerts.filter((e) => e !== d);
      else this.selectedAlerts = [...this.selectedAlerts, d];
    }

    resetAlertSelection = (): void => {
      this.selectedAlerts = [];
    }

    loadUserActiveAlertCount = async (): Promise<void> => {
      try {
        const a = await getActiveAlertCountsForUser();
        runInAction(() => {
          this.activeAlertCountsForUser = a;
        });
      } catch (e) {
        this.setError(i18n.t("alertStore.loadUserActiveAlertCountError"));
        this.activeAlertCountsForUser = [];
        console.error(e.stack);
      }
    }

    loadActiveAlertsForDevice = async (organizationId: string, deviceId: string): Promise<void> => {
      try {
        const a = await getActiveAlertsForDevice(organizationId, deviceId);
        runInAction(() => {
          this.activeDeviceAlerts = a;
        });
      } catch (e) {
        this.setError("error");
        console.error(e.stack);
      }
    }

    loadAllAlertsForDevice = async (organizationId: string, deviceId: string): Promise<void> => {
      try {
        const a = await getAllAlertsForDevice(organizationId, deviceId);
        runInAction(() => {
          this.allDeviceAlerts = a;
        });
      } catch (e) {
        this.setError("alertStore.loadAllAlertsForDeviceError");
        console.error(e.stack);
      }
    }

    loadActiveAlertsForUser = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string): Promise<void> => {
      try {
        const a = await getAlertsPaged(page, pageSize, sortBy, sortDirection);
        runInAction(() => {
          this.activeAlertsForUser = a.content;
          this.totalAlerts = a.totalElements;
        });
      } catch (e) {
        this.setError(i18n.t("alertStore.loadActiveAlertsForUserError"));
        this.activeAlertCountsForUser = [];
        console.error(e.stack);
      }
    }

    checkAlert = async (checkData: CheckAlert): Promise<void> => {
      try {
        await checkAlert(checkData);
      } catch (e) {
        this.setError(i18n.t("alertStore.checkAlertError"));
        console.error(e.stack);
      }
    }

    checkMultipleAlerts = async (alerts: Alert[]): Promise<void> => {
      try {
        const checked: string[] = await checkMultipleAlerts(alerts.map((e) => e.id || ""));
        runInAction(() => {
          this.activeAlertsForUser = this.activeAlertsForUser?.filter((a) => {
            if (!a.id) return false;
            return !checked.includes(a.id);
          });
          const failureList = alerts.filter((e) => !e.id || !checked.includes(e.id));
          if (failureList.length > 0) {
            this.setError(i18n.t("alertStore.checkMultipleAlertsError", {
              ids: failureList.join(", "),
            }));
          }
          this.resetAlertSelection();
        });
      } catch (e) {
        this.setError(i18n.t("alertStore.checkMultipleAlertsError"));
        console.error(e.stack);
      }
    }

    clearState = () : void => {
      this.activeAlertsForUser = [];
      this.activeDeviceAlerts = [];
      this.activeAlertCountsForUser = [];
      this.allDeviceAlerts = [];
      this.selectedAlerts = [];
      this.totalAlerts = 0;
    }

    constructor() {
      super();
      makeObservable(this, {
        activeAlertsForUser: observable,
        activeAlertCountsForUser: observable,
        activeDeviceAlerts: observable,
        allDeviceAlerts: observable,
        loadActiveAlertsForDevice: action,
        loadUserActiveAlertCount: action,
        selectedAlerts: observable,
        toggleAlertSelection: action,
        resetAlertSelection: action,
        checkMultipleAlerts: action,
        clearState: action,
        totalAlerts: observable,
      });
    }
}
