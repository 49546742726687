import React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { TFunction } from "i18next";
import { useTranslation, withTranslation } from "react-i18next";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Menu, MenuItem } from "@mui/material";
import { useRootStore } from "../../RootStateContext";
import i18n from "../../i18n";
import useStyles from "./HeaderStyles";

export default withTranslation()((): JSX.Element => {
  const classes = useStyles();
  const store = useRootStore().uiStateStore;
  const { t } = useTranslation();
  const authStore = useRootStore().authenticationStore;
  const changeLanguage = (lng: string):Promise<TFunction> => i18n.changeLanguage(lng);

  const navigateToLogout = (): void => {
    authStore.logout();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
      <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.contents}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={store.handleDrawerToggle}
                className={classes.menuButton}
                id="OpenMobileNavigation"
              >
                  <MenuIcon />
              </IconButton>
              <div className={classes.languageSelector}>
                  <Button
                    className={(i18n.language === "fi") ? classes.selectedLanguage : classes.languageButton}
                    onClick={() => changeLanguage("fi")}
                    id="ChangeLanguageToFinnish"
                  >
                      fi
                  </Button>
                  <Button
                    className={(i18n.language === "en") ? classes.selectedLanguage : classes.languageButton}
                    onClick={() => changeLanguage("en")}
                    id="ChangeLanguageToEnglish"
                  >
                      en
                  </Button>
              </div>
              {authStore.authenticated && (
                  <div className={classes.nameAndUserMenuContainer}>
                      {/*  <div>
                          { authStore.currentUser && authStore.currentUser.name }
                      </div> */}
                      <div>
                          <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                          >
                              <AccountCircle />
                          </IconButton>
                          <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={open}
                            onClose={handleClose}
                          >
                              <MenuItem key="currentuser" onClick={handleClose}>
                                  { authStore.currentUser && authStore.currentUser.firstName }
                              </MenuItem>
                              <MenuItem key="logoutbutton" onClick={navigateToLogout} id="logoutButton">{t("header.logout")}</MenuItem>
                          </Menu>
                      </div>
                  </div>
              )}
          </Toolbar>
      </AppBar>
  );
});
