import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Checkbox, TableSortLabel } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Minimize, Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useStyles from "./TableStyles";
import PendingCircle from "../loadingCircle/loadingCircle";

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (t: any) => JSX.Element | string; // TODO: Fix type
  booleanType?: boolean;
  nestedInside?: Record<string, unknown>;
  disableSort?: boolean;

}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.selected,
    },
  },
}))(TableRow);

type Generic = {
  [key: string]: unknown
}

interface ITableProps<T extends Generic> {
  columns: Column[];
  rows: T[];
  clickRowCallback?: (d: T) => void;
  handlePageChange?: (page: number) => void;
  handlePageSizeChange?: (size: number) => void;
  count?: number;
  currentPage?: number;
  currentRowsPerPage?: number;
  sort?: (s: string) => void;
  setSortDirection?: (s: string) => void;
  toggleRowSelection?: (rows: T) => void;
  onClickIcon?: JSX.Element;
}

interface IRowProps<T extends Generic> {
  row: T;
  columns: Column[];
  onClick?: (d: T) => void;
  onClickIcon?: JSX.Element;
  isSelected?: boolean;
  index: number;
  select?: (index: number) => void;
}

const RowElement = <T extends Generic>({ row, columns, onClick, isSelected, index, select, onClickIcon }: IRowProps<T>): JSX.Element => {
  const classes = useStyles();
  return (
      <>

          <StyledTableRow
            className={classes.root}
            hover={onClick !== undefined}
            role="checkbox"
            tabIndex={-1}
            key={JSON.stringify(row)}
            style={
              onClick !== undefined
                ? {
                  cursor: "pointer",
                } : {}
            }
          >
              {select && (
                  <TableCell
                    onClick={() => select && select(index)}
                    component="th"
                    scope="row"
                    key="select"
                    align="left"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                      <Checkbox
                        checked={isSelected}
                        color="primary"
                      />
                  </TableCell>
              )}
              {columns.map((column) => {
                const value = row[column.id];
                return (
                    <TableCell
                      onClick={() => onClick && onClick(row)}
                      component="th"
                      scope="row"
                      key={column.id}
                      align={column.align}
                    >
                        {!column.booleanType && column.format ? column.format(value) : value as string}
                        {column.booleanType && (row[column.id] ? <Check /> : <Minimize />)}
                    </TableCell>
                );
              })}
              {onClickIcon && onClick
                && (
                    <TableCell
                      key="icon"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => onClick(row)}
                    >
                        <span>{onClickIcon}</span>
                    </TableCell>
                )}
          </StyledTableRow>

      </>
  );
};

export default <T extends Generic>({ columns, rows, clickRowCallback, handlePageChange,
  handlePageSizeChange, count, currentPage, currentRowsPerPage, sort, setSortDirection, toggleRowSelection, onClickIcon }: ITableProps<T>): JSX.Element => {
  const classes = useStyles();
  const [currentSortedBy, setCurrentSortedBy] = useState("");
  const [asc, setAsc] = useState(false);
  const [rowSelections, setRowSelections] = useState<T[]>([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const toggleRowSelect = (rowNumber: number, ignoreAlreadySelected?: boolean): void => {
    const selected = rowSelections.includes(rows[rowNumber]);
    if (selected && ignoreAlreadySelected) return;
    if (toggleRowSelection === undefined) return;
    if (selected) setRowSelections((p) => p.filter((e) => e !== rows[rowNumber]));
    else setRowSelections((p) => [...p, rows[rowNumber]]);
    toggleRowSelection(rows[rowNumber]);
  };

  useEffect(() => {
    if (rowSelections.length !== rows.length) {
      setAllRowsSelected(false);
    } else setAllRowsSelected(true);
  }, [rowSelections, rows.length]);

  const { t } = useTranslation();

  const selectAllRows = (): void => {
    rows.forEach((e, index) => (allRowsSelected ? toggleRowSelect(index) : toggleRowSelect(index, true)));
    setAllRowsSelected((e) => !e);
  };

  const setSort = (field: string): void => {
    if (sort === undefined || setSortDirection === undefined) return;
    if (currentSortedBy === field) {
      const newDirection = asc ? "desc" : "asc";
      setSortDirection(newDirection);
      setAsc(!asc);
    } else {
      setAsc(false);
      setSortDirection("desc");
      setCurrentSortedBy(field);
      sort(field);
    }
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    if (handlePageChange) handlePageChange(newPage);
    else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (handlePageSizeChange !== undefined) handlePageSizeChange(+event.target.value);
    else {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }
  };

  return (
      <Paper className={classes.root}>
          <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                  <TableHead>
                      <TableRow className={classes.root}>
                          {toggleRowSelection && (
                              <TableCell onClick={selectAllRows}>
                                  <Checkbox
                                    checked={allRowsSelected}
                                    style={{
                                      color: "white",
                                    }}
                                  />
                              </TableCell>
                          ) }
                          {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: "bold",
                                }}
                              >
                                  {column.disableSort && sort
                                    ? <>{column.label}</>
                                    : (
                                        <TableSortLabel
                                          className={classes.sortLabel}
                                          active={currentSortedBy === column.id}
                                          onClick={() => setSort(column.id)}
                                          direction={asc ? "asc" : "desc"}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) }
                              </TableCell>
                          ))}
                          {onClickIcon && <TableCell /> }
                      </TableRow>
                  </TableHead>
                  <TableBody>

                      { rows.length > 0 && rows.map((row, index) => (
                          <RowElement
                            onClick={clickRowCallback}
                            columns={columns}
                            index={index}
                            onClickIcon={onClickIcon}
                            isSelected={rowSelections.includes(row)}
                            select={toggleRowSelection ? toggleRowSelect : undefined}
                            row={row}
                            key={JSON.stringify(row)}
                          />
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
          {/* rows.length === 0 && <PendingCircle customStyles={classes.spinner} /> */}
          <TablePagination
            labelRowsPerPage={t("table.rowsPerPage")}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} (${count})`}
            rowsPerPageOptions={[25, 100, 500]}
            component="div"
            count={count !== undefined ? count : rows.length}
            rowsPerPage={currentRowsPerPage || rowsPerPage}
            page={currentPage || page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
  );
};
