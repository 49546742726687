import { action, makeObservable, observable, runInAction } from "mobx";
import { batchDeleteUsers, deleteUser, editUser, getUsers, getUsersPaged, saveNewUser } from "../api/UserApi";
import i18n from "../i18n";
import { UserInfo, UserInfoForCreation, UserInfoForUpdating } from "../models/User";
import BaseStore from "./BaseStore";

export default class UserStore extends BaseStore {
    loadUsers = async (): Promise<void> => {
      try {
        const u = await getUsers();
        runInAction(() => { this.users = u; });
      } catch (e) {
        this.setError(i18n.t("userStore.usersFetchError"));
        this.users = [];
        console.error(e.stack);
      }
    }

    loadUsersPaged = async (page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<void> => {
      try {
        const usersResp = await getUsersPaged(page, pageSize, sortBy, sortDirection, search);
        runInAction(() => {
          this.users = usersResp.content;
          this.totalUsers = usersResp.totalElements;
        });
      } catch (e) {
        this.setError(i18n.t("userStore.usersFetchError"));
        this.users = [];
        console.error(e.stack);
      }
    }

    clearState = () : void => {
      this.users = [];
      this.selectedUser = undefined;
      this.selectedUsers = [];
    }

    saveNewUser = async (user: UserInfoForCreation): Promise<UserInfo> => {
      try {
        const u = await saveNewUser(user);
        runInAction(() => {
          this.users?.push(u);
        });
        return u;
      } catch (e) {
        console.log(e.status);
        this.setError(i18n.t("userStore.userCreationError"));
        console.error(e.stack);
        console.log(JSON.stringify(e));
        return Promise.reject(e.stack);
      }
    }

    deleteUser = async (user: UserInfo): Promise<void> => {
      try {
        await deleteUser(user);
        runInAction(() => {
          this.users = this.users?.filter((e) => e.id !== user.id);
        });
      } catch (e) {
        this.setError(i18n.t("userStore.userDeletionError"));
        console.error(e.stack);
        console.log(JSON.stringify(e));
      }
    }

    updateUser = async (user: UserInfoForUpdating, id: string): Promise<UserInfo> => {
      try {
        const u = await editUser(user, id);
        runInAction(() => {
          const userInUsers = this.users?.find((e) => e.id === id);
          if (userInUsers) {
            this.users?.splice(this.users?.indexOf(userInUsers), 1, u);
          }
        });

        return u;
      } catch (e) {
        this.setError(i18n.t("userStore.userUpdatingError"));
        console.error(e.stack);
        console.log(JSON.stringify(e));
        return Promise.reject(e.stack);
      }
    }

    setSelectedUser = (u: UserInfo): void => {
      this.selectedUser = u;
    }

  toggleUserSelection = (u: UserInfo): void => {
    if (this.selectedUsers.includes(u)) this.selectedUsers = this.selectedUsers.filter((e) => e !== u);
    else this.selectedUsers = [...this.selectedUsers, u];
  }

  deleteUsers = async (users: UserInfo[]): Promise<void> => {
    try {
      const deleted: string[] = await batchDeleteUsers(users.map((e) => e.id || ""));
      runInAction(() => {
        this.users = this.users?.filter((d) => {
          if (!d.id) return false;
          return !deleted.includes(d.id);
        });
        const failureList = users.filter((e) => !e.id || !deleted.includes(e.id));
        if (failureList.length > 0) {
          this.setError(i18n.t("userStore.deleteFailedForXUsers", {
            ids: failureList.join(", "),
          }));
        }
        this.resetUserSelection();
      });
    } catch (e) {
      this.setError(i18n.t("userStore.deleteFailedAllUsers"));
      console.error(e.stack);
    }
  }

  resetUserSelection = (): void => {
    this.selectedUsers = [];
    this.selectedUser = undefined;
  }

    users?: UserInfo[] = undefined;

    selectedUser?: UserInfo = undefined;

    selectedUsers: UserInfo[] = [];

    totalUsers = 0;

    constructor() {
      super();
      makeObservable(this, {
        users: observable,
        loadUsers: action,
        clearState: action,
        saveNewUser: action,
        deleteUser: action,
        updateUser: action,
        selectedUser: observable,
        setSelectedUser: action,
        selectedUsers: observable,
        toggleUserSelection: action,
        resetUserSelection: action,
        deleteUsers: action,
        totalUsers: observable,
        loadUsersPaged: action,
      });
    }
}
