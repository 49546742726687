import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { observer } from "mobx-react-lite";
import NavigationBar from "../../components/navigationBar/NavigationBar";
import Header from "../../components/header/Header";
import "react-toastify/dist/ReactToastify.css";
import mainTheme from "../../materialUITheme";
import SingleDeviceRootView from "../singleDeviceView/singleDeviceRootView/SingleDeviceRootView";
import FrontPageRootView from "../frontPage/frontPageRootView/FrontPageRootView";
import StoreErrorToaster from "../../stores/StoreErrorToaster";
import useStyles from "./MainViewStyles";
import DevicesRootView from "../devicesView/devicesRootView/DevicesRootView";
import AlertsView from "../alerts/AlertsView";
import SubscriptionView from "../subscription/SubscriptionView";
import RoutingRootView from "../routing/routingRootView/RoutingRootView";
import UserManagementRootView from "../userManagement/userManagementRootView/UserManagementRootView";
import { useRootStore } from "../../RootStateContext";
import PendingCircle from "../../components/loadingCircle/loadingCircle";
import UnauthorizedComponent from "../../components/unauthorizedComponent/UnauthorizedComponent";
import SessionExpiredComponent from "../../components/sessionExpired/SessionExpiredComponent";

export default observer((): JSX.Element => {
  const authStore = useRootStore().authenticationStore;
  const { currentUser } = authStore;
  const classes = useStyles();
  const [unauthorized, setunauthorized] = useState(false);
  const [miscError, setMiscError] = useState(false);

  useEffect(() => {
    let mounted = true;
    authStore.fetchUserDetails()
      .catch((e) => {
        if (mounted) {
          // TODO check error response for forbidden or unauthenticated etc.
          if (e.message === "401") {
            setunauthorized(true);
          } else setMiscError(true);
        }
      });

    return function cleanup() {
      mounted = false;
      // authStore.clearState();
    };
  }, [authStore]);

  if (miscError) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mainTheme}><UnauthorizedComponent /></ThemeProvider>
        </StyledEngineProvider>
    );
  }
  if (unauthorized) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mainTheme}><SessionExpiredComponent /></ThemeProvider>
        </StyledEngineProvider>
    );
  }

  if (currentUser === null || currentUser === undefined) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mainTheme}><PendingCircle /></ThemeProvider>
        </StyledEngineProvider>
    );
  }

  return (
      <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
              <div className={classes.root}>
                  <Router>
                      <StoreErrorToaster />
                      <Header />
                      <NavigationBar currentUser={currentUser} />
                      <Paper elevation={0} className={classes.content}>
                          <div className={classes.toolbar} />
                          <Routes>
                              <Route path="/" element={<FrontPageRootView />} />
                              <Route path="/home" element={<FrontPageRootView />} />
                              <Route path="/devices" element={<DevicesRootView currentUser={currentUser} />} />
                              <Route path="/device/:deviceId" element={<SingleDeviceRootView />} />
                              <Route path="/alerts" element={<AlertsView />} />
                              <Route path="/subscription" element={<SubscriptionView />} />
                              <Route path="/users" element={<UserManagementRootView />} />
                              <Route path="/routing" element={<RoutingRootView />} />
                          </Routes>
                      </Paper>
                  </Router>
              </div>
          </ThemeProvider>
      </StyledEngineProvider>
  );
});
