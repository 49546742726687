import { Box, Button, Checkbox, CircularProgress, Container, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "./DeviceAlertsStyles";
import Alert from "../../../../models/Alert";
import EventHistoryModal from "../../../../components/eventHistoryModal/EventHistoryModal";
import { useRootStore } from "../../../../RootStateContext";
import Device from "../../../../models/Device";
import CheckAlert from "../../../../models/CheckAlert";

interface IDeviceAlertsProps {
    activeAlerts?: Alert[];
    device: Device;
}

/*
Todo: too much copypaste with frontpages eventblock, see if could be made to one component
*/
export default ({ activeAlerts, device }: IDeviceAlertsProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const store = useRootStore().alertStore;
  const { eventStore } = useRootStore();
  const [fetchingData, setFetchingData] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // todo: you can fetch these at the modal as well, feel free to edit the code
  const openModal = async (): Promise<void> => {
    if (device.id) {
      setFetchingData(true);
      await store.loadAllAlertsForDevice(device.organizationId || "", device.id);
      await eventStore.loadAllDeviceEvents(device.id);
      setFetchingData(false);
      setModalOpen(true);
    }
  };

  const checkAlert = (alertId: string): void => {
    const checkData : CheckAlert = {
      id: alertId,
      checkedDate: new Date().toISOString(),
    };
    store
      .checkAlert(checkData)
      .then(() => store.loadActiveAlertsForDevice(device.organizationId || "", device.id || ""));
  };

  return (
      <>
          <EventHistoryModal alerts={store.allDeviceAlerts} events={eventStore.allDeviceEvents} open={modalOpen} handleClose={() => setModalOpen(false)} />

          <Container className={classes.rootContainer}>
              <Container className={classes.titleContainer}>
                  <Typography gutterBottom className={classes.leftFloat} variant="h4">{t("singleDeviceView.alerts")}</Typography>
              </Container>
              <Container className={classes.contentContainer}>

                  {(activeAlerts && activeAlerts.length > 0) && (
                      <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                  <TableRow>
                                      <TableCell className={classes.tableHead} align="center">
                                          {`${t("singleDeviceView.alarmStart")}`}
                                      </TableCell>
                                      <TableCell className={classes.tableHead}>{t("singleDeviceView.alarmTitle")}</TableCell>
                                      <TableCell className={classes.tableHead}>{t("singleDeviceView.alarmText")}</TableCell>
                                      <TableCell className={classes.tableHead}>{t("singleDeviceView.alarmSeverity")}</TableCell>
                                      <TableCell className={classes.tableHead}>{t("singleDeviceView.alarmCount")}</TableCell>
                                      <TableCell className={classes.tableHead}>Check</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {activeAlerts.map((alert) => (
                                      <TableRow key={alert.id.toString()}>
                                          <TableCell align="left">
                                              {alert.startTime}
                                          </TableCell>
                                          <TableCell component="th" scope="row">{alert.title}</TableCell>
                                          <TableCell component="th" scope="row">{alert.message}</TableCell>
                                          <TableCell component="th" scope="row">{alert.severity}</TableCell>
                                          <TableCell component="th" scope="row">{alert.repeated ? alert.repeated.length : null}</TableCell>
                                          <TableCell component="th" scope="row">
                                              <Checkbox onClick={() => checkAlert(alert.id)} />
                                          </TableCell>
                                      </TableRow>
                                  ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                  )}

                  {(activeAlerts && activeAlerts.length === 0) && (
                      <Box className={classes.alertBox}>
                          <Typography className={classes.leftFloat} variant="subtitle1" id="NoAlertsText">
                              -
                              {t("singleDeviceView.noActiveAlerts")}
                              -
                          </Typography>
                      </Box>
                  )}
              </Container>
              <Container>
                  <Button variant="contained" color="primary" onClick={() => openModal()} className={classes.eventLogButton} id="openAlarmsAndEventsButton">{t("singleDeviceView.alarmAndEventLog")}</Button>
                  {fetchingData && (
                      <Box>
                          <CircularProgress
                            size={50}
                            classes={{
                              root: classes.spinner,
                            }}
                          />
                          <Typography>
                              {t("singleDeviceView.fetchingData")}
                          </Typography>
                      </Box>
                  )}
              </Container>
          </Container>
      </>
  );
};
