import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    color: theme.palette.text.secondary,
    height: "100%",
    "& .MuiButton-root": {
      marginRight: 20,
      borderRadius: 25,
      paddingLeft: 15,
      paddingRight: 15,
      width: "25%",
      fontSize: "75%",
    },
  },
  piecontainer: {
    flexDirection: "column",
    paddingLeft: "15px",
  },
  container: {
    margin: "0",
    padding: "0 0 0 10px",
  },
  headerContainer: {
    marginBottom: 50,
  },
  pieChartTitle: {
    textAlign: "start",
    color: theme.palette.primary.main,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    width: "133px",
  },
  routeDisplay: {
    marginTop: 30,
    marginLeft: 30,
    display: "inline-flex",
  },
  deviceNameDisplay: {
    marginLeft: 40,
    marginTop: 30,
  },
  routeDisplayElementWrapper: {
    marginRight: 10,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
  },
  myDataPreviewCard: {
    minWidth: 445,
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
    "& .MuiTypography-colorTextSecondary": {
      color: theme.palette.primary.light,
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  headerGraphContainer: {
    paddingTop: "25px",
    paddingBottom: "35px",
    backgroundColor: theme.palette.primary.light,
  },
  headerGraphTitle: {
    marginLeft: "31px",
    marginBottom: "5px",
    color: "white",
  },
  gridContainer: {
    marginBottom: 0,
  },
  onlineIcon: {
    color: theme.palette.primary.main,
    position: "relative",
    bottom: "2px",
  },
  onlineText: {
    color: theme.palette.primary.main,
  },
  offlineIcon: {
    color: theme.palette.secondary.main,
    position: "relative",
    bottom: "2px",
    marginLeft: "10px",
  },
  offlineText: {
    color: theme.palette.secondary.main,
  },
  headCardsContainer:
  {
    gap: "15px",
    marginTop: "10px",
  },
}));
