import { Box, Button, CircularProgress, Container, Tooltip, Typography } from "@mui/material";
import { CloudDone, CloudOff, CloudQueue } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Device from "../../../../models/Device";
import useStyles from "./DeviceDataFetchStyles";
import RawDataUpDownLinkStatus from "../../../../models/RawDataUpDownLinkStatus";
import RawDataModal from "../../../../components/rawDataModal/RawDataModal";
import { useRootStore } from "../../../../RootStateContext";

interface IDeviceFetchProps {
    device: Device;
    latestRawDataUpDownLinkStatus?: RawDataUpDownLinkStatus;
}

export default ({ device, latestRawDataUpDownLinkStatus }: IDeviceFetchProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dayModalOpen, setDayModalOpen] = useState(false);
  const [weekModalOpen, setWeekModalOpen] = useState(false);
  const [monthModalOpen, setMonthModalOpen] = useState(false);

  const rawDatasStore = useRootStore().rawDataStore;
  const { rawDataOfDay } = rawDatasStore;

  const { rawDataOfWeek } = rawDatasStore;

  const { rawDataOfMonth } = rawDatasStore;
  const [fetchingData, setFetchingData] = useState(false);

  const getElapsedTime = (d?: Date): string => {
    if (d) {
      const differenceInMillis = (Date.now()).valueOf() - (new Date(d)).valueOf();
      let differenceAsSeconds = Math.floor(differenceInMillis / 1000);
      // Calculate (and subtract) whole days
      const days = Math.floor(differenceAsSeconds / 86400);
      differenceAsSeconds -= days * 86400;
      // Calculate (and subtract) whole hours
      const hours = Math.floor(differenceAsSeconds / 3600) % 24;
      differenceAsSeconds -= hours * 3600;
      // Calculate (and subtract) whole minutes
      // eslint-disable-next-line no-mixed-operators
      const mins = Math.floor(differenceAsSeconds / 60) % 60;
      differenceAsSeconds -= mins * 60;
      const secs = differenceAsSeconds;

      return `${(days === 0 ? "" : `${days}${t("singleDeviceView.days")} `)
            + (hours === 0 ? "" : `${hours}${t("singleDeviceView.hours")} `)
            + (mins === 0 ? "" : `${mins}${t("singleDeviceView.minutes")} `)
            + secs}${t("singleDeviceView.seconds")} ${t("singleDeviceView.ago")}`;
    }
    return t("singleDeviceView.noData");
  };

  const openOneDayRawDataModal = async (): Promise<void> => {
    setDayModalOpen(true);
    setFetchingData(true);
    await rawDatasStore.loadRawDataOfLastDay(device.physicalId);
    setFetchingData(false);
  };

  const openOneWeekRawDataModal = async (): Promise<void> => {
    setWeekModalOpen(true);
    setFetchingData(true);
    await rawDatasStore.loadRawDataOfLastWeek(device.physicalId);
    setFetchingData(false);
  };

  const openOneMonthRawDataModal = async (): Promise<void> => {
    setMonthModalOpen(true);
    setFetchingData(true);
    await rawDatasStore.loadRawDataOfLastMonth(device.physicalId);
    setFetchingData(false);
  };

  const resolveConnectionStatusIcon = (
    device: Device,
    latestRawDataUpDownLinkStatus: RawDataUpDownLinkStatus | undefined,
    classes: any,
  ): React.ReactNode => {
    switch (device.connectionStatus) {
      case "ONLINE":
        return (
            <Tooltip title="Online">
                <CloudDone className={classes.statusOnlineIcon} />
            </Tooltip>
        );
      case "OFFLINE":
        return (
            <Tooltip title="Offline">
                <CloudOff
                  className={classes.statusOfflineIcon}
                />
            </Tooltip>
        );
      default:
        return (
            <Tooltip title="Never seen">
                <CloudQueue className={classes.statusInitialIcon} />
            </Tooltip>
        );
    }
  };

  return (
      <div className={classes.deviceStatusRootContainer}>
          <RawDataModal fetchingData={fetchingData} rawData={rawDataOfDay} open={dayModalOpen} title={t("singleDeviceView.rawDataOfDay")} handleClose={() => setDayModalOpen(false)} modalType="Day" />
          <RawDataModal fetchingData={fetchingData} rawData={rawDataOfWeek} open={weekModalOpen} title={t("singleDeviceView.rawDataOfWeek")} handleClose={() => setWeekModalOpen(false)} modalType="Week" />
          <RawDataModal fetchingData={fetchingData} rawData={rawDataOfMonth} open={monthModalOpen} title={t("singleDeviceView.rawDataOfMonth")} handleClose={() => setMonthModalOpen(false)} modalType="Month" />
          <Container className={classes.deviceStatusRootContainerTop}>
              <Box className={classes.checkBoxIconContainer}>
                  <Typography>{resolveConnectionStatusIcon(device, latestRawDataUpDownLinkStatus, classes) }</Typography>
              </Box>
              <Box className={classes.subtitles}>
                  <Typography variant="subtitle1" id="LatestUpLink">
                      {t("singleDeviceView.latestUplink")}
                      <span className={classes.agoText}>{getElapsedTime(latestRawDataUpDownLinkStatus?.upLink)}</span>
                  </Typography>

                  {/* <Typography variant="subtitle1" id="LatestDownLink">
                          {t("singleDeviceView.latestDownlink")}
                          <span className={classes.agoText}>{getElapsedTime(latestRawDataUpDownLinkStatus?.downLink)}</span>
                      </Typography> */}
                  <Typography variant="subtitle1" className={classes.fetchDataText} id="FetchDeviceDataTitle">{t("singleDeviceView.fetchData")}</Typography>
              </Box>
          </Container>
          <Container className={classes.fetchDataContainer}>
              {/* <Container className={classes.fetchDataText}>
                  <Typography variant="subtitle2" id="FetchDeviceDataTitle">{t("singleDeviceView.fetchData")}</Typography>
              </Container> */}
              <Container className={classes.buttonsContainer}>
                  <Button className={classes.fetchDataButton} variant="contained" onClick={() => openOneDayRawDataModal()} color="primary" id="FetchDataFromOneDayButton">
                      {t("singleDeviceView.day")}
                  </Button>
                  <Button className={classes.fetchDataButton} variant="contained" onClick={() => openOneWeekRawDataModal()} color="primary" id="FetchDataFromOneWeekButton">
                      {t("singleDeviceView.week")}
                  </Button>
                  <Button className={classes.fetchDataButton} variant="contained" onClick={() => openOneMonthRawDataModal()} color="primary" id="FetchDataFromOneMonthButton">
                      {t("singleDeviceView.month")}
                  </Button>
              </Container>
          </Container>
      </div>
  );
};
