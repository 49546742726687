import { createTheme, Theme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const mainTheme = createTheme({
  palette: {
    primary: {
      main: blue[900],
      dark: "#071f53",
    },
    secondary: {
      main: "#B4B4B4FF",
    },
    error: {
      main: "#f3742b",
    },
    info: {
      main: "#0099CB",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    h4: {},
    h6: {},
    subtitle1: {},
    subtitle2: {
      color: blue[900],
    },
    fontFamily: ["sans-serif"].join(","),
  },
});

export default mainTheme;
