import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    marginTop: "15px",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: "64px",
  },
}));
