import React from "react";
import { Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

import { Edit } from "@mui/icons-material";
import useStyles from "./RoutingTabsStyles";
import RoutingPlanTable, { Column } from "../../../components/table/TableWithPagination";
import RoutingPlan from "../../../models/RoutingPlan";
import SingleRoutingPlanModal from "./SingleRoutingPlanModal";
import DeleteRoutingPlanModal from "../../../components/deleteRoutingPlanModal/DeleteRoutingPlanModal";
import MyTabs from "../../../components/tabs/MyTabs";
import { useRootStore } from "../../../RootStateContext";
import PendingCircle from "../../../components/PendingCircle/PendingCircle";

interface TabTitle {
  label: string,
  id: string
  disabled: boolean
}

interface TabContent {
  index: number,
  contentElement: JSX.Element
}

const columns: Column[] = [
  /* {
    id: "defaultPlan", label: "Oletus", minWidth: 100, booleanType: true,
  }, */
  {
    id: "name", label: "Nimi", minWidth: 170,
  },
  {
    id: "sendingType",
    label: "Viestin lähetys",
    minWidth: 170,
  },
  {
    id: "url",
    label: "URL",
    minWidth: 170,
  },
  {
    id: "organizationName",
    label: "Organisaatio",
    minWidth: 170,
  },
];

interface IRoutingTabsProps {
  routingPlans: RoutingPlan[];
  deleteRoutingPlan: (routingPlan: RoutingPlan) => Promise<void>;
  updateRoutingPlan: (routingPlan: RoutingPlan) => Promise<void>;
  totalRoutingPlans: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
}

export default ({ routingPlans,
  deleteRoutingPlan,
  updateRoutingPlan,
  totalRoutingPlans,
  setPage,
  setPageSize,
  page,
  rowsPerPage,
  sort,
  setSortDirection }: IRoutingTabsProps): JSX.Element => {
  const classes = useStyles();
  const [selectedRoutingPlan, setSelectedRoutingPlan] = React.useState({} as RoutingPlan);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const store = useRootStore().routingPlanStore;

  const cellClickedCallback = (routing: RoutingPlan): void => {
    const selectedPlan : RoutingPlan | undefined = routingPlans.find((p) => p.id === routing.id);

    setSelectedRoutingPlan(selectedPlan as RoutingPlan);
    setModalOpen(true);
  };

  const tabTitles: TabTitle[] = [
    {
      label: "Reitityssuunnitelmat", id: "routingPlansTab", disabled: false,
    },
    {
      label: "Omat urlit", id: "myUrlsTab", disabled: true,
    },
  ];

  const tabContents: TabContent[] = [
    {
      index: 0,
      contentElement: <RoutingPlanTable<RoutingPlan>
        columns={columns}
        rows={routingPlans}
        toggleRowSelection={store.toggleRoutingPlanSelection}
        clickRowCallback={cellClickedCallback}
        onClickIcon={<Edit />}
        count={totalRoutingPlans}
        handlePageChange={setPage}
        handlePageSizeChange={setPageSize}
        currentPage={page}
        currentRowsPerPage={rowsPerPage}
        sort={sort}
        setSortDirection={setSortDirection}
      />,
    },
    {
      index: 1,
      contentElement:
    <Typography>Täällä voi asettaa urleja ja niiden salausavaimia. Näitä urleja voi tulevaisuudessa hakea ja lisätä reitityssuunnitelmiin.</Typography>,
    },
  ];

  return (
      <div
        className={classes.root}
        style={{
          padding: store.loadingRoutingPlansPaged || (!store.loadingRoutingPlansPaged && routingPlans && routingPlans.length === 0) ? "15px" : "0px",
        }}
      >
          <DeleteRoutingPlanModal open={deleteModalOpen} handleClose={() => setDeleteModalOpen(false)} submit={deleteRoutingPlan} routingPlan={selectedRoutingPlan} />
          <SingleRoutingPlanModal routingPlan={selectedRoutingPlan} open={modalOpen} handleClose={() => setModalOpen(false)} submit={updateRoutingPlan} />

          {store.loadingRoutingPlansPaged ? (<PendingCircle />) : routingPlans && routingPlans.length > 0 ? (
              <MyTabs tabSwitchedCallback={store.resetRoutingPlanSelection} tabTitles={tabTitles} tabContents={tabContents} />
          ) : (<></>)}

          {!store.loadingRoutingPlansPaged && routingPlans && routingPlans.length === 0 && (
              <Typography>Ei reitityssuunnitelmia </Typography>
          )}
      </div>
  );
};
