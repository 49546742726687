import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    maxWidth: "20vw",
    whiteSpace: "pre-wrap",
    height: "350px",
    width: "500px",
    boxSizing: "border-box",
    "& *": {
      boxSizing: "border-box",
    },
  },
  section: {
    padding: "10px",
    height: "calc(100% - 55px)",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginRight: 15,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.dark,
    marginRight: 15,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  formControl: {
    width: "100%",
    marginTop: "15px",
  },
  sectionHeader: {
    padding: theme.spacing(2, 2, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.light,
    "& .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
    },
  },

  sectionHeaderText: {
    fontSize: "14pt",
  },

  sectionHeaderSubText: {
    fontSize: "12pt",
    color: "rgba(0, 0, 0, 0.6)",
  },

  massEditModalButtons: {
    width: "100%",
    height: "55px",
    padding: "10px",
  },

  backButton: {},

  saveButton: {
    marginLeft: "auto",
  },

  spinnerContainer: {
    height: "calc(100% - 55px)",
  },

  spinner: {
    width: "50px !important",
    height: "50px !important",
  },
}));
